import React from "react";
import { connect } from "react-redux";

import CardList from "./CardsList";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { getPointsView } from "@utils/CommonUtil";

function PlayerHands({ playerHands, gameCondition }) {
    return (
        <div className={classNames("player-hands")}>
            <div className="player-hands-_1">
                {
                    gameCondition ?
                        <span className="hands-title">
                            <FormattedMessage id="you" /> - {playerHands[0]?.points?.length > 0 && getPointsView(playerHands[0].points, playerHands[0].isBlackJack) || 0}
                        </span> :""
                }
                <div className="hands-body">
                    {playerHands?.length > 0 && <CardList cards={playerHands[0].cards || []} />}
                </div>
               
            </div>
            {
                playerHands?.length == 2 ?
                <div className="player-hands-_2">
                    {
                        gameCondition ?
                            <span className="hands-title">
                                <FormattedMessage id="split" /> - {playerHands[1]?.points?.length > 1 && getPointsView(playerHands[1].points, playerHands[1].isBlackJack) || 0}
                            </span> :""
                    }
                    <div className="hands-body">
                        <CardList cards={playerHands[1].cards || []} />
                    </div>
                </div>
                : ""
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        playerHands: state.playerHands,
        gameCondition: state.gameCondition
    }
}

const mapDispatchToProps = {
}


export default connect(mapStateToProps, mapDispatchToProps)(PlayerHands);