import {SET_VIEW_SETTINGS} from "@actions/actionTypes";
import {setLocalStorage} from "@services/localStorageService";

export default function totalBetsReducer(state = false, action) {
    switch (action.type) {
        case SET_VIEW_SETTINGS:
            setLocalStorage("viewSettings", action.mode)
            return action.mode
        default:
            return state;
    }
}
