import React from "react";

function HeaderCloseIcon() {
    return (
        <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 49.996 50">
                <defs>
                    <filter id="VectorHeaderCloseIcon" x="10.998" y="13"  filterUnits="userSpaceOnUse">
                        <feOffset dy="2" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="2" result="blur"/>
                        <feFlood/>
                        <feComposite operator="in" in2="blur"/>
                        <feComposite in="SourceGraphic"/>
                    </filter>
                </defs>
                <g id="Group_217" data-name="Group 217" transform="translate(-29.002 -25)">
                    <circle id="Ellipse_351" data-name="Ellipse 351" cx="24" cy="24" r="24" transform="translate(30 26)" fill="none"/>
                    <g transform="matrix(1, 0, 0, 1, 29, 25)" filter="url(#VectorHeaderCloseIcon)">
                        <path id="Vector-2" data-name="Vector" d="M9.319,8.5,15.952.342A.208.208,0,0,0,15.8,0H13.781a.407.407,0,0,0-.311.149L8,6.878,2.53.149A.4.4,0,0,0,2.219,0H.2A.208.208,0,0,0,.048.342L6.681,8.5.048,16.658A.211.211,0,0,0,0,16.765a.213.213,0,0,0,.017.115.206.206,0,0,0,.075.088A.2.2,0,0,0,.2,17H2.219a.407.407,0,0,0,.311-.149L8,10.122l5.47,6.73a.4.4,0,0,0,.311.149H15.8a.208.208,0,0,0,.154-.342Z" transform="translate(17 17)" fill="#fff"/>
                    </g>
                </g>
            </svg>
    )
}

export default HeaderCloseIcon