import React from "react";
// import {FormattedMessage} from "react-intl";
// import {Prediction} from "@constants/Enums";

function BoardLeft({onClick}) {

    return (
        <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 108 108">
            <g id="Group_2799" data-name="Group 2799" transform="translate(-732 -832.004)">
                <g id="Ellipse_4211" data-name="Ellipse 4211" transform="translate(732 832.004)" fill="none" stroke="#79c4f2" strokeWidth="1">
                    <circle cx="54" cy="54" r="54" stroke="none"/>
                    <circle cx="54" cy="54" r="53.5" fill="none"/>
                </g>
                <g id="Ellipse_4208" data-name="Ellipse 4208" transform="translate(736 836)" fill="rgba(3,76,140,0.5)" stroke="#79c4f2" strokeWidth="1">
                    <circle cx="50" cy="50" r="50" stroke="none"/>
                    <circle cx="50" cy="50" r="49.5" fill="none"/>
                </g>
                <text id="_3" data-name="+3" transform="translate(786 919.004)" fill="#fff" fontSize="22" fontFamily="SFCompactText-Bold, SF Compact Text" fontWeight="700" letterSpacing="0.04em"><tspan x="-13" y="0">+3</tspan></text>
                <text id="_21" data-name="21" transform="translate(786 886.004)" fill="#fff" fontSize="40" fontFamily="SFCompactText-BlackItalic, SF Compact Text" fontWeight="800" fontStyle="italic" letterSpacing="0.04em"><tspan x="-23" y="0">21</tspan></text>
            </g>
        </svg>
    )
}

export default BoardLeft;