import {
    SEND_BET_HISTORY,
    SUB_BET_HISTORY,
    SET_HISTORY,
    RESET_HISTORY,
    REQUEST_BET_HISTORY
} from "@actions/actionTypes";
import {
    getTokenByUrl
} from "../../utils/HttpUtil";

export function getHistory(limit, page, status, createdFrom, createdTo ){
    return dispatch => {
        const body = {
            limit: limit,
            page: page,
        }
        status !== "" && (body.Status = status);
        createdFrom && (body.CreatedFrom = createdFrom);
        createdTo && (body.CreatedTo = createdTo);
        dispatch({
            type: REQUEST_BET_HISTORY,
            data: body
        })
    }
}

export function resetHistory() {
    return dispatch => {
        dispatch({type: RESET_HISTORY});
    }
}