import React from "react";
import CardsListItem from "./CardsListItem";
import classNames from "classnames";

export function CardList({cards, className}) {
    
    return(
        <div className={classNames("cards-list", className)}>
            {cards.map((card, index)=>(
                <CardsListItem key={index} card={card} />
            ))}
        </div>
    )
}

export default CardList