import {
    SET_TIME_TICKER,
    SET_ENABLE_MAIN_BETS_AREA
} from "@actions/actionTypes";
import initialStore from "../initialStore";

export function disableMainBetsArea() {
    return dispatch => {
        dispatch({type: SET_ENABLE_MAIN_BETS_AREA, isEnable: false})
    }
}

export function enableMainBetsArea() {
    return dispatch => {
        dispatch({type: SET_ENABLE_MAIN_BETS_AREA, isEnable: true})
    }
}

export function resetTimeTicker() {
    return dispatch => {
        dispatch({
            type: SET_TIME_TICKER,
            ticker:  {
                Start: initialStore.timeTicker.start,
                End: initialStore.timeTicker.end
            }
        })
    }
}