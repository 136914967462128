import React from "react";
import classNames from "classnames";

function _2XButton({ onClick }) {

    return (
        <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48">
            <defs>
                <filter id="Path_3383_2XButton" x="5.3" y="13.49" width="37.569" height="24.733" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="icon_outlined_editor_delete" transform="translate(14 14)">
                <circle id="Ellipse_349" cx="24" cy="24" r="24" transform="translate(-14 -14)" fill="none" opacity="0.7"/>
                <g transform="matrix(1, 0, 0, 1, -14, -14)" filter="url(#Path_3383_2XButton)">
                    <path id="Path_3383_2XButton-2" d="M1.1.023h9.936V-2.672H5.948v-.166l1.776-1.4c2.3-1.813,3.153-3.069,3.153-4.424V-8.7c0-2.2-1.923-3.609-4.894-3.609-3.075,0-5.1,1.63-5.1,4.1v.067H4.224v-.075A1.5,1.5,0,0,1,5.852-9.7c.918,0,1.481.474,1.481,1.256V-8.4c0,.632-.494,1.222-2.408,2.844L1.1-2.322Zm12.43,0h3.881l2.14-3.618h.069L21.774.023h4.089l-3.855-6V-6.04l3.872-5.938H21.808L19.833-8.152h-.069L17.8-11.977H13.518l3.69,5.921v.067Z" transform="translate(10.62 30)" fill="#fff" stroke="#fff" strokeWidth="0.4"/>
                </g>
            </g>
        </svg>
    )
}

export default _2XButton