import React, { useState, useEffect } from "react";
import tap from "@assets/img/tap.svg";
import { FormattedMessage } from "react-intl";
import UserCenterInfo from "../UserCenterInfo";
import { getFullscreenElement, fullScreenToggle, removeFullScreenEventListner, addFullScreenEventListner } from "@services/fullScreenService";

export default function TabFullScreen() {
    const [fullScreen, setFullScreen] = useState(!!getFullscreenElement())

    const setfullScreenToggle = () => {
        fullScreenToggle();
        setFullScreen(!!getFullscreenElement())
    }

    useEffect(() => {
        const fullscreenchanged = (event) => {
            if (getFullscreenElement()) {
                setFullScreen(true);
            } else {
                setFullScreen(false);
            }
        }
        addFullScreenEventListner(fullscreenchanged);

        return () => {
            removeFullScreenEventListner(fullscreenchanged);
        }
    }, [])

    return  !getFullscreenElement() && <UserCenterInfo description={<FormattedMessage id="please_tap_to_continue" />}
                        icon={tap}
                        onClick={setfullScreenToggle}
                        className="tap-full-screen"/>
    
}