export function inIframe () {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export function getPointsView(points, isBlackJack) {
    if (isBlackJack) {
        return "BJ";
    } else {
        if (points.indexOf(21) != -1) {
            return "21";
        }
    }
    return points.filter(point => point > 0).join('/');
}