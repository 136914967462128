import {SET_LIMIT} from "@actions/actionTypes";
import initialStore from "../initialStore";

function limitReducer(state = initialStore.limit, action) {
    switch (action.type) {
        case SET_LIMIT:
            return action.limit;
        default:
            return state;
    }
}

export default limitReducer