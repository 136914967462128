import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Ticker from "@components/roundInfo/ticker";
import {FormattedMessage} from "react-intl";
import { RoundActionType } from "@constants/Enums";
import {subscribeTickerWorker, unSubscribeTickerWorker} from "@actions/workerActions";
import { resetTimeTicker, disableMainBetsArea, enableMainBetsArea} from "@actions/timeticker";
import {resetActiveBets, sendBets, setDeleteBetCondition} from "@actions/betsAction";

function RoundInfo({ isCustomDisableInsurancePanel, isCustomDisableSelectHandPanel, isCustomDisableActionPanel,
                       timeTicker, round, subscribeTickerWorker, unSubscribeTickerWorker, setDeleteBetCondition,
                       disableMainBetsArea, isEnabledMainBetArea, sendBets, enableMainBetsArea, resetTimeTicker
}) {
    const [betDuration, setBetDuration] = useState(0);

    useEffect(() => {
        function getGameTimeDiff(e) {
            const  timer = JSON.parse(e.data)
            setBetDuration(timer.data ? parseInt(timer.data / 1000) : 0)
            setDeleteBetCondition(false)
        }
        subscribeTickerWorker(getGameTimeDiff);
        return () => {
            unSubscribeTickerWorker(getGameTimeDiff)
        }
    }, [])

    useEffect(() => {

        if (betDuration <= 3 && betDuration > 2 && round.actionType == RoundActionType.Bet ) {
            disableMainBetsArea()
            setDeleteBetCondition(true)
            sendBets(round?.roundId)
        } else if (betDuration > 3 && !isEnabledMainBetArea && round.actionType === RoundActionType.Bet) {
            enableMainBetsArea();
        }
        if (betDuration === 0) {
            resetTimeTicker()
        }

    }, [betDuration])

    return (
        <div className="round-info">
            <div className="round-info-board">
                <Ticker diff={timeTicker.diff}
                    betDuration={betDuration}
                    actionType={round?.actionType}
                    customDisable={
                        round?.actionType == RoundActionType.Insurance && isCustomDisableInsurancePanel ||
                        round?.actionType == RoundActionType.Split && isCustomDisableSelectHandPanel ||
                        round?.actionType == RoundActionType.PlayerAction && isCustomDisableActionPanel
                        }/>
            </div>

            <span className="message">
                {
                    betDuration === 0 ||
                    round?.actionType == RoundActionType.Insurance && isCustomDisableInsurancePanel ||
                    round?.actionType == RoundActionType.Split && isCustomDisableSelectHandPanel ||
                    round?.actionType == RoundActionType.PlayerAction && isCustomDisableActionPanel ? "" :
                    betDuration > 3 && (
                        (round?.actionType == RoundActionType.PlayerAction || round?.actionType == RoundActionType.Split || round?.actionType == RoundActionType.Insurance) ?
                            <FormattedMessage id={"choose_the_option_please"} />
                            :
                            <FormattedMessage id={"place_your_bets_please"} />
                        ) || ""
                }
            </span>
        </div>
    )
}

export const mapStateToProps = (state) => {
    return {
        timeTicker: state.timeTicker,
        round: state.round,
        isEnabledMainBetArea: state.isEnabledMainBetArea,
        isCustomDisableInsurancePanel: state.isCustomDisableInsurancePanel,
        isCustomDisableSelectHandPanel: state.isCustomDisableSelectHandPanel,
        isCustomDisableActionPanel: state.isCustomDisableActionPanel,
    }
}

const mapDispatchToProps = {
    unSubscribeTickerWorker,
    subscribeTickerWorker,
    setDeleteBetCondition,
    enableMainBetsArea,
    disableMainBetsArea,
    sendBets,
    resetTimeTicker
}

export default connect(mapStateToProps, mapDispatchToProps)(RoundInfo)