const host = process.env.REACT_APP_SOCKET_PATH;
import {
    PONG_TYPE
} from "@actions/actionTypes";
import {
    SocketReadyState
} from "@constants/Enums";

function SocketClient() {
    this.client = null;
    let pingTimeOut = 0;
    let lastPongTime = new Date().getTime();
    let subscribers = [];
    let openSubscribers = [];
    let closeSubscribers = [];
    let disconnectSubscribers =[];
    let isConnected = false;
    let reconnectTimerId;
    let session = "";
    let pandingTimer = 0;
    const that = this;

    window.addEventListener('load', function() {

        function updateOnlineStatus(event) {
            if (navigator.onLine) {
                clearTimeout(reconnectTimerId);
                that.removeOldEvents();
                that.connect(session);
            } else {
                clearTimeout(pingTimeOut)
                that.client.close();
                isConnected = false;
            }
        }

        window.addEventListener('online',  updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);
    });

    this.removeOldEvents = function () {
        this.client.removeEventListener('message', this.message);
        this.client.removeEventListener('open', this.open);
        this.client.removeEventListener('close', this.close.bind(this));
        this.client.removeEventListener('error', this.onError.bind(this));
        this.client = null;
    }

    this.message = function (event) {
        let message = JSON.parse(event.data);
        if (message.Type == PONG_TYPE) {
            lastPongTime = new Date().getTime();
        }
        subscribers.forEach((subscriber) => {
            if (subscriber.eventName == message.Type) {
                subscriber.callBack(message.Data);
            }
        })
    }

    this.connect =  function (sessionId) {
        if (this.client && this.client.readyState != SocketReadyState.CLOSED) {
            return;
        }
        session = sessionId
        try {
            this.client = new WebSocket(`${host}?session=${sessionId}`);

            this.client.addEventListener('message', this.message);
            this.client.addEventListener('open', this.open);
            this.client.addEventListener('close', this.close.bind(this));
            this.client.addEventListener('error', this.onError.bind(this));
            pandingTimer = setTimeout(() => {
                if(this.client.readyState != SocketReadyState.OPEN) {
                    this.client.close();
                    this.connect(session);
                }
            }, 5000)
        } catch (e) {
            console.error(e)
        }
        return this;

    }

    this.on = function (eventName, callBack) {
        subscribers.push({
            eventName: eventName,
            callBack: callBack
        })
    }

    this.onOpen = function(f) {
        openSubscribers.push(f);
    }

    this.open = function(data) {
        isConnected = true;
        clearTimeout(pandingTimer);
        openSubscribers.forEach(f => {
            f(data);
        })
    }

    this.onClose = function(f) {
        closeSubscribers.push(f);
    }

    this.onConnectEnd =  function (f) {
        disconnectSubscribers.push(f)
    }

    this.close = function(data, reason) {
        isConnected = false;
        console.log(data)
        if (data.code === 1003 || data.code === 1008) {
            clearTimeout(reconnectTimerId)
            disconnectSubscribers.forEach(f => f(data.code))
        } else {
            clearTimeout(reconnectTimerId)
            reconnectTimerId = setTimeout(() => {
                this.connect(session);
            }, 5000);
            closeSubscribers.forEach(f => {
                f(data);
            })
        }
    }

    this.onError = function (err) {
        console.error(err, this);
    }

    this.off = function (eventName, callBack) {
        subscribers = subscribers.filter(subscriber => subscriber.eventName != eventName && callBack !=  subscriber.callBack);
    }

    this.pingSocket =  function () {
        try {
            if (!this.client) return;
            if (this.client.readyState !== 1) return;
            this.client.send('{"Type": 0}');
            setTimeout(() => {
                if (lastPongTime && (new Date().getTime() - lastPongTime > 2000)) {
                    clearInterval(pingTimeOut);
                    this.client.close();
                }
            }, 1000)
            pingTimeOut = setTimeout(this.pingSocket.bind(this), 5000);
        } catch (e) {
            console.error(e)
        }
    }

    this.emit = function (eventName, data) {
        try {
            let message = {Type: +eventName};
            if (data) {
                message.Data = data
            }

            this.client.send(JSON.stringify(message));
        } catch (e) {
            console.error(e)
        }
    }
}

export default function () {
    return new SocketClient()
}
