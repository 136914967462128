import React, {useEffect, useRef, useState} from "react";
import ToolTipContent from "@components/toolTip/toolTipContent";

function ToolTip({children, title, position}) {
    const toolTipRef = useRef();
    const [toolTipParams, setToolTipParams] = useState({})
    const [isMouseEnter, setIsMouseEnter] = useState(false)

    const getElementCoordinates = () => {
        if (toolTipRef && toolTipRef.current) {
            const tooltipParams = toolTipRef.current.getBoundingClientRect();
            setToolTipParams(tooltipParams)
        }
    }

    useEffect(() => {
        getElementCoordinates()

    }, [])



    return(<div ref={toolTipRef} className="tooltip-wrapper"
                onMouseEnter={() => {setIsMouseEnter(true); getElementCoordinates() }}
                onMouseLeave={() => setIsMouseEnter(false)}
                onMouseDown={() => setIsMouseEnter(false)}>
        {children}
        {
            isMouseEnter ? <ToolTipContent title={title} toolTipParams={toolTipParams} position={position} /> : ""
        }
    </div>)
}

export default ToolTip