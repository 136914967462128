import React from "react";

function ClearFilterIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Group_1487" data-name="Group 1487" viewBox="0 0 32 32.388">
            <defs>
                <filter id="VectorClearFilterIcon" x="2" y="4" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <circle id="Ellipse_357" data-name="Ellipse 357" cx="16" cy="16" r="16" fill="none"/>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#VectorClearFilterIcon)">
                <path id="Vector-2" data-name="Vector" d="M15.746,2.289l-1.1.86A8.192,8.192,0,1,0,15.891,11a.157.157,0,0,0-.1-.2l-1.106-.38a.156.156,0,0,0-.2.094c-.035.1-.074.2-.115.291a6.712,6.712,0,1,1-.9-6.747l-1.174.917a.156.156,0,0,0,.059.275l3.426.839a.157.157,0,0,0,.193-.15L16,2.409a.157.157,0,0,0-.254-.121Z" transform="translate(8 8)" fill="#fff"/>
            </g>
        </svg>
    )
}

export default ClearFilterIcon