import {SET_SELECTED_CHIP, CHECKED_IS_ACTIVE_CHIP} from "@actions/actionTypes";

export default function selectedChipReducer(state = 0, action) {
    switch (action.type) {
        case SET_SELECTED_CHIP:
            return action.chip
        case CHECKED_IS_ACTIVE_CHIP:
            return state && +action.sum < +state ? 0 : state
        default:
            return state;
    }
}
