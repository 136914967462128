import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import TemplateButton from "@components/templateButton"
import {FormattedMessage} from "react-intl";
import {
    FullScreenInIcon, SwitchIcon, HistoryIcon,
    InfoIcon, FullScreenOutIcon, HeaderCloseIcon,
    BurgerMenu, BalanceIcon, TotalBetsIcon
} from "../resource";
import History from "@components/history/history";
import InfoPopUp from "@components/infoPopUp";
import RightMenu from "@components/rightMenu";
import {subscribeRoundStartAction, subscribeRoundUpdateAction} from "@actions/gameAction";
import { subscribeBalanceUpdate } from "@actions/betsAction";
import {getLocalStorage} from "@services/localStorageService";
import {setViewSettings} from "@actions/viewSettingsAction";
import UserInfo from "@components/userInfo";
import Rules from "@components/rules";
import { inIframe } from "../utils/CommonUtil";
import { getLobbyUrl, isOpenedByLobby } from "../utils/HttpUtil";
import { abbrNum } from "@services/betService";
import { getLocalBets, clearActiveBetsInStorage, resetActiveBetsStore} from "@actions/betsAction";
import { RoundActionType } from "@constants/Enums";
import { getFullscreenElement, fullScreenToggle, removeFullScreenEventListner, addFullScreenEventListner } from "@services/fullScreenService";


function Header({round, subscribeRoundStartAction, subscribeRoundUpdateAction, subscribeBalanceUpdate, setViewSettings, userInfo, totalBets, getLocalBets, resetActiveBetsStore, clearActiveBetsInStorage, lobbyUrl}) {
    const [fullScreen, setFullScreen] = useState(!!getFullscreenElement())
    const [threeD, setThreeD] = useState(getLocalStorage("viewSettings"))
    const [history, setHistory] = useState(false)
    const [view, setView] = useState(false)
    const [exit, setExit] = useState(false)
    const [rightMenu, setRightMenu] = useState(false)
    const [rules, setRules] = useState(false)

    useEffect(() => {
        subscribeRoundStartAction();
        subscribeRoundUpdateAction();
        subscribeBalanceUpdate();

        const fullscreenchanged = (event) => {
            if (getFullscreenElement()) {
                setFullScreen(true);
            } else {
                setFullScreen(false);
            }
          }
          addFullScreenEventListner(fullscreenchanged);

          return () => {
            removeFullScreenEventListner(fullscreenchanged);
          }
    }, [])

    const submitMenu = (name) => {
        setRightMenu(false)
        if (name === "history") {
            setHistory(true)
        }
        if (name === "rules") {
            setRules(true)
        }
        if (name === "exit") {
            setExit(true)
        }
    }

    const changeState = (name) => {
        const states = {
            history: setHistory,
            view: setView,
            exit: setExit,
            rightMenu: setRightMenu,
            rules: setRules
        }
        Object.keys(states).forEach(state => state === name ? null : states[state](false))
    }

    const goLobby = () => {
        setExit(false);
        if (isOpenedByLobby() && inIframe()) {
            window.parent.postMessage("CLOSE", getLobbyUrl());
        } else {
            window.location = lobbyUrl;
        }
    }

    useEffect(() => {
        setThreeD(threeD);
        setViewSettings(threeD);
    }, [threeD])

    useEffect(() => {
        if (round && userInfo.Id) {
            if (round.actionType == RoundActionType.Bet) {
                getLocalBets(round.roundId)
            } else if (round.actionType != RoundActionType.Bet) {
                clearActiveBetsInStorage(round.roundId);
            }
        } 
    }, [round, userInfo.Id])

    return (<header>
        <div className="game-controller">
            <div className="info-box">
                <div className="game-info">
                    <p className="title"><FormattedMessage id="welcome_text"/></p>
                    <p className="description"> Round ID: {round?.roundId || ""}</p>
                </div>
                <div className="user-info">
                    <UserInfo title={<FormattedMessage id="balance"/>} value={userInfo.Balance || 0} icon={<BalanceIcon />} />
                    <UserInfo title={<FormattedMessage id="total_bet"/>} className="total-bets" value={abbrNum( Number(totalBets.toFixed(2)) || 0)} icon={<TotalBetsIcon />} />
                </div>
            </div>
            <div className="settings-box">
                <ul className="template-button-list desktop-icon">
                    <TemplateButton title={!!getFullscreenElement() ? <FormattedMessage id={"exit-full-screen"}/> :  <FormattedMessage id={"full-screen"}/>}
                                    icon={!!getFullscreenElement() ? <FullScreenOutIcon /> : <FullScreenInIcon/>}
                                    className="fullScreen desktop-icon"
                                    changeState={() => {
                                        fullScreenToggle();
                                    }}
                    />
                    <TemplateButton title={<FormattedMessage id={"switch-view"}/>}
                                    icon={<SwitchIcon/>}
                                    active={view}
                                    changeState={() => setThreeD(prev => !prev)} />
                    <TemplateButton title={<FormattedMessage id={"player_history"}/>}
                                    icon={<HistoryIcon/>}
                                    active={history}
                                    changeState={() => {setHistory(prev => !prev); changeState("history")}}/>
                    <TemplateButton title={<FormattedMessage id={"game_rules"}/>}
                                    active={rules}
                                    icon={<InfoIcon/>}
                                    changeState={() => {setRules(prev => !prev); changeState("rules")}}/>
                    <TemplateButton title={<FormattedMessage id={"close"}/>}
                                    icon={<HeaderCloseIcon/>}
                                    changeState={() => {setExit(true); changeState("exit")}}/>
                </ul>
                <ul className="template-button-list mobile-icon">
                    <TemplateButton title={<FormattedMessage id={"menu"}/>}
                                    icon={<BurgerMenu />}
                                    active={rightMenu}
                                    changeState={() => {setRightMenu(prev => !prev); changeState("rightMenu")}}
                    />
                </ul>
            </div>
        </div>
        <History handleCancel={setHistory} show={history}/>

        <InfoPopUp title={<FormattedMessage id="exit_to_lobby"/>}
                              show={exit}
                              description={<FormattedMessage id="are_you_sure_you_want_to_leave_the_game" />}
                              handleCancel={() => setExit(false)}
                              submit={() => goLobby()}/>

        <RightMenu handleCancel={() => setRightMenu(false)}
                   submitMenu={submitMenu}
                   show={rightMenu}/>

        <Rules handleCancel={() => setRules(false)} show={rules} />
    </header>)
}

function mapStateToProps(state) {
    return {
        round: state.round,
        totalBets: state.totalBets,
        userInfo: state.userInfo,
        isConnected: state.globalInfo.isConnected,
        lobbyUrl: state.globalInfo.lobbyUrl
    }
}

const mapDispatchToProps = {
    subscribeRoundStartAction,
    subscribeRoundUpdateAction,
    setViewSettings,
    getLocalBets,
    clearActiveBetsInStorage,
    resetActiveBetsStore,
    subscribeBalanceUpdate
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)