import React from "react";

function InfoIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48">
            <defs>
                <filter id="VectorInfoIcon" x="15" y="8" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Group_1486" data-name="Group 1486" transform="translate(-30 -26)">
                <circle id="Ellipse_356" data-name="Ellipse 356" cx="24" cy="24" r="24" transform="translate(30 26)" fill="none"/>
                <g transform="matrix(1, 0, 0, 1, 30, 26)" filter="url(#VectorInfoIcon)">
                    <path id="Vector-2" data-name="Vector" d="M4.6,22H1.4c-.221,0-.4-.1-.4-.233V8.233C1,8.1,1.18,8,1.4,8H4.6c.221,0,.4.1.4.233V21.767C5,21.9,4.821,22,4.6,22ZM3,4A2.98,2.98,0,0,1,.878,3.121,2.98,2.98,0,0,1,0,1,2.98,2.98,0,0,1,.878-1.121a3,3,0,0,1,4.243,0A3.021,3.021,0,0,1,6,1a3.021,3.021,0,0,1-.879,2.121A2.98,2.98,0,0,1,3,4Z" transform="translate(21 14)" fill="#fff"/>
                </g>
            </g>
        </svg>
    )
}

export default InfoIcon