import React from "react";

function HistoryIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 48 48">
            <defs>
                <filter id="VectorHistoryIcon" x="7" y="8" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Group_1484" data-name="Group 1484" transform="translate(-30 -26)">
                <circle id="Ellipse_354" data-name="Ellipse 354" cx="24" cy="24" r="24" transform="translate(30 26)" fill="none"/>
                <g transform="matrix(1, 0, 0, 1, 30, 26)" filter="url(#VectorHistoryIcon)">
                    <path id="Vector-2" data-name="Vector" d="M11.854,24a11.707,11.707,0,0,1-8.341-3.474A12.12,12.12,0,0,1,3.424,3.551,11.7,11.7,0,0,1,11.848,0h.01a11.73,11.73,0,0,1,9.334,4.61L19.543,5.916a9.931,9.931,0,0,0-.789-.9,9.694,9.694,0,0,0-3.1-2.119,9.675,9.675,0,0,0-7.592,0,9.693,9.693,0,0,0-3.1,2.119A9.9,9.9,0,0,0,2.1,12a9.855,9.855,0,0,0,2.854,6.983,9.692,9.692,0,0,0,3.1,2.118,9.563,9.563,0,0,0,3.8.776h.016a9.56,9.56,0,0,0,3.782-.776,9.674,9.674,0,0,0,3.1-2.118,9.833,9.833,0,0,0,2.094-3.14c.058-.139.114-.28.165-.421a.223.223,0,0,1,.112-.124.214.214,0,0,1,.166-.01l1.564.543a.229.229,0,0,1,.134.29,11.952,11.952,0,0,1-4.271,5.663A11.672,11.672,0,0,1,11.854,24ZM15.4,16.83a.224.224,0,0,1-.133-.044L10.71,13.418a.223.223,0,0,1-.091-.181V5.553a.222.222,0,0,1,.22-.223h1.327a.222.222,0,0,1,.22.223v6.908l3.932,2.877a.213.213,0,0,1,.089.141.227.227,0,0,1-.041.171l-.789,1.089A.215.215,0,0,1,15.4,16.83ZM22.6,8.5a.218.218,0,0,1-.051-.006L18.228,7.428a.219.219,0,0,1-.107-.061.222.222,0,0,1-.057-.109.232.232,0,0,1,.008-.123.227.227,0,0,1,.072-.1L22.492,3.6a.211.211,0,0,1,.134-.049.223.223,0,0,1,.221.225l-.021,4.507A.222.222,0,0,1,22.6,8.5Z" transform="translate(13 12)" fill="#fff"/>
                </g>
            </g>
        </svg>
    )
}

export default HistoryIcon