import React from "react";
import classNames from "classnames";
import {FormattedMessage} from "react-intl";

function PluseButton({ onClick }) {

    return (
        <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48">
            <defs>
                <filter id="Path_3384PluseButton" x="9.5" y="12.001" width="29" height="28" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="icon_outlined_editor_delete" data-name="icon/outlined/editor/delete" transform="translate(14 14)">
                <circle id="Ellipse_349" data-name="Ellipse 349" cx="24" cy="24" r="24" transform="translate(-14 -14)" fill="none" opacity="0.7"/>
                <g transform="matrix(1, 0, 0, 1, -14, -14)" filter="url(#Path_3384PluseButton)">
                    <path id="Path_3384PluseButton-2" data-name="Path 3384" d="M1.482-2.279H7.391V3.13h5.17V-2.279h5.922V-7.461H12.56V-12.87H7.391v5.409H1.482Z" transform="translate(14.02 28.87)" fill="#fff"/>
                </g>
            </g>
        </svg>
    )
}

export default PluseButton;