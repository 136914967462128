import React from "react";
import classNames from "classnames";

function InsurancNo({ onClick }) {

    return (
        <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48">
            <defs>
                <filter id="VectorNo" x="10.315" y="12.109" width="27.352" height="27.781" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="icon_outlined_editor_delete" transform="translate(14 14)">
                <circle id="Ellipse_349" cx="24" cy="24" r="24" transform="translate(-14 -14)" fill="none" opacity="0.7"/>
                <g transform="matrix(1, 0, 0, 1, -14, -14)" filter="url(#VectorNo)">
                    <path id="Vector-2" d="M8.644,7.641,14.8.307A.186.186,0,0,0,14.653,0h-1.87a.382.382,0,0,0-.288.134L7.42,6.183,2.346.134A.375.375,0,0,0,2.058,0H.188A.186.186,0,0,0,.045.307L6.2,7.641.045,14.974a.186.186,0,0,0,.143.307h1.87a.382.382,0,0,0,.288-.134L7.42,9.1l5.074,6.049a.375.375,0,0,0,.288.134h1.87a.186.186,0,0,0,.143-.307Z" transform="translate(16.57 16.36)" fill="#fff" stroke="#fff" strokeWidth="0.5"/>
                </g>
            </g>
        </svg>
    )
}

export default InsurancNo