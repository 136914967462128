import {
    SET_HISTORY,
    RESET_HISTORY,
    REQUEST_BET_HISTORY
} from "@actions/actionTypes";

export default function historyLoadingReducer(state = true, action) {
    switch (action.type) {
        case REQUEST_BET_HISTORY:
            return true
        case SET_HISTORY:
            return false
        case RESET_HISTORY:
            return true
        default:
            return state;
    }
}
