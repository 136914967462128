import {SET_DEALER_HAND} from "@actions/actionTypes";
import initialStore from "../../initialStore";

function DealerCardsReducer(state = initialStore.emptyObj, action) {
    switch (action.type) {
        case SET_DEALER_HAND:
            return action.dealerHand;
        default:
            return state;
    }
}

export default DealerCardsReducer