import React, { useEffect } from "react";
import { connect } from "react-redux";
import {subscribeCards} from "@actions/cardsAction";
import DealerHand from "./DealerHand";
import PlayerHands from "./PlayerHands";
import classNames from "classnames";


function Cards({ subscribeCards, threeD }) {

    useEffect(() => {
        subscribeCards();
    }, [])

    return (
        <div className={classNames("cards", {threeD})}>
            <DealerHand />
            <PlayerHands />
        </div>
    )
}

// function mapStateToProps(_) {
//     return {
//     }
// }

const mapDispatchToProps = {
    subscribeCards
}


export default connect(null, mapDispatchToProps)(Cards);