import React from "react";
import {connect} from "react-redux";
import classNames from "classnames";
import {FormattedMessage} from "react-intl";
import { RoundActionType, PlayerActionType } from "@constants/Enums";
import { emitPlayerAction, disableActionPanel } from "@actions/userActions";
import _2XButton from "@components/ActionsButtons/2XButton";
import MinusButton from "@components/ActionsButtons/MinusButton";
import PluseButton from "@components/ActionsButtons/PluseButton";
import TemplateButton from "@components/templateButton"

function ActionsPanel({ round, isCustomDisableActionPanel, emitPlayerAction, disableActionPanel }) {

    const emitAction = (action) => {
        disableActionPanel();
        emitPlayerAction(action);
    }

    return (
        <div className={classNames("action-panel", {show: round?.actionType == RoundActionType.PlayerAction && !isCustomDisableActionPanel})}>
           <ul className="action-panel-body template-button-list">
                <TemplateButton title={<FormattedMessage id={"double"}/>}
                                icon={<_2XButton/>}
                                active={false}
                                position="top"
                                changeState={() => emitAction(PlayerActionType.Double)}
                                className={classNames({hidden: round?.availableActions?.length && round.availableActions.indexOf(PlayerActionType.Double) == -1})} />
                <TemplateButton title={<FormattedMessage id={"hit"}/>}
                                icon={<PluseButton/>}
                                active={false}
                                position="top"
                                changeState={() => emitAction(PlayerActionType.Hit)}
                                className={classNames({hidden: round?.availableActions?.length && round.availableActions.indexOf(PlayerActionType.Hit) == -1})}/>
                <TemplateButton title={<FormattedMessage id={"stand"}/>}
                            icon={<MinusButton/>}
                            active={false}
                            position="top"
                            changeState={() => emitAction(PlayerActionType.Stand)}
                            className={classNames({hidden: round?.availableActions?.length && round.availableActions.indexOf(PlayerActionType.Stand) == -1})}/>
           </ul>
        </div>
    )
}

export const mapStateToProps = (state) => {
    return {
        round: state.round,
        isCustomDisableActionPanel: state.isCustomDisableActionPanel
    }
}

const mapDispatchToProps = {
    emitPlayerAction,
    disableActionPanel
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsPanel)