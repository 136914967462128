import BalanceIcon from "./balanceIcon";
import BurgerMenu from "./burderMenu";
import CalendarMonthsArrow from "./calendarMonthsArrow";
import CalendarYearsArrow from "./calendarYearsArrow";
import CloseIcon from "./closeIcon";
import ClearFilterIcon from "./clearFilterIcon";
import DoneIcon from "./doneIcon";
import DropDownArrowIcon from "./dropDownArrowIcon";
import FullScreenInIcon from "./fullScreenInIcon";
import FullScreenOutIcon from "./fullScreenOutIcon";
import HistoryFilterIcon from "./historyFilterIcon";
import HistoryIcon from "./historyIcon";
import HistoryLoadingIcon from "./historyLoadingIcon";
import HistoryMobileIcon from "./historyMobileIcon";
import HistoryTotalIcon from "./historyTotalIcon";
import InfoIcon from "./infoIcon";
import InfoMobileIcon from "./infoMobileIcon";
import ModalButtonCloseIcon from "./modalButtonCloseIcon";
import ModalCloseIcon from "./modalCloseIcon";
import SliderArrowIcon from "./sliderArrowIcon";
import SwitchIcon from "./switchIcon";
import TotalBetsIcon from "./totalBetsIcon";
import ViewSettingsMobileIcon from "./viewSettingsMobileIcon";
import WarningIcon from "./warningIcon";
import HeaderCloseIcon from "./headerCloseIcon";
import FilterIcon from "./filterIcon";
import CalendarIcon from "./calendarIcon";
import CalendarDividerIcon from "./calendarDividerIcon";
import DeleteBetsIcon from "./deleteBetsIcon";
import CloseMobileIcon from "./closeMobileIcon";
import BackRoundHistoryIcon from "./backRoundHistoryIcon";
import NoDataIcon from "./noDataIcon";

export {
    BalanceIcon,
    BurgerMenu,
    CalendarMonthsArrow,
    CalendarYearsArrow,
    CloseIcon,
    ClearFilterIcon,
    DoneIcon,
    DropDownArrowIcon,
    FullScreenInIcon,
    FullScreenOutIcon,
    HistoryFilterIcon,
    HistoryIcon,
    HistoryLoadingIcon,
    HistoryMobileIcon,
    HistoryTotalIcon,
    InfoIcon,
    InfoMobileIcon,
    ModalButtonCloseIcon,
    ModalCloseIcon,
    SliderArrowIcon,
    SwitchIcon,
    TotalBetsIcon,
    ViewSettingsMobileIcon,
    WarningIcon,
    HeaderCloseIcon,
    FilterIcon,
    CalendarIcon,
    CalendarDividerIcon,
    DeleteBetsIcon,
    CloseMobileIcon,
    BackRoundHistoryIcon,
    NoDataIcon
}