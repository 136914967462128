import React from "react";
import {FormattedMessage} from "react-intl";
import { OwnerTypes, Prediction } from "@constants/Enums";
import RoundInfoItem from "./RoundInfoItem";
import CardList from "./CardList";
import {floatNumberWithSpaces} from "@services/numberFormatServices";

export function RoundHistory({round}) {
    
    return (
        <div className="history-round">
            <div className="history-round-info">
                <RoundInfoItem time={round.startTime}
                                timeTitle="game_start"
                                balance={round.balanceBefore}
                                balanceTitle="balance_before_the_round" />
                <RoundInfoItem time={round.endTime}
                                timeTitle="game_end"
                                balance={round.balanceAfter}
                                balanceTitle="balance_after_the_round" />
            </div>
            <div className="round-cards">
                {
                    round.playerHands.map(hand => (
                        <CardList key={hand.handId}
                                    points={hand.points}
                                    player={<FormattedMessage id={OwnerTypes[hand.owner]} />}
                                    cards={hand.cards}
                                    isBlackJack={hand.isBlackJack} />
                    ))
                }
                
                <CardList points={round.dealerHand.points}
                        player={<FormattedMessage id={OwnerTypes[round.dealerHand.owner]} />}
                        cards={round.dealerHand.cards}
                        isBlackJack={round.dealerHand.isBlackJack} />
            </div>
            <div className="round-bets">
                <div className="tbl">
                    <div className="row">
                        <div className="cell"><FormattedMessage id="bet_type" /></div>
                        <div className="cell"><FormattedMessage id="bet" /></div>
                        <div className="cell"><FormattedMessage id="win_lose" /></div>
                    </div>
                    {
                        round.bets.map(item => (
                            <div className="row" key={item.type}>
                                <div className="cell"><FormattedMessage id={Prediction[item.type]} /></div>
                                <div className="cell">{floatNumberWithSpaces(item.stake)}</div>
                                <div className="cell">{floatNumberWithSpaces(item.win || -item.stake)}</div>
                            </div>
                        ))
                    }
                    <div className="row">
                        <div className="cell"><FormattedMessage id="total" />:</div>
                        <div className="cell">{floatNumberWithSpaces(round.bets.reduce((a, b) => b.stake + a, 0))}</div>
                        <div className="cell">{floatNumberWithSpaces(round.bets.reduce((a, b) => (b.win || -b.stake || 0) + a, 0))}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default RoundHistory;