import React, { useState } from "react";
import {connect} from "react-redux";
import classNames from "classnames";
import {FormattedMessage} from "react-intl";
import { useEffect } from "react";
import { Prediction, RoundActionType } from "@constants/Enums";
import {subscribeTickerWorker, unSubscribeTickerWorker} from "@actions/workerActions";

function ActionTypeInfo({ round, activeBets, unSubscribeTickerWorker, subscribeTickerWorker }) {
    const [isExistsBetOnMain, setIsExistsBetOnMain] = useState(false);
    const [customIsShow, setCustomIsShow] = useState(false);

    useEffect(() => {
        let value = !!activeBets.find(item => item.Type === Prediction.Main && item.Stake > 0);
        setIsExistsBetOnMain(value);
    }, [activeBets])

    useEffect(() => {
        function getGameTimeDiff(e) {
            const  timer = JSON.parse(e.data);
            setCustomIsShow(timer.data > 4000);
            
        }
        subscribeTickerWorker(getGameTimeDiff);
        return () => {
            unSubscribeTickerWorker(getGameTimeDiff);
        }
    }, [])
    return (
        <div className={classNames("action-type-info", {show: round?.actionType == RoundActionType.Bet && customIsShow})}>
            { isExistsBetOnMain ?
                <FormattedMessage id="bet_on_sidebet_is_available" /> 
            :
                <FormattedMessage id="bet_on_sidebet_is_not_available" />
            }
        </div>
    )
}

export const mapStateToProps = (state) => {
    return {
        round: state.round,
        activeBets: state.activeBets
    }
}

const mapDispatchToProps = {
    unSubscribeTickerWorker,
    subscribeTickerWorker
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionTypeInfo)