import React, {useEffect, useState, useContext} from "react";
import classNames from "classnames";
import {connect} from "react-redux";
import ChipsListOnBoard from "./ChipsListOnBoard";
import {Prediction} from "@constants/Enums";
import {ResizeContext} from "../../contexts/resizeContext";

function UserActions({threeD, activeBets}) {
    const [bets, setBets] = useState({});
    const device = useContext(ResizeContext);

    useEffect(() => {
        var betsByPrediction = {};
        activeBets.forEach(element => {
            betsByPrediction[element.Type] = element.Stake;
        });

        setBets(betsByPrediction);
    }, [activeBets])

    return (
        <div className={classNames("user-actions-area", {threeD: threeD && !device.mobile})}>
            <div className="board-body">
                <div className="left">
                    <ChipsListOnBoard stack={bets[Prediction._21plus3]} threeD={threeD && !device.mobile}/>
                </div>
                <div className="middle">
                    <ChipsListOnBoard stack={bets[Prediction.Main]} threeD={threeD && !device.mobile}/>
                </div>
                <div className="right">
                    <ChipsListOnBoard stack={bets[Prediction.PerfectPairs]} threeD={threeD && !device.mobile}/>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        activeBets: state.activeBets
    }
}

export default connect(mapStateToProps, null)(UserActions)

