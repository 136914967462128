import React from "react";

function BackRoundHistoryIcon() {
    return (
        <svg id="Group_1490" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 26">
            <defs>
                <filter id="VectorBackRoundHistoryIcon" x="0" y="2" width="24" height="24" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <circle id="Ellipse_360" cx="12" cy="12" r="12" fill="none" opacity="0.2"/>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#VectorBackRoundHistoryIcon)">
                <path id="Vector-2" d="M11.87,5.36H2.33L8.04.236A.135.135,0,0,0,7.955,0H6.512a.257.257,0,0,0-.171.066L.18,5.592a.538.538,0,0,0-.133.183.555.555,0,0,0,0,.447.537.537,0,0,0,.133.183l6.2,5.56A.126.126,0,0,0,6.462,12H7.953a.135.135,0,0,0,.085-.236L2.33,6.64H11.87A.133.133,0,0,0,12,6.506V5.494A.133.133,0,0,0,11.87,5.36Z" transform="translate(6 6)" fill="#fff"/>
            </g>
        </svg>
    )
}

export default BackRoundHistoryIcon