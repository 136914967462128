import React from "react";

function HistoryLoadingIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="icon_outlined_application_loading-3-quarters_clip_group_" data-name="icon/outlined/application/loading-3-quarters (clip group)" viewBox="0 0 24 24">
            <rect id="icon_outlined_application_loading-3-quarters_style_" data-name="icon/outlined/application/loading-3-quarters (style)" fill="none"/>
            <path id="Vector" d="M12,24A11.987,11.987,0,0,1,0,12a.844.844,0,1,1,1.687,0,10.311,10.311,0,0,0,17.606,7.291A10.309,10.309,0,0,0,12,1.688.844.844,0,0,1,12,0a12,12,0,0,1,4.671,23.056A11.885,11.885,0,0,1,12,24Z" fill="#878794"/>
        </svg>
    )
}

export default HistoryLoadingIcon