import React from "react";

function FullScreenInIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48">
            <defs>
                <filter id="VectorFullScreenInIcon" x="8" y="10" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Group_1481" data-name="Group 1481" transform="translate(-30 -26)">
                <circle id="Ellipse_351" data-name="Ellipse 351" cx="24" cy="24" r="24" transform="translate(30 26)" fill="none"/>
                <g transform="matrix(1, 0, 0, 1, 30, 26)" filter="url(#VectorFullScreenInIcon)">
                    <path id="Vector-2" data-name="Vector" d="M20.76,21l-.027,0-4.779-.563a.252.252,0,0,1-.121-.052.247.247,0,0,1-.076-.108.234.234,0,0,1-.007-.132.242.242,0,0,1,.064-.116l1.309-1.309-3.989-3.988a.238.238,0,0,1,0-.337L14.4,13.134a.238.238,0,0,1,.337,0l3.989,3.984,1.3-1.3a.236.236,0,0,1,.168-.07.239.239,0,0,1,.238.21L21,20.732a.238.238,0,0,1-.06.187A.24.24,0,0,1,20.76,21ZM.238,21a.238.238,0,0,1-.177-.08A.241.241,0,0,1,0,20.729l.565-4.775a.229.229,0,0,1,.052-.121.239.239,0,0,1,.108-.076A.245.245,0,0,1,.8,15.745a.257.257,0,0,1,.053.005.242.242,0,0,1,.116.064l1.3,1.3,3.989-3.984a.238.238,0,0,1,.337,0L7.865,14.4a.238.238,0,0,1,0,.337L3.877,18.719l1.309,1.307a.239.239,0,0,1-.14.405l-4.779.563ZM14.567,7.935a.239.239,0,0,1-.169-.069L13.134,6.6a.238.238,0,0,1,0-.337l3.989-3.981L15.813.974a.239.239,0,0,1,.14-.405L20.732,0l.029,0a.238.238,0,0,1,.177.08A.241.241,0,0,1,21,.27l-.565,4.775a.239.239,0,0,1-.05.121.249.249,0,0,1-.109.076.225.225,0,0,1-.076.013.235.235,0,0,1-.054-.006.242.242,0,0,1-.116-.064l-1.3-1.3L14.735,7.867A.236.236,0,0,1,14.567,7.935Zm-8.135,0a.236.236,0,0,1-.168-.069L2.275,3.881l-1.3,1.3a.236.236,0,0,1-.168.07.239.239,0,0,1-.238-.21L0,.267A.238.238,0,0,1,.061.08.24.24,0,0,1,.24,0L.267,0,5.046.566a.229.229,0,0,1,.121.052.239.239,0,0,1,.076.108A.232.232,0,0,1,5.25.856a.25.25,0,0,1-.063.116L3.877,2.28,7.865,6.268a.238.238,0,0,1,0,.337L6.6,7.867A.239.239,0,0,1,6.432,7.935Z" transform="translate(14 14)" fill="#fff"/>
                </g>
            </g>
        </svg>
    )
}

export default FullScreenInIcon