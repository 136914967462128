import {SET_USER_SESSION} from "@actions/actionTypes";
import initialStore from "../initialStore";

export  function userReducer(state = initialStore.emptyObj, action) {
    switch (action.type) {
        case SET_USER_SESSION:
            return {...state, id: action.id}
        default:
            return state;
    }
}