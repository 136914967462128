import React from "react";
import Chips from "./Chips";

function ChipsListOnBoard({threeD, stack}) {
    return (
        <ul className="chips-list">
            <Chips stack={stack} isThreeD={threeD}/>
        </ul>
    )
}

export default ChipsListOnBoard;