import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import { ModalCloseIcon, BackRoundHistoryIcon} from "../../resource"; //HistoryFilterIcon,
import HistoryList from "@components/history/HistoryList";
// import HistoryFilter from "@components/history/historyFilter";
import classNames from "classnames";
import Modal from "@components/modal";
import {
    resetHistory,
    getHistory
} from "@actions/historyAction";
import {removeDay} from "@services/timestampService";
import RoundHistory from "./RoundHistory/RoundHistory";
import { FormattedMessage } from "react-intl";


function History({handleCancel, show, resetHistory, getHistory}) {
    const [Status, setStatus] = useState("");
    const tadayFrom = new Date();
    tadayFrom.setHours(0, 0, 0, 0);
    const tadayTo = new Date();
    tadayTo.setHours(23, 59, 59, 0);
    const [createdFrom, setCreatedFrom] = useState(removeDay(tadayFrom, 7));
    const [createdTo , setCreatedTo] = useState(tadayTo);
    const [showFilter, setShowFilter] = useState(false);
    const [limit] = useState(30);
    const [page, setPage] = useState(0);
    const [selectedRound, setSelectedRound] = useState(null);

    useEffect(() => {
        if (!show) {
            setPage(1);
            setStatus("");
            const tadayFrom = new Date();
            tadayFrom.setHours(0, 0, 0, 0);
            const tadayTo = new Date();
            tadayTo.setHours(23, 59, 59, 0);
            setCreatedFrom(removeDay(tadayFrom, 7));
            setCreatedTo(tadayTo);
        } else {
            resetHistory();
        }
    }, [show])

    useEffect(() => {
        if (show) {
            fetchHistory();
        }
    }, [show, page]);



    const fetchHistory = () => {
        getHistory(limit, page, Status, createdFrom, createdTo)
    }

    return (
        <Modal show={show}
               handleCancel={() =>  handleCancel(false)}
               parentClass={"history"}
        >
            <div className="modal-header">
                <div className="modal-header-title">
                    { selectedRound && <>
                        <FormattedMessage id="round_id" />: {selectedRound.roundId}
                    </>}
                </div>
                <div className="modal-header-control">
                    {/* <button className={classNames("circled-button small", {active: showFilter})} onClick={() => setShowFilter(prev => !prev)}>
                        <HistoryFilterIcon/>
                    </button> */}
                    {selectedRound && <button className={classNames("circled-button small")} onClick={() => setSelectedRound(null)}>
                        <BackRoundHistoryIcon/>
                    </button>}
                    <button className="circled-button small" onClick={() =>  handleCancel(false)}>
                        <ModalCloseIcon/>
                    </button>
                </div>
            </div>
            <div className={classNames("history", {showFilter})}>
                {/* {
                    showFilter ? <HistoryFilter Status={Status}
                                                setStatus={setStatus}
                                                limit={limit}
                                                setCreatedFrom={setCreatedFrom}
                                                setCreatedTo={setCreatedTo}
                                                createdTo={createdTo}
                                                createdFrom={createdFrom}
                                                setPage={setPage}
                                                fetchHistory={fetchHistory}/> : ""
                } */}
                {selectedRound && <RoundHistory round={selectedRound} /> || <HistoryList setPage={setPage} fetchHistory={fetchHistory} setSelectedRound={setSelectedRound}/>}
            </div>
        </Modal>)

}

const mapDispatchToProps = {
    resetHistory,
    getHistory,

}

export default connect(null, mapDispatchToProps)( History);
