import React from "react";
import classNames from "classnames";
import {FormattedMessage} from "react-intl";

function MinusButton({ onClick }) {

    return (
        <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48">
            <defs>
                <filter id="Path_3385MinusButton" x="10" y="17.501" width="28" height="17" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="icon_outlined_editor_delete" transform="translate(14 14)">
                <circle id="Ellipse_349" cx="24" cy="24" r="24" transform="translate(-14 -14)" fill="none" opacity="0.7"/>
                <g transform="matrix(1, 0, 0, 1, -14, -14)" filter="url(#Path_3385MinusButton)">
                    <path id="Path_3385MinusButton-2" d="M2.2-4.351h16v-5H2.2Z" transform="translate(13.8 30.85)" fill="#fff"/>
                </g>
            </g>
        </svg>
    )
}

export default MinusButton;