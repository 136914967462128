import React from "react";
import {connect} from "react-redux";
import classNames from "classnames";
import {FormattedMessage} from "react-intl";
import { RoundActionType, InsuranceType } from "@constants/Enums";
import { emitInsuranceAction, disableInsurancePanel } from "@actions/userActions";
import InsurancNo from "@components/ActionsButtons/InsurancNo";
import InsurancYes from "@components/ActionsButtons/InsurancYes";
import TemplateButton from "@components/templateButton"

function InsurancePanel({ round, isCustomDisableInsurancePanel, emitInsuranceAction, disableInsurancePanel }) {

    const emitAction = (action) => {
        disableInsurancePanel();
        emitInsuranceAction(action);
    }

    return (
        <div className={classNames("action-panel", {show: round?.actionType == RoundActionType.Insurance && !isCustomDisableInsurancePanel})}>
           <ul className="action-panel-body template-button-list">
                <TemplateButton title={<FormattedMessage id={"no"}/>}
                                icon={<InsurancNo/>}
                                active={false}
                                position="top"
                                changeState={() => emitAction(InsuranceType.No)} />
                <span className="description">
                    <FormattedMessage id="1_insurance_for_seat_1" />
                </span>
                <TemplateButton title={<FormattedMessage id={"yes"}/>}
                                icon={<InsurancYes/>}
                                active={false}
                                position="top"
                                changeState={() => emitAction(InsuranceType.Yes)} />
           </ul>
        </div>
    )
}

export const mapStateToProps = (state) => {
    return {
        round: state.round,
        isCustomDisableInsurancePanel: state.isCustomDisableInsurancePanel
    }
}

const mapDispatchToProps = {
    emitInsuranceAction,
    disableInsurancePanel
}

export default connect(mapStateToProps, mapDispatchToProps)(InsurancePanel)