import React from "react";
import ToolTip from "@components/toolTip/toolTip";
import classNames from "classnames";

function TemplateButton({icon, title, changeState, active, position, className, disabled}) {
    return (
        <li className={classNames(`template-button-item ${className ? className : ""}`, {active, disabled})} onClick={changeState}>
            <ToolTip title={title} position={position}>
                {icon}
            </ToolTip>
        </li>
    )
}

export default TemplateButton