import React from "react";

function DeleteBets({parentName}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48">
            <defs>
                <filter id={`${parentName}VectorDeleteBets`} x="8" y="10"  filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="icon_outlined_editor_delete" data-name="icon/outlined/editor/delete" transform="translate(14 14)">
                <circle id="Ellipse_349" data-name="Ellipse 349" cx="24" cy="24" r="24" transform="translate(-14 -14)" fill="none" opacity="0.7"/>
                <g transform="matrix(1, 0, 0, 1, -14, -14)" filter={`url(#${parentName}VectorDeleteBets)`}>
                    <path id="Vector-2" data-name="Vector" className="disabled" d="M15.911,20H4.089a1.636,1.636,0,0,1-1.665-1.525L1.781,5.4H.208A.2.2,0,0,1,0,5.2V4.4a.818.818,0,0,1,.833-.8H4.167v-2A1.636,1.636,0,0,1,5.833,0h8.334a1.636,1.636,0,0,1,1.666,1.6v2h3.334A.818.818,0,0,1,20,4.4v.8a.2.2,0,0,1-.208.2H18.219l-.643,13.075A1.636,1.636,0,0,1,15.911,20ZM3.659,5.4l.63,12.8H15.711l.63-12.8ZM6.042,3.6h7.916V1.8h.209a.205.205,0,0,1-.209-.2v.2H6.042Z" transform="translate(14 14)" fill="#fff"/>
                </g>
            </g>
        </svg>
    )
}

export default DeleteBets