import React from "react";
import classNames from "classnames";

function OneHandButton({ onClick }) {
    
    return (
        <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48">
            <defs>
                <filter id="Path_3397OneHandButton" x="4.5" y="4.7" width="38.852" height="42.506" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="icon_outlined_editor_delete" transform="translate(14 14)">
                <circle id="Ellipse_349" cx="24" cy="24" r="24" transform="translate(-14 -14)" fill="none" opacity="0.7"/>
                <g transform="matrix(1, 0, 0, 1, -14, -14)" filter="url(#Path_3397OneHandButton)">
                    <path id="Path_3397OneHandButton-2" d="M15.856,74.532a2.361,2.361,0,0,0-2.895-1.671l-11.21,3A2.363,2.363,0,0,0,.081,78.759L4.976,97.028A2.361,2.361,0,0,0,7.87,98.7l11.21-3A2.363,2.363,0,0,0,20.751,92.8ZM7.133,87.02a.628.628,0,0,1-.18-.672l1.714-5.075A.628.628,0,0,1,9.677,81L13.7,84.54a.628.628,0,0,1,.18.672l-1.714,5.075a.628.628,0,0,1-1.009.27Z" transform="matrix(0.97, 0.26, -0.26, 0.97, 36.07, -61.6)" fill="#fff"/>
                </g>
            </g>
        </svg>
    )
}

export default OneHandButton