import React  from "react";
import {floatNumberWithSpaces} from "@services/numberFormatServices";
import {getDateDDMMYYUtc} from "@services/timestampService";
import {FormattedMessage} from "react-intl";
import classNames from "classnames";
import {injectIntl} from "react-intl";
import {Prediction} from "@constants/Enums";

function HistoryItem({item, onClick, intl}) {
    return (
        <tr onClick={onClick} className={classNames({sum: item.startDay})}>
            <td>{item.roundId}</td>
            <td>{getDateDDMMYYUtc(item.startTime, intl, "dd-mm-time")}</td>
            <td>{floatNumberWithSpaces(item.bets.reduce((a, b) => a + b.stake, 0))}</td>
            <td>{floatNumberWithSpaces(item.bets.reduce((a, b) => a + (b.win || -b.stake), 0) || `0`)}</td>
        </tr>)
}

export default injectIntl((HistoryItem))