export function getFullscreenElement() {
    return  document.fullscreenElement || 
            document.webkitFullscreenElement || 
            document.mozFullScreenElement || 
            document.msFullscreenElement;
}

export const fullScreenToggle = () => {
    const root = document.body;
    try {
        if (!!getFullscreenElement()) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
              } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
              } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
              }
        } else {
            if (root.requestFullscreen) {
                root.requestFullscreen({navigationUI: "hide"}).then(() => {
    
                }).catch(e => {
                    console.error(e)
                });
            } else if (root.webkitRequestFullscreen) { /* Safari */
                root.webkitRequestFullscreen({navigationUI: "hide"});
            } else if (root.msRequestFullscreen) { /* IE11 */
                root.msRequestFullscreen({navigationUI: "hide"});
            }
        }
    } catch(e) {
        console.error(e)
    }
    
}

export const addFullScreenEventListner = (fullscreenchanged) => {
    document.addEventListener('webkitfullscreenchange', fullscreenchanged);
    document.addEventListener('fullscreenchange', fullscreenchanged);
    document.addEventListener('mozfullscreenchange', fullscreenchanged);
}

export const removeFullScreenEventListner = (fullscreenchanged) => {
    document.removeEventListener('webkitfullscreenchange', fullscreenchanged);
    document.removeEventListener('fullscreenchange', fullscreenchanged);
    document.removeEventListener('mozfullscreenchange', fullscreenchanged);
}