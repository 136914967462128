import initialStore from "../initialStore";
import {
    SET_AVAILABLE_LANGUAGES,
    SET_SELECTED_LANGUAGE_MESSAGE
} from "@actions/actionTypes";

export function availableLanguagesReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_AVAILABLE_LANGUAGES:
            return action.languages;
        default:
            return state;
    }
}

export function selectedLanguagesReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_SELECTED_LANGUAGE_MESSAGE:
            return action.language;
        default:
            return state;
    }
}