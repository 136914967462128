import React from "react";

function InfoMobileIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 20 31.202">
            <defs>
                <filter id="VectorInfoMobileIcon" x="2" y="0" width="16.801" height="31.202" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Group_1517" data-name="Group 1517" transform="translate(-54 -307)">
                <rect id="Rectangle_709" data-name="Rectangle 709" width="20" height="20" transform="translate(54 311)" fill="none"/>
                <g transform="matrix(1, 0, 0, 1, 54, 307)" filter="url(#VectorInfoMobileIcon)">
                    <path id="Vector-2" data-name="Vector" d="M3.68,19.2H1.12c-.177,0-.32-.084-.32-.187V8.187C.8,8.084.944,8,1.12,8H3.68c.177,0,.32.084.32.187V19.014C4,19.118,3.857,19.2,3.68,19.2ZM2.4,4.8A2.414,2.414,0,0,1,.7,4.1,2.4,2.4,0,0,1,.7.7,2.4,2.4,0,0,1,4.1.7,2.4,2.4,0,0,1,4.1,4.1,2.414,2.414,0,0,1,2.4,4.8Z" transform="translate(8 4)" fill="#fff"/>
                </g>
            </g>
        </svg>
    )
}

export default InfoMobileIcon;