import {DefaultLanguageCode} from "@constants/Enums";

const store = {
    settings: {
        lang: DefaultLanguageCode
    },
    emptyObj: {},
    emptyArr: [],
    globalInfo: {
        isConnected: false,
        session:"",
        lobbyUrl: "",
        detectNewLogin: false
    },
    totalBets: 0,
    winnerList: [], //getWinnerList(),
    history: {
        data: [],
        hasNext: false,
    },
    userBetList: [
        {Type: 0, Stake :0},
        {Type: 1, Stake :0},
        {Type: 2, Stake :0},
        {Type: 3, Stake :0},
        {Type: 4, Stake :0},
        {Type: 5, Stake :0},
        {Type: 6, Stake :0},
    ],
    cards: {
        playerA: [],
        playerB: [],
    },
    isEnabledMainBetArea: false,
    timeTicker: {
        diff: 0,
        start: 0,
        end: 0
    },
    gameHistory: [],
    userInfo: {
        Balance: 0,
        Currency: "",
        Id: "",
        Image: null,
        Name: ""
    },
    videoStreamConfig: {
        Live: null,
        StreamName: ""
    },
    limit: {
        Max: 0,
        Min: 0,
        Nominal: []
    }
}

export default store;

function getWinnerList() {
    let result = [];
    for (let i = 0; i < 100; i++) {
        result.push({
            PlayerName: (Math.random() + 1).toString(36).substring(Math.random() * 10) + i,
            WonAmount: Math.round(Math.random() * 100000)
        })
    }
    return result
}