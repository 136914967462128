import {
    SET_USER_SESSION,
    SEND_PLAYER_ACTION,
    SEND_INSURANCE_ACTION,
    SEND_SPLIT_ACTION,
    SET_CUSTOM_DISABLE_INSURANCE_PANEL,
    SET_CUSTOM_DISABLE_SELECT_HAND_PANEL,
    SET_CUSTOM_DISABLE_ACTION_PANEL
} from "./actionTypes";

export function setUserSessionId(id) {
    return dispatch => {
        dispatch({type: SET_USER_SESSION, id});
    }
}

export function emitPlayerAction(playerAction) {
    return dispatch => {
        dispatch({
            eventEmit: SEND_PLAYER_ACTION,
            emitData: {Type: playerAction}
        });
    }
}

export function emitInsuranceAction(insuranceAction) {
    return dispatch => {
        dispatch({
            eventEmit: SEND_INSURANCE_ACTION,
            emitData: {Type: insuranceAction}
        });
    }
}

export function emitSplitAction(splitAction) {
    return dispatch => {
        dispatch({
            eventEmit: SEND_SPLIT_ACTION,
            emitData: {Type: splitAction}
        });
    }
}


export function disableInsurancePanel() {
    return dispatch => {
        dispatch({
            type: SET_CUSTOM_DISABLE_INSURANCE_PANEL,
            isEnable: true
        });
    }
}

export function enableInsurancePanel() {
    return dispatch => {
        dispatch({
            type: SET_CUSTOM_DISABLE_INSURANCE_PANEL,
            isEnable: false
        });
    }
}

export function disableSelectHandPanel() {
    return dispatch => {
        dispatch({
            type: SET_CUSTOM_DISABLE_SELECT_HAND_PANEL,
            isEnable: true
        });
    }
}

export function enableSelectHandPanel() {
    return dispatch => {
        dispatch({
            type: SET_CUSTOM_DISABLE_SELECT_HAND_PANEL,
            isEnable: false
        });
    }
}

export function disableActionPanel() {
    return dispatch => {
        dispatch({
            type: SET_CUSTOM_DISABLE_ACTION_PANEL,
            isEnable: true
        });
    }
}

export function enableActionPanel() {
    return dispatch => {
        dispatch({
            type: SET_CUSTOM_DISABLE_ACTION_PANEL,
            isEnable: false
        });
    }
}