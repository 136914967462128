import React from "react";
import { FormattedMessage } from "react-intl";
import HideToolBar1 from "@assets/img/hideToolBar1.png";
import HideToolBar2 from "@assets/img/hideToolBar2.png";
import HeaderCloseIcon from "@resource/headerCloseIcon";
import { useState } from "react";
import classNames from "classnames";



function HidetoolBar() {
    const [isShow, setIsShow] = useState(true);

    return (
        <div className={classNames("hide-tool-bar-wrapper", {show: isShow})}>
            <div className="hide-tool-bar-container">
                <div className="hide-tool-bar-head">
                    <p className="hide-tool-bar-head-text">
                        <FormattedMessage id="hide_tool_bat_description" />
                    </p>
                    <span role="button"
                            tabIndex="0"
                            className="hide-tool-bar-close-button"
                            onClick={() => setIsShow(false)}>
                        <HeaderCloseIcon />
                    </span>
                </div>
                <div className="hide-tool-bar-content">
                    <img src={HideToolBar1} alt="hide-tool-bar-img" className="hide-tool-bar-img" />
                    <img src={HideToolBar2} alt="hide-tool-bar-img-delay" className="hide-tool-bar-img-delay" />
                </div>
            </div>
        </div>
    )
}

export default HidetoolBar;