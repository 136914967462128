import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { TimeTicker, RoundActionType } from "@constants/Enums";
import classNames from "classnames";


function Ticker({ diff, betDuration, actionType, customDisable}) {
    const [showingTimeProgressPercent, setShowingTimeProgressPercent] = useState(290);
    const [timeTickerClassName, setTimeTickerClassName] = useState("white");
    const timer = useRef(0);
    const allStep = 290

    useEffect(() => {
        if (betDuration) {
            setShowingTimeProgressPercent(  allStep * ((betDuration * 1000) / diff))
            let step = allStep / betDuration
            const timerId = setInterval(() => {
                setShowingTimeProgressPercent(prev => {
                    if (prev <= 0) {
                        clearInterval(timer.current);
                        return 0;
                    }
                    return  prev - step;
                });
            }, 1000);
            timer.current = timerId
        }

        return () => {
            clearInterval(timer.current);
        }
    }, [betDuration])


    useEffect(() => {
        if (TimeTicker[betDuration]) {
            setTimeTickerClassName(TimeTicker[betDuration])
        } else if (betDuration > 6) {
            setTimeTickerClassName("green")
        }
    }, [betDuration])

    return(
        <div className={classNames("timer", timeTickerClassName, {"show-time-ticker": betDuration && !customDisable})}>
            <div className="timer-message">
                {
                    timeTickerClassName === "green" && actionType != RoundActionType.DealerAction ?
                        (
                            (actionType == RoundActionType.PlayerAction ||
                            actionType == RoundActionType.Split ||
                            actionType == RoundActionType.Insurance) ? <FormattedMessage id="sec_to_choose" /> : <FormattedMessage id="sec_to_bets" />
                        ) : ""
                }
                {
                    timeTickerClassName === "yellow" ? <FormattedMessage id="hurry_up" /> : ""
                }
                {
                    timeTickerClassName === "red" ? (actionType == RoundActionType.Bet? <FormattedMessage id="no_more_bets" /> : <FormattedMessage id="hurry_up" /> ) : ""
                }
                {
                    timeTickerClassName === "white" ? <FormattedMessage id="time_up" /> : ""
                }
            </div>
            <div className="timer-ticker">
                <svg viewBox="0 0 51 51">
                    <circle className="outer" cx="18" cy="22" r="23"
                            style={
                                {
                                    strokeDashoffset: (allStep - showingTimeProgressPercent) + '%',
                                    strokeWidth: "calc(4px * var(--device-pixel-ratio))"
                                }
                            }

                            transform="rotate(-90, 22, 20)"
                    />
                </svg>
                <div className="text">{betDuration < 0 ? 0 : betDuration}</div>
            </div>
        </div>
    )
}

export default Ticker