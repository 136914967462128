import {
    SET_GAME_CONDITION
} from "@actions/actionTypes";

export default function gameConditionReducer(state = false, action) {
    switch (action.type) {
        case SET_GAME_CONDITION:
            return action.condition
        default:
            return state;
    }
}