import { call,  put, } from 'redux-saga/effects';
 import { SET_ALERT_MSG } from '@actions/actionTypes';


export const safe = (handler = null, saga, ...args) => function* (action) {
    try {
        yield call(saga, ...args, action)
    } catch (err) {
        let errMsg = ''
        try {
            errMsg = JSON.parse(err.message)
        } catch (e) {
            errMsg = {
                statusCode: navigator.onLine ? "unexpected" : "offline"
            }
        }
        console.error('error handling', err)
        yield call(handler, ...args, errMsg);
    }
}

export function* onError (err, a) {
    yield put({type: SET_ALERT_MSG, data: {type: 'error', messages: `error_${err.statusCode}`}})
}