import React from "react";

function HistoryTotalIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 14">
            <g id="layer1HistoryTotalIcon" transform="translate(1.471 -289.062)">
                <rect id="Rectangle_1094" data-name="Rectangle 1094" width="12" height="14" transform="translate(-1.471 289.062)" fill="none"/>
                <path id="path4744" d="M.929,289.062a.4.4,0,0,0-.37.264.451.451,0,0,0,.086.467l4.928,5.271L.645,300.331a.451.451,0,0,0-.086.467.4.4,0,0,0,.37.264H11.347a.415.415,0,0,0,.4-.427v-1.674a.4.4,0,1,0-.8,0V300.2H1.9l4.523-4.841a.45.45,0,0,0,0-.6L1.9,289.92h9.051v1.243a.4.4,0,1,0,.8,0v-1.67a.445.445,0,0,0-.117-.3.388.388,0,0,0-.285-.126Z" transform="translate(-1.545 1)" fill="#fff" stroke="#fff" strokeWidth="0.4"/>
            </g>
        </svg>
    )
}
export default HistoryTotalIcon