import React, {useEffect, useState} from "react";
import {floatNumberWithSpaces} from "@services/numberFormatServices";
import {abbrNum} from "@services/betService";
import ToolTip from "@components/toolTip/toolTip";
import classNames from "classnames";

function UserInfo({icon, title, value, className}) {
    const [userBalance, setUserBalance] = useState("0");

    useEffect(() => {
        const refactorBalance = value.toString().length > 4 ? abbrNum(value) :  floatNumberWithSpaces(value)
        setUserBalance(refactorBalance)
    }, [value])
    return(
        <div className={classNames("user-info-item", className)}>
            <div className="icon">{icon}</div>
            <div className="title">{title}</div>
            <div className="description">
                <ToolTip title={floatNumberWithSpaces(value)}>
                {userBalance}
                </ToolTip>
            </div>
        </div>
    )
}

export default UserInfo