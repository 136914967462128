import React from "react";

function HistoryFilterIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 26">
            <defs>
                <filter id="VectorHistoryFilterIcon" x="0" y="3"  filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Group_1491" data-name="Group 1491" transform="translate(0)">
                <circle id="Ellipse_361" data-name="Ellipse 361" cx="12" cy="12" r="12" fill="none" opacity="0.2"/>
                <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#VectorHistoryFilterIcon)">
                    <path id="Vector-2" data-name="Vector" d="M7.968,11H4.031a.485.485,0,0,1-.477-.492v-3.7L.065.737a.5.5,0,0,1,0-.493A.47.47,0,0,1,.478,0H11.521a.471.471,0,0,1,.413.244.5.5,0,0,1,0,.493L8.445,6.812v3.7A.485.485,0,0,1,7.968,11ZM4.628,7.5v2.4H7.37l0-2.4ZM1.51,1.106,4.484,6.259l.142.255H7.372l.142-.255,2.975-5.153Z" transform="translate(6 7)" fill="#fff"/>
                </g>
            </g>
        </svg>
    )
}

export default HistoryFilterIcon