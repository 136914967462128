import React from "react";

function ModalButtonCloseIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 21 22">
            <defs>
                <filter id="VectorHistoryFilter" x="0" y="0" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Group_1522" data-name="Group 1522" transform="translate(6 3)">
                <rect id="Rectangle_528" data-name="Rectangle 528" width="10" height="16" fill="none"/>
                <g transform="matrix(1, 0, 0, 1, -6, -1)" filter="url(#VectorHistoryFilter)">
                    <path id="Vector-2" data-name="Vector" d="M5.242,5,8.973.2A.123.123,0,0,0,8.886,0H7.752a.225.225,0,0,0-.175.087L4.5,4.046,1.423.087A.221.221,0,0,0,1.248,0H.114A.123.123,0,0,0,.027.2L3.758,5,.027,9.8A.127.127,0,0,0,0,9.862a.131.131,0,0,0,.01.068.12.12,0,0,0,.042.052A.107.107,0,0,0,.114,10H1.248a.225.225,0,0,0,.175-.087L4.5,5.954,7.577,9.913A.221.221,0,0,0,7.752,10H8.886a.123.123,0,0,0,.087-.2Z" transform="translate(6 4)" fill="#fff"/>
                </g>
            </g>
        </svg>
    )
}

export default ModalButtonCloseIcon