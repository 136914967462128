import React from "react";

function NoDataIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 74">
            <g id="Group_2580" data-name="Group 2580" transform="translate(-221 -103)">
                <rect id="Rectangle_12409" data-name="Rectangle 12409" width="120" height="74"
                      transform="translate(221 103)" fill="none"/>
                <g id="Group_234" data-name="Group 234" transform="translate(-2 -610)">
                    <path id="Path_7" data-name="Path 7"
                          d="M60,102.949c33.137,0,60-5.8,60-12.95s-26.863-12.95-60-12.95S0,82.847,0,90,26.863,102.949,60,102.949Z"
                          transform="translate(223 684.051)" fill="#878794" opacity="0.5"/>
                    <g id="Path_8" data-name="Path 8" transform="translate(214 710.146)" fill="none">
                        <path
                            d="M112.125,26.46,93.1,5.181a4.465,4.465,0,0,0-3.65-2.327h-40.9A4.468,4.468,0,0,0,44.9,5.179L25.875,26.461V43.553h86.25Z"
                            stroke="none"/>
                        <path
                            d="M 109.125 40.55348968505859 L 109.125 27.60503768920898 L 90.69654846191406 6.992382526397705 L 90.56222534179688 6.779022693634033 C 90.16194915771484 6.143142700195312 89.70553588867188 5.853516101837158 89.45086669921875 5.853516101837158 L 48.54940032958984 5.853516101837158 C 48.29434585571289 5.853516101837158 47.83730697631836 6.143075942993164 47.4365348815918 6.778822422027588 L 47.30268096923828 6.991142749786377 L 28.875 27.60674095153809 L 28.875 40.55348968505859 L 109.125 40.55348968505859 M 112.125 43.55348968505859 L 25.875 43.55348968505859 L 25.875 26.46138191223145 L 44.89871978759766 5.178969383239746 C 45.81189346313477 3.730422735214233 47.14500045776367 2.853516101837158 48.54940032958984 2.853516101837158 L 89.45086669921875 2.853516101837158 C 90.85500335693359 2.853516101837158 92.18805694580078 3.730422735214233 93.10108184814453 5.180822849273682 L 112.125 26.45950317382812 L 112.125 43.55348968505859 Z"
                            stroke="none" fill="#878794"/>
                    </g>
                    <path id="Path_9" data-name="Path 9"
                          d="M87.024,45.373c0-2.969,1.864-5.42,4.176-5.422h20.925V73.5c0,3.928-2.475,7.147-5.531,7.147H31.406c-3.056,0-5.531-3.221-5.531-7.147V39.951H46.8c2.312,0,4.176,2.448,4.176,5.417v.041c0,2.969,1.884,5.367,4.194,5.367H82.83c2.31,0,4.194-2.42,4.194-5.389v-.013Z"
                          transform="translate(214 697.099)" fill="#878794" stroke="#878794" strokeWidth="1"/>
                </g>
            </g>
        </svg>
    )
}

export default NoDataIcon