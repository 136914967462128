import React from "react";
// import {FormattedMessage} from "react-intl";
// import {Prediction} from "@constants/Enums";

function BoardMiddle({onClick}) {

    return (
        <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130 130">
            <g id="Group_2797" data-name="Group 2797" transform="translate(-895 -821.004)">
                <circle id="Ellipse_4210" data-name="Ellipse 4210" cx="64" cy="64" r="64" transform="translate(896 822.004)" fill="none" stroke="#79c4f2" strokeWidth="2"/>
                <g id="Ellipse_4207" data-name="Ellipse 4207" transform="translate(900 826.004)" fill="rgba(3,76,140,0.5)" stroke="#79c4f2" strokeWidth="1">
                    <circle cx="60" cy="60" r="60" stroke="none"/>
                    <circle cx="60" cy="60" r="59.5" fill="none"/>
                </g>
                <text id="BET" transform="translate(936 895.004)" fill="#fff" fontSize="25" fontFamily="SFCompactText-Heavy, SF Compact Text" fontWeight="800" letterSpacing="0.04em"><tspan x="0" y="0">BET</tspan></text>
            </g>
        </svg>
    )
}

export default BoardMiddle;