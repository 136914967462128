import React from "react";

function CloseIcon() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 49.996 50">
            <defs>
                <linearGradient id="linear-gradient-close-icon" x1="1" y1="0.5" x2="0" y2="0.5" gradientUnits="objectBoundingBox">
                    <stop offset="0.01" stopColor="#878794"/>
                    <stop offset="0.07" stopColor="#8d8d9a"/>
                    <stop offset="0.15" stopColor="#9d9dab"/>
                    <stop offset="0.24" stopColor="#b8b8c7"/>
                    <stop offset="0.28" stopColor="#c7c7d6"/>
                    <stop offset="0.56" stopColor="#fff"/>
                    <stop offset="0.57" stopColor="#f9f9fa"/>
                    <stop offset="0.62" stopColor="#e0e0e6"/>
                    <stop offset="0.67" stopColor="#ceced7"/>
                    <stop offset="0.73" stopColor="#c3c3cf"/>
                    <stop offset="0.79" stopColor="#c0c0cc"/>
                    <stop offset="0.96" stopColor="#a1a4b3"/>
                </linearGradient>
                <filter id="Vector-close-icon" x="10.998" y="13" width="28" height="29" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Group_217" data-name="Group 217" transform="translate(-29.002 -25)">
                <g id="Group_213" data-name="Group 213" transform="translate(-50 -126)">
                    <circle id="Ellipse_5" data-name="Ellipse 5" cx="24" cy="24" r="24" transform="translate(80 152)" fill="#1d1b2d" opacity="0.7"/>
                    <path id="Subtraction_6" data-name="Subtraction 6" d="M19353,20133a24.928,24.928,0,1,1,9.73-1.965A24.832,24.832,0,0,1,19353,20133Zm0-49a23.912,23.912,0,1,0,9.344,1.885A23.874,23.874,0,0,0,19353,20084Z" transform="translate(-19249 -19932)" opacity="0.5" fill="url(#linear-gradient-close-icon)"/>
                </g>
                <g transform="matrix(1, 0, 0, 1, 29, 25)" filter="url(#Vector-close-icon)">
                    <path id="Vector-2" data-name="Vector" d="M9.319,8.5,15.952.342A.208.208,0,0,0,15.8,0H13.781a.407.407,0,0,0-.311.149L8,6.878,2.53.149A.4.4,0,0,0,2.219,0H.2A.208.208,0,0,0,.048.342L6.681,8.5.048,16.658A.211.211,0,0,0,0,16.765a.213.213,0,0,0,.017.115.206.206,0,0,0,.075.088A.2.2,0,0,0,.2,17H2.219a.407.407,0,0,0,.311-.149L8,10.122l5.47,6.73a.4.4,0,0,0,.311.149H15.8a.208.208,0,0,0,.154-.342Z" transform="translate(17 17)" fill="#fff"/>
                </g>
            </g>
        </svg>

    )
}

export default CloseIcon