import React, {useContext, useEffect, useState} from "react";
import {connect} from "react-redux";
import SingleChip from "@components/betBoard/singleChip";
import {RoundActionType} from "@constants/Enums";
import classNames from "classnames";
import {selectChip, checkedIsActiveChip} from "@actions/betsAction";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import DeleteBets from "@components/betBoard/deleteBets";
import {ResizeContext} from "../../contexts/resizeContext";

function ChipsList({round, selectChip, selectedChip, timeTicker, balance, activeBets, checkedIsActiveChip, nominal}) {
    const [showCipBoard, setShowChipBoard] = useState(false);
    const {mobile} = useContext(ResizeContext);
    const [availableChip, setAvailableChip] = useState(0)


    useEffect(() => {
        setShowChipBoard(!!timeTicker.diff && round?.actionType == RoundActionType.Bet)
    }, [timeTicker.diff, round])

    useEffect(() => {
        let activeBetsSum = 0;
        activeBets.forEach(item => activeBetsSum += item.Stake)
        setAvailableChip(balance - activeBetsSum)
        checkedIsActiveChip(balance - activeBetsSum)
    }, [activeBets, balance])

    return (
        <div className={classNames(`chips-board`, {'show-chip-board': showCipBoard})}>
            {
                mobile ?
                    <div className="chips-board-list">
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={0}>
                            {
                                nominal.map((chip, index) => {

                                        return( <SwiperSlide  key={index}
                                                              className={classNames("singleChip",{
                                                                  active: selectedChip === chip,
                                                                  disabled: chip > availableChip,
                                                                  mobile
                                                              })}
                                                     onClick={() => selectChip(chip)}>
                                            {
                                                <div className="item">
                                                    <SingleChip chipName={chip} chipTitle={chip} attributeClassName="Chip_list_Mobile"/>
                                                </div>

                                            }
                                        </SwiperSlide>)
                                    }
                                )
                            }
                        </Swiper>
                    </div>
                    :
                <ul className="chips-board-list">
                    {
                        nominal.map((chip, index) => {
                            if (index === Math.floor(nominal.length / 2)) {
                                return (
                                    <React.Fragment key={index}>
                                        <DeleteBets className="ChipsBoard"/>
                                        <li  className={classNames("singleChip", {
                                            active: selectedChip === chip,
                                            disabled: chip > availableChip,
                                            mobile
                                        })}
                                            onClick={() => selectChip(chip)}>
                                            <SingleChip chipName={chip} chipTitle={chip} attributeClassName="Chip_list"/>
                                        </li>
                                    </React.Fragment>

                                )
                            } else {
                                return (<li key={index}
                                            className={classNames("singleChip", {
                                                active: selectedChip === chip,
                                                disabled: chip > availableChip,
                                                mobile
                                            })}
                                            onClick={() => selectChip(chip)}>
                                    <SingleChip chipName={chip} chipTitle={chip} attributeClassName="Chip_list"/>
                                </li>)
                            }
                        })
                    }
                </ul>
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        selectedChip: state.selectedChip,
        timeTicker: state.timeTicker,
        round: state.round,
        balance: state.userInfo.Balance,
        activeBets: state.activeBets,
        nominal: state.limit.Nominal
    }
}

const mapDispatchToProps = {
    selectChip,
    checkedIsActiveChip
}

export default connect(mapStateToProps, mapDispatchToProps)(ChipsList)