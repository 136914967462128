import React from "react";

function HistoryMobileIcon() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 30 31">
            <defs>
                <filter id="VectorHistoryMobileIcon" x="0" y="0" width="30" height="31" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Group_1515" data-name="Group 1515" transform="translate(-49 -222)">
                <rect id="Rectangle_707" data-name="Rectangle 707" width="20" height="20" transform="translate(54 227)" fill="none"/>
                <g transform="matrix(1, 0, 0, 1, 49, 224)" filter="url(#VectorHistoryMobileIcon)">
                    <path id="Vector-2" data-name="Vector" d="M9.277,19a9.11,9.11,0,0,1-6.528-2.75A9.555,9.555,0,0,1,0,9.6,9.559,9.559,0,0,1,2.68,2.811,9.107,9.107,0,0,1,9.272,0H9.28a9.144,9.144,0,0,1,7.3,3.65l-1.29,1.034a7.836,7.836,0,0,0-.617-.713,7.583,7.583,0,0,0-2.428-1.677,7.492,7.492,0,0,0-5.942,0A7.583,7.583,0,0,0,3.878,3.97,7.761,7.761,0,0,0,2.241,6.455a7.918,7.918,0,0,0,1.634,8.573A7.582,7.582,0,0,0,6.3,16.7a7.41,7.41,0,0,0,2.972.614h.012a7.408,7.408,0,0,0,2.96-.614,7.568,7.568,0,0,0,2.428-1.676,7.791,7.791,0,0,0,1.639-2.486c.045-.11.089-.222.129-.333a.176.176,0,0,1,.088-.1.166.166,0,0,1,.13-.008l1.224.43a.182.182,0,0,1,.105.23,9.452,9.452,0,0,1-3.342,4.483A9.064,9.064,0,0,1,9.277,19Zm2.776-5.676a.174.174,0,0,1-.1-.035L8.381,10.622a.177.177,0,0,1-.071-.143V4.4a.175.175,0,0,1,.172-.176H9.521a.175.175,0,0,1,.172.176V9.865l3.077,2.278a.169.169,0,0,1,.069.111.181.181,0,0,1-.032.135l-.617.862A.167.167,0,0,1,12.052,13.324Zm5.637-6.591a.169.169,0,0,1-.04,0l-3.384-.847a.171.171,0,0,1-.084-.048.177.177,0,0,1-.045-.087.186.186,0,0,1,.007-.1A.18.18,0,0,1,14.2,5.57l3.4-2.72a.164.164,0,0,1,.105-.039.176.176,0,0,1,.173.178l-.017,3.568A.175.175,0,0,1,17.689,6.732Z" transform="translate(6 4)" fill="#fff"/>
                </g>
            </g>
        </svg>
    )
}

export default HistoryMobileIcon