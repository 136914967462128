import {
    RESET_USER_BET_LIST,
    SET_TOTAL_ALL_BETS
} from "@actions/actionTypes";
import initialStore from "../initialStore";
import {getActiveBetsSum} from "@services/betService";

export default function totalBets(state = initialStore.totalBets, action) {
    switch (action.type) {
        case SET_TOTAL_ALL_BETS:
            return getActiveBetsSum(action.bets)
        case RESET_USER_BET_LIST :
            return initialStore.totalBets
        default:
            return state;
    }
}
