import {SET_SERVER_TIME, SET_TIME_TICKER, SET_SUB_TICKER_WORKER, SET_UN_SUB_TICKER_WORKER} from "@actions/actionTypes";

export default function workerMiddleware(workerService) {
    return ({dispatch}) => next => (action) => {
        if (typeof action === 'function') return next(action);


        if (action.type === SET_SERVER_TIME) {
            workerService.setTime(action.time)
        }

        if (action.type === SET_TIME_TICKER) {
            workerService.setTimeTicker(action.ticker)
        }

        if (action.type === SET_SUB_TICKER_WORKER) {
            workerService.subscribe(action.data)
        }

        if (action.type === SET_UN_SUB_TICKER_WORKER) {
            workerService.unSubscribe(action.data)
        }

        try {
            return next(action);
        } catch(e) {
            console.error(e);
            console.log(action);
        }
        
    };
}
