import React, {useEffect, useRef, useState, useContext} from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import winnerListIcon from "@assets/img/icons/winerListIcon.svg"
import winnerListUserIcon from "@assets/img/icons/winnerListUserIcon.svg";
import {floatNumberWithSpaces} from "@services/numberFormatServices";
import AnimatedList from './AnimatedList';
import { CellMeasurerCache, CellMeasurer } from "react-virtualized";
import classNames from "classnames";
import {ResizeContext} from "../../contexts/resizeContext";

const cache = new CellMeasurerCache({
    fixedWidth: true,
    fixedHeight: false
})

function WinnerList({winnerList}) {
    const [rowHeight, setRowHeight] = useState(30);
    const [animateData, setAnimateData] = useState([]);
    const [enableScroll, setEnableScroll] = useState(false)
    const [animatingRowSize, setAnimatingRowSize] = useState(0);
    const [sumOfWin, setSumOfWin] = useState(0);
    const [hideWinnerList, setHideWinnerList] = useState(false);
    const {height, width, mobile, pc, tab} = useContext(ResizeContext);
    const hideTimer = useRef();
    const listRef = useRef();
    

    function easeInOutQuint(t) {
        return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
    }
    function easeInSine(x) {
        return 1 - Math.cos((x * Math.PI) / 2);
    }
    function easeOutSine(x) {
        return Math.sin((x * Math.PI) / 2);
    }
      
    const rowRenderer = ({ index, isScrolling, key, style, parent }) => {

        return (
            <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={key}
                rowIndex={index}
                parent={parent} >
                    {({measure, registerChild}) => 
                        (index == 0 && (<div style={{...style}} ref={registerChild}>
                            <div style={{height: 400}}></div>
                            <div className="title">
                                <span><FormattedMessage id="winner"/></span>
                                <span className="title-divider">-</span>
                                <span><FormattedMessage id="announcement"/></span>
                                <span><img className="icon" src={winnerListIcon} alt="winnerListIcon"/></span>
                            </div>
                            <div className="winner-table-header">
                                <div className="winner-table-header-content">
                                    <div className="winner-table-header-content-item">
                                        { winnerList.length }
                                        <img className="icon" src={winnerListUserIcon} alt="winnerListUserIcon"/>
                                        <FormattedMessage id={"won"}/>
                                    </div>
                                    <div className="winner-table-header-content-item">{floatNumberWithSpaces(sumOfWin)}</div>
                                </div>
                            </div>
                            <div className="winner-table-body-content" >
                                <div className="winner-table-body-content-item">{winnerList[index]?.PlayerName}</div>
                                <div className="winner-table-body-content-item">{floatNumberWithSpaces(winnerList[index]?.WonAmount)}</div>
                            </div>
                            {
                                index == winnerList.length - 1 &&  <div style={{height: 400}}></div>
                            }
                        </div>)
                        || index == winnerList.length - 1 &&
                        <div style={style} ref={registerChild}>
                            <div className="winner-table-body-content" >
                                <div className="winner-table-body-content-item">{winnerList[index]?.PlayerName}</div>
                                <div className="winner-table-body-content-item">{floatNumberWithSpaces(winnerList[index]?.WonAmount)}</div>
                            </div>
                            <div style={{height: 400}}></div>
                        </div> 
                        ||
                        <div className="winner-table-body-content" style={style} ref={registerChild}>
                            <div className="winner-table-body-content-item">{winnerList[index]?.PlayerName}</div>
                            <div className="winner-table-body-content-item">{floatNumberWithSpaces(winnerList[index]?.WonAmount)}</div>
                        </div>
                        
                        )
                }
            </CellMeasurer>
            
        )
    };

    useEffect(() => {
        clearTimeout(hideTimer);
        setHideWinnerList(false);
        if (winnerList.length) {
            setAnimatingRowSize(winnerList.length)
        }
        setSumOfWin(winnerList.reduce((a, b) => b.WonAmount + a, 0))
        listRef.current && listRef.current.recomputeRowHeights();
        clearTimeout(hideTimer.current)
        return () => {
            cache.clearAll();
        }
    }, [winnerList]);

    const animationComplete = () => {
        if (!enableScroll) {
            hideTimer.current = setTimeout(() => {
                setHideWinnerList(true);
            }, 5000);
        }
    }

    const onScroll = (scroll) => {
        setAnimatingRowSize(Math.max(Math.round(winnerList.length - scroll.scrollTop / rowHeight), 1))
    }

    useEffect(() => {
        console.log(animatingRowSize, Math.max(animatingRowSize * 500, 10000))
    }, [animatingRowSize])

    return ( <>
            <div className={classNames("winners", {hide: hideWinnerList})}  onMouseEnter={() => { setEnableScroll(true) }} onMouseLeave={() => { setEnableScroll(false) }}>
                <AnimatedList
                    ref={listRef}
                    className="winner-table-body"
                    duration={enableScroll? 0 : Math.max(animatingRowSize * 500, 10000)}
                    easing={easeOutSine}
                    width={420}
                    height={mobile? window.innerHeight * 0.43 : (tab ? 274 : 340 ) }
                    onAnimationComplete={animationComplete}
                    rowCount={winnerList.length}
                    rowHeight={cache.rowHeight}
                    rowRenderer={rowRenderer}
                    scrollToRow={enableScroll? undefined : winnerList.length + 24}
                    stopAnimation={enableScroll}
                    deferredMeasurementCache={cache}
                    overscanRowCount={0}
                    onScroll={onScroll}
                    data={winnerList}
                    style={{
                        overflow: enableScroll? "auto" : "hidden"
                    }}
                />
            </div>
    </>
    )
}

const mapStateToProps = (state) => {
    return {
        winnerList: state.winnerList
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(WinnerList);