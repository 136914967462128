import React from "react";

function WarningIcon() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 76 68">
            <defs>
                <filter id="VectorWarningIcon" x="0" y="0" width="76" height="68" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Group_1354" data-name="Group 1354" transform="translate(-922 -284)">
                <rect id="Rectangle_870" data-name="Rectangle 870" width="64" height="56" transform="translate(928 288)" fill="none"/>
                <g transform="matrix(1, 0, 0, 1, 922, 284)" filter="url(#VectorWarningIcon)">
                    <path id="Vector-2" data-name="Vector" d="M61.712,56H2.288A2.285,2.285,0,0,1,.309,52.571L30.022,1.143a2.284,2.284,0,0,1,3.957,0L63.691,52.571A2.285,2.285,0,0,1,61.712,56ZM32,39.429a3.43,3.43,0,1,0,2.38,1.029A3.41,3.41,0,0,0,32,39.429ZM30.286,20.571a.572.572,0,0,0-.571.571V34.286a.572.572,0,0,0,.571.571h3.428a.572.572,0,0,0,.571-.571V21.143a.572.572,0,0,0-.571-.571Z" transform="translate(6 4)" fill="#fc0"/>
                </g>
            </g>
        </svg>
    )
}

export default WarningIcon