import {SET_USER_BALANCE, SET_USER_INFO} from "@actions/actionTypes";
import initialStore from "../initialStore";

export default function userInfoReducer(state = initialStore.userInfo, action) {
    switch (action.type) {
        case SET_USER_INFO:
            return action.user
        case SET_USER_BALANCE:
            return {...state, Balance: action.balance}
        default:
            return state;
    }
}