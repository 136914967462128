import React from "react";

function FullScreenOutIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 48 48">
            <defs>
                <filter id="VectorFullScreenOutIcon" x="8" y="10" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Group_1476" data-name="Group 1476" transform="translate(-30 -26)">
                <circle id="Ellipse_344" data-name="Ellipse 344" cx="24" cy="24" r="24" transform="translate(30 26)" fill="none"/>
                <g transform="matrix(1, 0, 0, 1, 30, 26)" filter="url(#VectorFullScreenOutIcon)">
                    <path id="Vector-2" data-name="Vector" d="M19.563,21a.226.226,0,0,1-.161-.067l-3.815-3.812L14.34,18.369a.224.224,0,0,1-.16.067.23.23,0,0,1-.228-.2l-.539-4.571a.225.225,0,0,1,.056-.178.231.231,0,0,1,.172-.078h.026l4.57.539a.233.233,0,0,1,.116.049.227.227,0,0,1,.072.1.224.224,0,0,1,.007.126.228.228,0,0,1-.06.111L17.12,15.59l3.814,3.815a.224.224,0,0,1,.067.16.227.227,0,0,1-.067.162l-1.21,1.206A.226.226,0,0,1,19.563,21ZM1.437,21a.224.224,0,0,1-.161-.067L.067,19.724a.227.227,0,0,1,0-.322l3.814-3.809L2.628,14.34a.229.229,0,0,1,.134-.388l4.574-.539.028,0a.225.225,0,0,1,.223.253l-.539,4.568A.225.225,0,0,1,7,18.348a.237.237,0,0,1-.1.072.225.225,0,0,1-.126.007.229.229,0,0,1-.11-.06L5.413,17.12,1.6,20.931A.227.227,0,0,1,1.437,21ZM7.359,7.592H7.333L2.762,7.051A.236.236,0,0,1,2.647,7a.227.227,0,0,1-.072-.1.224.224,0,0,1-.007-.126.228.228,0,0,1,.06-.111L3.881,5.41.067,1.6a.227.227,0,0,1,0-.322L1.276.067a.227.227,0,0,1,.322,0L5.413,3.878,6.66,2.632a.224.224,0,0,1,.16-.067.23.23,0,0,1,.228.2l.539,4.571a.225.225,0,0,1-.056.178A.231.231,0,0,1,7.359,7.592Zm6.277,0a.224.224,0,0,1-.167-.075.228.228,0,0,1-.056-.181l.539-4.568a.229.229,0,0,1,.152-.188.247.247,0,0,1,.076-.012.217.217,0,0,1,.05.006.227.227,0,0,1,.11.061l1.247,1.247L19.4.069a.23.23,0,0,1,.322,0l1.21,1.207a.224.224,0,0,1,.067.16.227.227,0,0,1-.067.162L17.12,5.41l1.252,1.25a.229.229,0,0,1-.135.388l-4.573.539Z" transform="translate(14 14)" fill="#fff"/>
                </g>
            </g>
        </svg>
    )
}

export default FullScreenOutIcon