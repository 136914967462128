import React, { forwardRef, useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { List } from "react-virtualized";

function defaultEasing(delta) {
  return delta;
}

function AnimatedList(props) {
  const [scrollTop, setScrollTop] = useState();
  const frameIdRef = useRef();
  const listRef = useRef();
  const _scrollTopInitial = useRef();
  const _scrollTopFinal = useRef();
  const _animationStartTime = useRef();
  const duration = useRef(10000);

  useEffect(() => {
    if (props.scrollToRow) {
      _initAnimation();
    }
  }, [])

  useEffect(() => {
    cancelAnimationFrame(frameIdRef.current);
      _end();
      _scrollTopInitial.current = 0;
      _initAnimation();
  }, [props.data])

  useEffect(() => {
    _initAnimation();
  }, [props.scrollToRow])

  useEffect(() => {
    duration.current = props.duration;
  }, [props.duration])

  useEffect(() => {
    if (props.stopAnimation) {
      cancelAnimationFrame(frameIdRef.current);
      _end();
    }
  }, [props.stopAnimation])

  function _animate() {
    if (!duration.current) {
      console.error("set duration please")
      return;
    }
    frameIdRef.current = requestAnimationFrame(() => {
      const now = performance.now();
      const ellapsed = now - _animationStartTime.current;
      const scrollDelta = _scrollTopFinal.current - _scrollTopInitial.current;
      const easedTime = props.easing(Math.min(1, ellapsed / duration.current));
      const scrollTop = _scrollTopInitial.current + scrollDelta * easedTime;

      setScrollTop(scrollTop);

      if (ellapsed < duration.current && !props.stopAnimation) {
        _animate();
      } else {
        _end();
      }
    });
  }
  
  function _end () {
    _animationStartTime.current = undefined;
    _scrollTopInitial.current = _scrollTopFinal.current;
    props.onAnimationComplete();
  }

  function _initAnimation() {
    if (_animationStartTime.current) {
        return;
      throw Error("Animation in progress"); 
    }

    _animationStartTime.current = performance.now();
    _scrollTopFinal.current = listRef.current.getOffsetForRow({
      index: props.scrollToRow
    });

    _scrollTopFinal.current = Math.max(600, _scrollTopFinal.current)
    _animate();
  }

  const onScroll = ({ scrollTop }) => {
    if (!_animationStartTime.current) {
      _scrollTopInitial.current = scrollTop;
      setScrollTop(scrollTop);
      props.onScroll({ scrollTop })
    }
  }

  return (
    <List
      {...props}
      onScroll={onScroll}
      ref={listRef}
      scrollToRow={undefined}
      scrollTop={scrollTop}
    />
  )
}

AnimatedList.defaultProps = {
  duration: 1000,
  easing: defaultEasing,
  onAnimationComplete: () => {},
  onScroll: () => {},
  stopAnimation: false
};


export default React.forwardRef((props, ref) => <AnimatedList 
  innerRef={ref} {...props}
/>);