import React from "react";

function SliderArrowIcon() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 24.188 29.764">
            <path id="Vector-2" transform="translate(6 6.08)" data-name="Vector" d="M11.156,2V.188a.187.187,0,0,0-.3-.148L.288,8.293a.747.747,0,0,0,0,1.179l10.566,8.252a.187.187,0,0,0,.3-.148V15.764a.379.379,0,0,0-.143-.3L2.576,8.883l8.437-6.588A.379.379,0,0,0,11.156,2Z"  fill="#fff"/>
        </svg>
    )
}

export default SliderArrowIcon