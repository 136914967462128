import {
    SET_ROUND_START
} from "@actions/actionTypes";

export default function roundReducer(state = null, action) {
    switch (action.type) {
        case SET_ROUND_START:
            return action.round;
        default:
            return state;
    }
}