import React from "react";

function FilterIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Group_1487" data-name="Group 1487" viewBox="0 0 32 32">
            <defs>
                <filter id="VectorFilterIcon" x="2" y="4"  filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <circle id="Ellipse_357" data-name="Ellipse 357" cx="16" cy="16" r="16" fill="none"/>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#VectorFilterIcon)">
                <path id="Vector-2" data-name="Vector" d="M14.964,16a.159.159,0,0,1-.062-.012.168.168,0,0,1-.054-.035L9.654,10.76a6,6,0,0,1-7.9-.518A6,6,0,0,1,6,0a6,6,0,0,1,6,6,5.924,5.924,0,0,1-1.242,3.657l5.194,5.194a.164.164,0,0,1,.035.053.168.168,0,0,1,0,.126.164.164,0,0,1-.035.053l-.872.87a.156.156,0,0,1-.053.035A.161.161,0,0,1,14.964,16ZM6,1.52A4.479,4.479,0,1,0,9.169,2.832,4.461,4.461,0,0,0,6,1.52Z" transform="translate(8 8)" fill="#fff"/>
            </g>
        </svg>
    )
}

export default FilterIcon