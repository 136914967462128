import React from "react";

function BurgerMenu() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 38 38">
            <defs>
                <filter id="VectorBurgerMEnu" x="4" y="7" width="30" height="28" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Group_1679" data-name="Group 1679" transform="translate(-144 -21)">
                <g id="Ellipse_401" data-name="Ellipse 401" transform="translate(145 22)" fill="rgba(29,27,45,0.7)" stroke="rgba(135,135,148,0.5)" strokeWidth="1">
                    <circle cx="18" cy="18" r="18" stroke="none"/>
                    <circle cx="18" cy="18" r="18.5" fill="none"/>
                </g>
                <g id="Group_619" data-name="Group 619" transform="translate(-183 12)">
                    <rect id="Rectangle_695" data-name="Rectangle 695" width="20" height="20" transform="translate(336 18)" fill="none"/>
                    <g transform="matrix(1, 0, 0, 1, 327, 9)" filter="url(#VectorBurgerMEnu)">
                        <path id="Vector-2" data-name="Vector" d="M17.82,16H.18A.181.181,0,0,1,0,15.818V14.364a.181.181,0,0,1,.18-.182H17.82a.181.181,0,0,1,.18.182v1.455A.181.181,0,0,1,17.82,16Zm0-7.091H.18A.181.181,0,0,1,0,8.727V7.273a.181.181,0,0,1,.18-.182H17.82a.181.181,0,0,1,.18.182V8.727A.181.181,0,0,1,17.82,8.909Zm0-7.091H.18A.181.181,0,0,1,0,1.636V.182A.181.181,0,0,1,.18,0H17.82A.181.181,0,0,1,18,.182V1.636A.181.181,0,0,1,17.82,1.818Z" transform="translate(10 11)" fill="#fff"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default BurgerMenu;