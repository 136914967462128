import React from "react";

function CalendarMonthsArrow() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="icon_outlined_directional_left" data-name="icon/outlined/directional/left" viewBox="0 0 12 12">
            <defs>
                <clipPath id="clip-pathCalendarMonthsArrow">
                    <rect id="icon_outlined_directional_left_clip_mask_" data-name="icon/outlined/directional/left (clip mask)" width="12" height="12" fill="#eaeaec"/>
                </clipPath>
                <filter id="VectorCalendarMonthsArrow" x="1.406" y="0.058" filterUnits="userSpaceOnUse">
                    <feOffset input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="0.5" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <rect id="icon_outlined_directional_left_clip_mask_2" data-name="icon/outlined/directional/left (clip mask)" width="12" height="12" fill="none"/>
            <g id="icon_outlined_directional_left-2" data-name="icon/outlined/directional/left" clipPath="url(#clip-pathCalendarMonthsArrow)">
                <g id="icon_outlined_directional_left_clip_group_" data-name="icon/outlined/directional/left (clip group)">
                    <rect id="icon_outlined_directional_left_style_" data-name="icon/outlined/directional/left (style)" width="12" height="12" fill="none"/>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#VectorCalendarMonthsArrow)">
                        <path id="Vector-2" data-name="Vector" d="M5.578,1V.094A.094.094,0,0,0,5.427.02L.144,4.146a.373.373,0,0,0,0,.589L5.427,8.862a.093.093,0,0,0,.151-.074V7.882a.189.189,0,0,0-.071-.148L1.288,4.442,5.507,1.147A.189.189,0,0,0,5.578,1Z" transform="translate(2.91 1.56)" fill="#eaeaec"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}
export default CalendarMonthsArrow