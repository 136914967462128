import React from "react";
// import {FormattedMessage} from "react-intl";
import {Prediction} from "@constants/Enums";
import BoardMiddle from "./BoardMiddle";
import BoardLeft from "./BoardLeft";
import BoardRight from "./BoardRight";

function Board({setBet}) {

    return (
        <div className="board">
            <div className="board-left" >
                <BoardLeft onClick={() => setBet(Prediction._21plus3)}/> 
            </div>
            <div className="board-middle" >
                <BoardMiddle onClick={() => setBet(Prediction.Main)}/>  
            </div>
            <div className="board-right" >
                <BoardRight onClick={() => setBet(Prediction.PerfectPairs)}/>     
            </div>
        </div>
       
    )
}

export default Board;