import React, {useEffect, useState} from 'react'
import {IntlProvider} from "react-intl";
import {connect} from "react-redux";
import translations from './translations';
import "@assets/sass/main.scss";
import {DefaultLanguage, LanguageCode} from './constants/Enums';
import MainLayout from "./layouts/mainLayout";
import {
  getAvailableLanguages,
  getSelectedLanguage
} from "@actions/settingActions";
import {getSessionIdByUrl} from "./utils/HttpUtil";
import {setUserSessionId} from "@actions/userActions";
import Loading from "@components/loading";
import RotateDevice from "@components/RotateDevice";
import TapFullScreen from "@components/TapFullScreen";
import HideToolBar from "@components/HideToolBar";
import useFetch from "./hook/useFetchHook";
import svgFontEot from "@assets/fonts/svgFonts/fonts/iconfont.eot";
import svgFontSvg from "@assets/fonts/svgFonts/fonts/iconfont.svg";
import svgFontTtf from "@assets/fonts/svgFonts/fonts/iconfont.ttf";
import svgFontWoff from "@assets/fonts/svgFonts/fonts/iconfont.woff";
import svgFontWoff2 from "@assets/fonts/svgFonts/fonts/iconfont.woff2";
import textFontSFCompactHeavyEot from "@assets/fonts/sf_compact_text/SF-Compact_Text_Heavy.eot";
import textFontSFCompactHeavyTtf from "@assets/fonts/sf_compact_text/SF-Compact_Text_Heavy.ttf";
import textFontSFCompactHeavyWoff from "@assets/fonts/sf_compact_text/SF-Compact_Text_Heavy.woff";
import textFontSFCompactHeavyWoff2 from "@assets/fonts/sf_compact_text/SF-Compact_Text_Heavy.woff2";
import textFontSFCompactBoldEot from "@assets/fonts/sf_pro_text/SFProText-Bold.eot";
import textFontSFCompactBoldTtf from "@assets/fonts/sf_pro_text/SFProText-Bold.ttf";
import textFontSFCompactBoldWoff from "@assets/fonts/sf_pro_text/SFProText-Bold.woff";
import textFontSFCompactBoldWoff2 from "@assets/fonts/sf_pro_text/SFProText-Bold.woff2";
import textFontSFProTextHeavyEot from "@assets/fonts/sf_pro_text/SFProText-Heavy.eot";
import textFontSFProTextHeavyTtf from "@assets/fonts/sf_pro_text/SFProText-Heavy.ttf";
import textFontSFProTextHeavyWoff from "@assets/fonts/sf_pro_text/SFProText-Heavy.woff";
import textFontSFProTextHeavyWoff2 from "@assets/fonts/sf_pro_text/SFProText-Heavy.woff2";
import textFontSFProTextLightEot from "@assets/fonts/sf_pro_text/SFProText-Light.eot";
import textFontSFProTextLightTtf from "@assets/fonts/sf_pro_text/SFProText-Light.ttf";
import textFontSFProTextLightWoff from "@assets/fonts/sf_pro_text/SFProText-Light.woff";
import textFontSFProTextLightWoff2 from "@assets/fonts/sf_pro_text/SFProText-Light.woff2";
import textFontSFProTextMediumEot from "@assets/fonts/sf_pro_text/SFProText-Medium.eot";
import textFontSFProTextMediumTtf from "@assets/fonts/sf_pro_text/SFProText-Medium.ttf";
import textFontSFProTextMediumWoff from "@assets/fonts/sf_pro_text/SFProText-Medium.woff";
import textFontSFProTextMediumWoff2 from "@assets/fonts/sf_pro_text/SFProText-Medium.woff2";
import winerListIcon from "@assets/img/icons/winerListIcon.svg";
import winnerListUserIcon from "@assets/img/icons/winnerListUserIcon.svg";
import background from "@assets/img/background.svg";
import noInternetBg from "@assets/img/noInternetBg.svg";
import useResizeHook from "./hook/useResizeHook";
import {ResizeContext} from "./contexts/resizeContext";

const resources =  [
    // svgFontEot,
    // svgFontSvg,
    // svgFontTtf,
    {url: svgFontWoff2, fontFamily: "iconfont"},
    {url: textFontSFCompactHeavyWoff2, fontFamily: 'SF Compact Text Heavy'},
    {url: textFontSFCompactBoldWoff2, fontFamily: 'SF Pro Text Bold'},
    {url: textFontSFProTextHeavyWoff2, fontFamily: 'SF Pro Text Heavy'},
    {url: textFontSFProTextLightWoff2, fontFamily: 'SF Pro Text Light'},
    {url: textFontSFProTextMediumWoff2, fontFamily: 'SF Pro Text Medium'},

    // {url: svgFontWoff, fontFamily: "iconfont"},
    // // textFontSFCompactHeavyEot,
    // // textFontSFCompactHeavyTtf,
    // {url: textFontSFCompactHeavyWoff, fontFamily: 'SF Compact Text Heavy'},
    // // textFontSFCompactBoldEot,
    // // textFontSFCompactBoldTtf,
    // {url: textFontSFCompactBoldWoff, fontFamily: 'SF Pro Text Bold'},
    // // textFontSFProTextHeavyEot,
    // // textFontSFProTextHeavyTtf,
    // {url: textFontSFProTextHeavyWoff, fontFamily: 'SF Pro Text Heavy'},
    // // textFontSFProTextLightEot,
    // // textFontSFProTextLightTtf,
    // {url: textFontSFProTextLightWoff, fontFamily: 'SF Pro Text Light'},
    // // textFontSFProTextMediumEot,
    // // textFontSFProTextMediumTtf,
    // {url: textFontSFProTextMediumWoff, fontFamily: 'SF Pro Text Medium'},
    

    {url: winerListIcon},
    {url: winnerListUserIcon},
    {url: background},
    {url: noInternetBg}
]


function App({
  lang,
  getAvailableLanguages,
  locale,
  messages,
  selectedLanguages,
  getSelectedLanguage}) {
    const { loading, progress, getJson  } =  useFetch(resources);
    const {height, width, mobile, pc, tab} = useResizeHook();
    const [isMobile, setIsMobile] = useState(false);

    const setDevicePixelRatio = () => {
        let root = document.documentElement;
        root.style.setProperty('--device-pixel-ratio', 1);
    }

    const setDeviceClassName = () => {
        let scaleX = 0;
        let scaleY = 0;
        let localWidth = 0;
        let localHeight = 0;
        setDevicePixelRatio();
        const el = document.querySelector(".mainContent");
        const loading = document.querySelector(".loading .border-box");
        document.body.classList.remove("tab")
        document.body.classList.remove("smallPc")
        document.body.classList.remove("pc")
        if (width <= 540) {
            el.style.transform = "unset";
            loading && (loading.style.transform = "unset");
            setIsMobile(true)
            return
        } else if (width < 775 ) {
            scaleX = width / 768
            scaleY = height / 1024
            localWidth = 768 ;
            localHeight = 1024 ;
            document.body.classList.add("tab")
            setIsMobile(false)
        } else if(width < 1281 ) {
            scaleX = width / 1280
            scaleY = height / 800
            localWidth = 1280 ;
            localHeight = 800 ;
            document.body.classList.add("smallPc")
            setIsMobile(false)
        } else {
            scaleX = width / 1920
            scaleY = height / 1080
            localWidth = 1920 ;
            localHeight = 1080 ;
            document.body.classList.add("pc")
            setIsMobile(false)
        }
        let scale = 1;
        let translateX = 0;
        let translateY = 0;
        let isCalcByWidth = false;

        if (scaleX < scaleY ) {
            scale = scaleX;
            isCalcByWidth = true;
        } else {
            scale = scaleY;
            isCalcByWidth = false;
        }
        translateX = !isCalcByWidth? (width - scale * localWidth) / 2 / scale : 0;
        translateY =  isCalcByWidth?(height - scale * localHeight) / 2 / scale : 0;
        el.style.transform = `scale(${scale}) translate(${translateX}px, ${translateY}px)`;
        loading && (loading.style.transform = `scale(${scale}) translate(${translateX}px, ${translateY}px)`);
        let root = document.documentElement;
        root.style.setProperty('--scale-value', scale);
    }
    useEffect(() => {
        setDeviceClassName()
    }, [width, height])

    useEffect(() => {
       getJson()
    },[])

    useEffect(() => {
        if (!loading) {
            document.body.classList.add('bg');
        }
    }, [loading])

    useEffect(() => {
        let userSessionId = getSessionIdByUrl();
        if (userSessionId) {
            setUserSessionId(userSessionId);
        }
    }, []);

  // useEffect(() => {
  //     getAvailableLanguages();
  // }, []);

  // const [translation, setTranslation] = useState(messages);

  // useEffect(() => {
  //   if (selectedLanguages.length > 0) {
  //     setTranslation(selectedLanguages);
  //   }
  // }, [selectedLanguages]);
  
  // useEffect(() => {
  //   if (lang) {
  //     getSelectedLanguage(lang);
  //   }
  //   // setTimeout(() => {
  //   //   console.log("fetch")
  //   //   getSelectedLanguage({id: 135});
  //   // }, 5000);
  // }, [lang]);

  // useEffect(() => {
  //   document.querySelector('#swipeUp .title').innerHTML = translation['scroll_to_enter_full_screen'];
  //   document.querySelector('#clickForFoolScreen .title').innerHTML = translation['tap_to_enter_full_screen'];
  // }, [translation]);

  return (
    <IntlProvider
            // locale={LanguageCode[lang]}
            // messages={translations[LanguageCode[lang].toLowerCase()] ? translations[LanguageCode[lang].toLowerCase()] : {}}
            locale={DefaultLanguage} messages={translations[DefaultLanguage]}
            defaultLocale="EN">
        {!loading ? <HideToolBar /> : ""}
        {loading ? <Loading load={progress} /> : ""}
        <RotateDevice />
        <TapFullScreen />
        <ResizeContext.Provider value={{height, width, mobile, pc, tab}}>
            <MainLayout />
        </ResizeContext.Provider>
    </IntlProvider>
  );
}

function mapStateToProps(state) {
    return {
        lang: state.settings.lang,
        availableLanguages: state.availableLanguages,
        selectedLanguages: state.selectedLanguages
    };
  }
  
const mapDispatchToProps = {
  getAvailableLanguages,
  getSelectedLanguage,
}

export default connect(mapStateToProps, mapDispatchToProps)(App);