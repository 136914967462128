import {
    SET_PLAYER_HAND,
    SUB_CARD,
    SET_DEALER_HAND
} from "@actions/actionTypes";
import initialStore from "../initialStore";
import { OwnerTypes } from "@constants/Enums";

export function subscribeCards() {
    return dispatch => {
        dispatch({
            eventOn: SUB_CARD,
            handle: (data) => {
                if (data[0]?.Owner == OwnerTypes.player) {
                    let playerHands = data.map(playerHand => ({
                        cards: playerHand.Cards,
                        handId: playerHand.HandId,
                        points: playerHand.Points,
                        isBlackJack: playerHand.IsBlackJack
                    }))
                    dispatch({type: SET_PLAYER_HAND, playerHands});
                } else if (data[0]?.Owner == OwnerTypes.dealer) {
                    dispatch({type: SET_DEALER_HAND, dealerHand: {
                        cards: data[0].Cards,
                        points: data[0].Points,
                        handId: data[0].HandId,
                        isBlackJack: data[0].IsBlackJack
                    }})
                } else {
                    console.error("unexpected owner", data)
                }
            }
        })
    }
}

export function resetPlayingCards() {
    return dispatch => {
        dispatch({type: SET_DEALER_HAND, dealerHand: initialStore.emptyObj})
        dispatch({type: SET_PLAYER_HAND, playerHands: initialStore.emptyArr})
    }
}