import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import TemplateButton from "@components/templateButton";
import {FormattedMessage} from "react-intl";
import {DeleteBetsIcon} from "../../resource";
import {getActiveBetsSum} from "@services/betService";
import {resetActiveBets} from "@actions/betsAction";
import classNames from "classnames";

function DeleteBets({activeBets, resetActiveBets, round, className, isEnabledMainBetArea}) {
    const [disabledDeleteButton, setDisabledDeleteButton] = useState(true)

    useEffect(() => {
        let sum = getActiveBetsSum(activeBets)
        setDisabledDeleteButton(!(sum > 0) || !isEnabledMainBetArea)

    }, [activeBets, isEnabledMainBetArea])

    const deleteBets = () => {
        resetActiveBets(round?.roundId)
    }

    return (
        <ul className={classNames(`template-button-list delete-bets`, className)}>
            <TemplateButton title={<FormattedMessage id={"delete_bet"}/>}
                            position="top"
                            icon={<DeleteBetsIcon parentName={className}/>}
                            changeState={deleteBets}
                            disabled={disabledDeleteButton}
            />
    </ul>)
}

const mapStateToProps = (state) => {
    return {
        activeBets: state.activeBets,
        round: state.round,
        isEnabledMainBetArea: state.isEnabledMainBetArea,
    }
}

const mapDispatchToProps = {
    resetActiveBets
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteBets)