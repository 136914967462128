import React, {useEffect} from "react";
import {connect} from "react-redux";

function VideStream({videoStreamConfig}) {

    useEffect(() => {
        if (videoStreamConfig && videoStreamConfig.Live) {
            const serverConfig =  JSON.parse(videoStreamConfig.Live);
            const config = {
                "source": {
                    "defaults": {
                        "service": "bintu"
                    },
                    "startIndex": 0,
                    "entries": [
                        {
                            "index": 0,
                            "h5live": {
                                "server": {
                                    "websocket": process.env.REACT_APP_STREAM_WEB_SOCKET,
                                    "hls": process.env.REACT_APP_STREAM_HLS,
                                },
                                "rtmp": {
                                    "url": process.env.REACT_APP_STREAM_COMMON_URL,
                                    "streamname": videoStreamConfig.StreamName
                                },
                                ...serverConfig.h5live
                            }
                        }
                    ]
                },
                "playback": {
                    "autoplay": true,
                    "automute": true,
                    "muted": true,
                },
                "style": {
                    "controls": false,
                    "view": false,
                    "width": "100%",
                    "height": "100%",
                    "displayMutedAutoplay": true
                }
            };
            var nanoPlayer = new window.NanoPlayer('video-stream');

            nanoPlayer.setup(config).then(function (config) {}, function (error) {
                console.error(error.message);
            });
        }
    }, [videoStreamConfig])

    return(<div className="video-stream" id="video-stream" >
    </div>)
}

const mapStateToProps = (state) => {
    return {
        videoStreamConfig: state.videoStreamConfig,
    }
}

export default connect(mapStateToProps, null)(VideStream)