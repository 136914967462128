import classNames from "classnames";
import React from "react";


export default function UserCenterInfo({icon, description, className, onClick = () => {}}) {

    return  (
        <div className={classNames("full-size user-center-info", className)} onClick={ onClick }>
            <div className="message">
                <img className="message-icon" src={icon} />
                <p className="message-description">
                    { description }
                </p>
            </div>
        </div>
    )
}