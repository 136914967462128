import TimeWorker from "../workers/TimeTicker.worker.js";
import WorkerBuilder from "../workers/WorkerBuilder";

function WorkerService() {
    this.timeTickerWorker = new WorkerBuilder(TimeWorker);
    this.dispatchAction = undefined
    const that = this;
    let subscribes = [];
    this.timeTickerWorker.onmessage = function (messageEvent) {
        const {type} = JSON.parse(messageEvent.data);
        if (type === "GAME_TIMER_DIFF") {
            subscribes.forEach(subscribe => {
                subscribe(messageEvent);
            })
        } else {
            if (that.dispatchAction === undefined) {
                console.error("dispatch action is not function");
                return;
            }
            that.dispatchAction(JSON.parse(messageEvent.data));
        }
    }

    this.setTime = function (time) {
        this.timeTickerWorker.postMessage(JSON.stringify({
            type: "SET_SERVER_TIME",
            data: time
        }))
    }

    this.setTimeTicker = function (timeTicker) {
        this.timeTickerWorker.postMessage(JSON.stringify({
            type: "SET_TIME_TICKER",
            data: timeTicker
        }))
    }

    this.subscribe = function (f) {
        subscribes.push(f);
    }

    this.unSubscribe = function (f) {
        subscribes = subscribes.filter(item => item !== f);
    }


}

export default WorkerService