import React from "react";
import classNames from "classnames";

function InsurancYes({ onClick }) {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48">
            <defs>
                <filter id="VectorYes" x="8.181" y="12.203" width="31.637" height="27.59" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="icon_outlined_editor_delete" transform="translate(14 14)">
                <circle id="Ellipse_349" cx="24" cy="24" r="24" transform="translate(-14 -14)" fill="none" opacity="0.7"/>
                <g transform="matrix(1, 0, 0, 1, -14, -14)" filter="url(#VectorYes)">
                    <path id="Vector-2" d="M18.938,0H17.3a.746.746,0,0,0-.588.286L7.048,12.527,2.414,6.656a.75.75,0,0,0-.588-.286H.188a.187.187,0,0,0-.148.3l6.42,8.133a.752.752,0,0,0,1.179,0L19.086.3A.186.186,0,0,0,18.938,0Z" transform="translate(14.44 16.45)" fill="#fc0" stroke="#fc0" strokeWidth="0.5"/>
                </g>
            </g>
        </svg>
    )
}

export default InsurancYes