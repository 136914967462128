import {all, call} from 'redux-saga/effects';
import errorSaga from "./errorSaga";
import settingSaga from "./settingSaga";
import Rules from "./rulesSaga";
import game from "./gameSaga"

export default function* rootSaga() {
    yield all([
        call(errorSaga),
        call(settingSaga),
        call(Rules),
        call(game),
    ])
}