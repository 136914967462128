import {
    SUB_GAME_FINISH,
    RESET_USER_BET_LIST,
    SET_GAME_CONDITION,
    SET_ROUND_START,
    SUB_CANCEL_GAME,
    SET_TIME_TICKER,
    SET_ALERT_MSG,
    REQUEST_REFRESH_SESSION,
    REQUEST_GAME_START,
    CONNECT_TO_SOCKET,
    SUB_GAME_INIT,
    SET_USER_INFO,
    SET_VIDEO_STREAM_CONFIG,
    SET_SERVER_TIME,
    SUB_ROUND_START,
    SET_DEALER_HAND,
    SET_PLAYER_HAND,
    SUB_ROUND_ACTION,
    RESET_ACTIVE_BET,
    SET_BETS,
    SET_TOTAL_ALL_BETS,
    SET_USER_WON,
    SET_ENABLE_MAIN_BETS_AREA,
    SET_LIMIT,
    SET_WINNER_LIST
} from "@actions/actionTypes";
import initialStore from "../initialStore";
import {getLocalStorage, removeLocalStorage, clearOldBets} from "@services/localStorageService";
import { RoundActionType } from "@constants/Enums";

export function subscribeRoundStartAction() {
    return dispatch => {
        dispatch({eventOn: SUB_ROUND_START,
            handle: (data) => {
                // dispatch({type: SET_SERVER_TIME, time: data.Timer.time});
                dispatch({type: SET_TIME_TICKER, ticker: {Start: data.ActionStartTime || 0, End: data.ActionEndTime || 0}});
                dispatch({type: SET_ROUND_START, round: {
                        actionEndTime: data.ActionEndTime,
                        actionStartTime: data.ActionStartTime,
                        actionType: data.ActionType,
                        roundId: data.RoundId,
                        availableActions: data.AvailableActions
                    }
                });
                dispatch({type: SET_GAME_CONDITION, condition : true});
            }
        });
    }
}



export function subscribeRoundUpdateAction() {
    return (dispatch, getStore) => {
        dispatch({eventOn: SUB_ROUND_ACTION,
            handle: (data) => {
                // dispatch({type: SET_SERVER_TIME, time: data.Timer.time});
                const { round } = getStore();
                dispatch({type: SET_TIME_TICKER, ticker: {Start: data.ActionStartTime || 0, End: data.ActionEndTime || 0}});
                dispatch({type: SET_ROUND_START, round: {
                        actionEndTime: data.ActionEndTime,
                        actionStartTime: data.ActionStartTime,
                        actionType: data.Type,
                        roundId: round.roundId,
                        availableActions: data.AvailableActions
                    }
                });
            }
        });
    }
}

export function subscribeGameFinish() {
    return (dispatch, getStore) => {
        dispatch({
            eventOn: SUB_GAME_FINISH,
            handle: (data) => {
                const { userInfo } = getStore();
                dispatch({type: SET_DEALER_HAND, dealerHand: initialStore.emptyObj})
                dispatch({type: SET_PLAYER_HAND, playerHands: initialStore.emptyArr})
                dispatch({type: RESET_USER_BET_LIST});
                dispatch({type: SET_GAME_CONDITION, condition : false});
                dispatch({type: SET_ROUND_START, round: null});
                if (data.RoundId) {
                    removeLocalStorage(userInfo.Id + "_bets_" + data.roundId);
                }
                dispatch({type: RESET_ACTIVE_BET });
                dispatch({type: SET_USER_WON, wonAmount: {value: data.WonAmount}})
                clearOldBets();
                console.log("data", data)
                dispatch({type: SET_WINNER_LIST, winners: data.Results});
            }
        })
    }
}

export function subscribeCanceledGame() {
    return (dispatch, getStore) => {
        dispatch({
            eventOn: SUB_CANCEL_GAME,
            handle: (data) => {
                const { userInfo } = getStore();
                dispatch({type: SET_DEALER_HAND, dealerHand: initialStore.emptyObj});
                dispatch({type: SET_PLAYER_HAND, playerHands: initialStore.emptyArr});
                dispatch({type: SET_ROUND_START, round: null});
                dispatch({type: SET_GAME_CONDITION, condition : false});
                dispatch({type: SET_TIME_TICKER, ticker: {
                    Start: initialStore.timeTicker.start,
                    End: initialStore.timeTicker.end
                }});
                dispatch({type: SET_ALERT_MSG, data: {type: 'info', messages: "game_is_canceled_your_bets_will_refund"}});
                if (data.RoundId) {
                    removeLocalStorage(userInfo.Id + "_bets_" + data.roundId);
                }
                dispatch({type: RESET_ACTIVE_BET});
                dispatch({type: RESET_USER_BET_LIST});
                dispatch({type: SET_ENABLE_MAIN_BETS_AREA, isEnable: false})
                clearOldBets();
            }
        })
    }
}

export function refreshSession(session) {
    return dispatch => {
        dispatch({
            type: REQUEST_REFRESH_SESSION,
            session
        })
    }
}

export function gameStart() {
    return dispatch => {
        dispatch({
            type: REQUEST_GAME_START,
        })
    }
}

export function connectToSocket(session) {
    return dispatch => {
        dispatch({
            type: CONNECT_TO_SOCKET,
            session
        })
    }
}

export function subGameInitData() {
    return dispatch => {
        dispatch({
            eventOn: SUB_GAME_INIT,
            handle: (data) => {
                dispatch({type: SET_USER_INFO, user: data.Player});
                dispatch({type: SET_VIDEO_STREAM_CONFIG, config: data.Stream});
                if (data.Round) {
                    if (data.Round.ActionType != RoundActionType.Bet) {
                        dispatch({type: RESET_ACTIVE_BET });
                        clearOldBets();
                    }
                    dispatch({type: SET_ROUND_START, round: {
                            actionEndTime: data.Round.ActionEndTime,
                            actionStartTime: data.Round.ActionStartTime,
                            actionType: data.Round.ActionType,
                            roundId: data.Round.RoundId,
                            availableActions: data.Round.AvailableActions
                        }
                    });
                    dispatch({type: SET_DEALER_HAND, dealerHand: {
                            cards: data.Round.DealerHand.Cards,
                            points: data.Round.DealerHand.Points,
                            handId: data.Round.DealerHand.HandId,
                            isBlackJack: data.Round.DealerHand.IsBlackJack
                        }
                    })
                    let playerHands = data.Round.PlayerHands.map(playerHand => ({
                        cards: playerHand.Cards,
                        handId: playerHand.HandId,
                        points: playerHand.Points,
                        isBlackJack: playerHand.IsBlackJack
                    }))
                    dispatch({type: SET_PLAYER_HAND, playerHands})
                    dispatch({type: SET_TIME_TICKER, ticker: {Start: data.Round.ActionStartTime || 0, End: data.Round.ActionEndTime || 0}});
                    dispatch({type: SET_BETS, bets: data.Round.Bets, userId: data.Player.Id, roundId: data.Round.RoundId});
                    dispatch({type: SET_TOTAL_ALL_BETS, bets: data.Round.Bets});
                } else {
                    dispatch({type: SET_DEALER_HAND, dealerHand: initialStore.emptyObj})
                    dispatch({type: SET_PLAYER_HAND, playerHands: initialStore.emptyArr})
                    dispatch({type: RESET_USER_BET_LIST});
                    dispatch({type: SET_GAME_CONDITION, condition : false});
                    dispatch({type: SET_ROUND_START, round: null});
                    dispatch({type: RESET_ACTIVE_BET });
                    clearOldBets();
                }
                
                if(data.Timer?.time) {
                    dispatch({type: SET_SERVER_TIME, time: data.Timer.time});
                }
                dispatch({type: SET_GAME_CONDITION, condition: !!data.Round?.ActionStartTime})
                dispatch({type: SET_LIMIT, limit: data.Limit})

            }
        })
    }
}

export function setUserWon(wonAmount) {
    return dispatch => {
        dispatch({type: SET_USER_WON, wonAmount})
    }
}