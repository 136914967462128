import React from "react";

function CalendarYearsArrow() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="icon_outlined_directional_double-left" data-name="icon/outlined/directional/double-left" viewBox="0 0 12 12">
            <defs>
                <clipPath id="clip-pathCalendarYearsArrow">
                    <rect id="icon_outlined_directional_double-left_clip_mask_" data-name="icon/outlined/directional/double-left (clip mask)" width="12" height="12" fill="none"/>
                </clipPath>
                <filter id="VectorCalendarYearsArrow" x="0.609" y="0.375"  filterUnits="userSpaceOnUse">
                    <feOffset input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="0.5" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <rect id="icon_outlined_directional_double-left_clip_mask_2" data-name="icon/outlined/directional/double-left (clip mask)" width="12" height="12" fill="none"/>
            <g id="icon_outlined_directional_double-left-2" data-name="icon/outlined/directional/double-left" clipPath="url(#clip-pathCalendarYearsArrow)">
                <g id="icon_outlined_directional_double-left_clip_group_" data-name="icon/outlined/directional/double-left (clip group)">
                    <rect id="icon_outlined_directional_double-left_style_" data-name="icon/outlined/directional/double-left (style)" width="12" height="12" fill="none"/>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#VectorCalendarYearsArrow)">
                        <path id="Vector-2" data-name="Vector" d="M7.688,8.25H6.782a.184.184,0,0,1-.148-.072L3.642,4.357a.376.376,0,0,1,0-.463L6.634.072A.189.189,0,0,1,6.782,0h.906a.094.094,0,0,1,.074.151L4.652,4.125,7.762,8.1a.094.094,0,0,1-.074.151Zm-3.563,0H3.219a.186.186,0,0,1-.148-.072L.08,4.357a.374.374,0,0,1,0-.463L3.072.072A.189.189,0,0,1,3.219,0h.906A.093.093,0,0,1,4.2.151L1.089,4.125,4.2,8.1a.093.093,0,0,1-.074.151Z" transform="translate(2.11 1.88)" fill="#eaeaec"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default CalendarYearsArrow