import {useEffect, useState} from "react";

export default function useResizeHook() {
    const [mobile, setMobile] = useState();
    const [tab, setTab] = useState();
    const [pc, setPc] = useState();
    const [height, setHeight] = useState();
    const [width, setWidth] = useState();

    useEffect(() => {
        function detectResize() {
            const deviceWidth = document.documentElement.clientWidth, deviceHeight = document.documentElement.clientHeight;
            let deviceMobile = false, deviceTab = false, devicePc = false;

            if (deviceWidth <= 540) {
                devicePc = false;
                deviceMobile = true;
                deviceTab = false
            } else if (deviceWidth > 540 && deviceWidth <= 786 ){
                devicePc = false;
                deviceMobile = false;
                deviceTab = true
            } else {
                devicePc = true;
                deviceMobile = false;
                deviceTab = false
            }
            setMobile(deviceMobile)
            setTab(deviceTab)
            setPc(devicePc)
            setWidth(deviceWidth)
            setHeight(deviceHeight)
        }
        detectResize()
        window.addEventListener("resize",  detectResize, true)
        return () => {
            window.removeEventListener("resize", detectResize, true)
        }
    }, [])

    return {mobile, tab, pc, height, width}
}