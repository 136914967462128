
import {
    REQUEST_AVAILABLE_LANGUAGES,
    REQUEST_SELECTED_LANGUAGE_MESSAGE
} from './actionTypes';

export function getAvailableLanguages() {
    return dispatch => {
        dispatch({type: REQUEST_AVAILABLE_LANGUAGES});
    }
}

export function getSelectedLanguage(lang) {
    return dispatch => {
        dispatch({type: REQUEST_SELECTED_LANGUAGE_MESSAGE, langId: lang.id});
    }
}
