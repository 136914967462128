import {combineReducers} from "redux";
import {
    availableLanguagesReducer as availableLanguages,
    selectedLanguagesReducer as selectedLanguages
} from "./languagesReducer";
import globalInfo from "./globalInfoRducer";
import settings from "./settingsReducer";
import winnerList from "./winnerListReducer";
import history from "./historyReducer";
import viewSetting from "./viewSettingsReducer";
import selectedChip from "./selectedChipReducer";
import totalBets from "./totalBetsReducer";
import {userReducer as user} from "./userReducer";
import round from "./roundReducer";
import timeTicker from "./timeTickerReducer";
import historyLoading from "./historyLoadingReducer";
import isEnabledMainBetArea from "./isEnabledMainBetAreaReducer";
import videoStreamConfig from "./videoStreamReducer";
import activeBets from "./activeBetsReducer";
import gameCondition from "./gameConditionReducer";
import userInfo from "./userInfoReducer";
import alertMessage from "./alertMessagesReducer";
import gameHistory from "./gameHistoryReducer";
import rules from "./rulesReducer";
import playerHands from "./cards/playerHandsReducer";
import dealerHand from "./cards/dealerHandReducer";
import isCustomDisableInsurancePanel from "./isCustomDisableInsurancePanelReducer";
import isCustomDisableSelectHandPanel from "./isCustomDisableSelectHandPanelReducer";
import isCustomDisableActionPanel from "./isCustomDisableActionPanelReducer";
import userWon from "./userWonReducer";
import limit from "./limitReducers";

const rootReducer = combineReducers({
    globalInfo,
    availableLanguages,
    selectedLanguages,
    settings,
    winnerList,
    history,
    viewSetting,
    selectedChip,
    totalBets,
    user,
    round,
    timeTicker,
    historyLoading,
    isEnabledMainBetArea,
    videoStreamConfig,
    activeBets,
    gameCondition,
    userInfo,
    alertMessage,
    gameHistory,
    rules,
    playerHands,
    dealerHand,
    isCustomDisableInsurancePanel,
    isCustomDisableSelectHandPanel,
    isCustomDisableActionPanel,
    userWon,
    limit
});

export default rootReducer;
