import React, {useMemo, useEffect} from "react";
import classNames from "classnames";

function Modal({children, handleCancel,  parentClass, show, ...restProps}) {

    const changeShowChild = useMemo(() => (e) => {
        handleCancel(false);
    }, []);

    useEffect(() => {
        if (show) {
            document.addEventListener('click', changeShowChild, false);
        } else {
            document.removeEventListener('click', changeShowChild);
        }
        requestAnimationFrame(() => {
            window.scrollTo(0, 1)
        });
    }, [show]);

    return (
        <div className={classNames(`modal ${parentClass ? parentClass : ""}`, {show})} {...restProps} onClick={e => e.stopPropagation()}>
            {children}
        </div>
    )
}

export default Modal