export function getLocalStorage(key) {
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key) ) : false
}

export function setLocalStorage(key ,value) {
    localStorage.setItem(key, value)
}

export function removeLocalStorage(key) {
    localStorage.removeItem(key)
}

export function clearOldBets() {
    var keys = Object.keys(localStorage),
    i = keys.length;

    while ( i-- ) {
        if (keys[i].indexOf("_bets_") != -1) {
            removeLocalStorage(keys[i]);
        }
    }
}