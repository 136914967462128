import React from "react";
// import {FormattedMessage} from "react-intl";
// import {Prediction} from "@constants/Enums";

function BoardLeft({onClick}) {

    return (
        <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 108 108">
            <defs>
                <filter id="Rectangle_220" x="42.229" y="11.996" width="43.618" height="49.594" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
                <filter id="Rectangle_220-2" x="22.223" y="11.997" width="43.618" height="49.594" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur-2"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur-2"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Group_2798" data-name="Group 2798" transform="translate(-1091 -840.004)">
                <g id="Ellipse_4212" data-name="Ellipse 4212" transform="translate(1091 840.004)" fill="none" stroke="#79c4f2" strokeWidth="1">
                    <circle cx="54" cy="54" r="54" stroke="none"/>
                    <circle cx="54" cy="54" r="53.5" fill="none"/>
                </g>
                <g id="Ellipse_4209" data-name="Ellipse 4209" transform="translate(1095 844)" fill="rgba(3,76,140,0.5)" stroke="#79c4f2" strokeWidth="1">
                    <circle cx="50" cy="50" r="50" stroke="none"/>
                    <circle cx="50" cy="50" r="49.5" fill="none"/>
                </g>
                <text id="PERFECT_PAIR" data-name="PERFECT PAIR" transform="translate(1145 913.004)" fill="#fff" fontSize="14" fontFamily="SFCompactText-Heavy, SF Compact Text" fontWeight="800" letterSpacing="0.04em"><tspan x="-31" y="0">PERFECT</tspan><tspan x="-16" y="16">PAIR</tspan></text>
                <g id="Group_2796" data-name="Group 2796">
                    <g id="Group_83" data-name="Group 83" transform="translate(15472.148 22932.984) rotate(20)">
                        <g transform="matrix(0.94, -0.34, 0.34, 0.94, -21070.1, -15841.97)" filter="url(#Rectangle_220)">
                            <g id="Rectangle_220-3" data-name="Rectangle 220" transform="translate(59.17 16) rotate(20)" fill="#eaeaec" stroke="#eaeaec" strokeWidth="1">
                                <rect width="22" height="32" rx="2" stroke="none"/>
                                <rect x="0.5" y="0.5" width="21" height="31" rx="1.5" fill="none"/>
                            </g>
                        </g>
                        <path id="Union_10" data-name="Union 10" d="M3.081,24.945V24.4a2.022,2.022,0,0,0,1.774-1.079,5.564,5.564,0,0,0,.532-2.177,3.435,3.435,0,0,1-3.037,1.473A2.279,2.279,0,0,1,.286,19.2c.752-1.473,3.291-1.823,4.743.149a5.649,5.649,0,0,0-1.6-2.025c-1.111-.969-.476-3.688,2.229-3.6,2.7-.085,3.338,2.633,2.228,3.6a5.668,5.668,0,0,0-1.607,2.026c1.45-1.972,3.989-1.622,4.743-.149a2.281,2.281,0,0,1-2.06,3.417,3.434,3.434,0,0,1-3.037-1.473,5.266,5.266,0,0,0,.52,2.127A2.144,2.144,0,0,0,8.227,24.4v.543ZM7.508,9.978l-.5-2H4.091l-.52,2H.628L3.8,0H7.531l3.146,9.978ZM4.6,5.936H6.514l-.873-3.5h-.15Z" transform="translate(-21003.68 -15843.648)"/>
                    </g>
                    <g id="Group_73" data-name="Group 73" transform="translate(26281.303 8569.487) rotate(-20)">
                        <g transform="matrix(0.94, 0.34, -0.34, 0.94, -21027.5, -15878.93)" filter="url(#Rectangle_220-2)">
                            <g id="Rectangle_220-4" data-name="Rectangle 220" transform="translate(28.22 23.52) rotate(-20)" fill="#eaeaec" stroke="#eaeaec" strokeWidth="1">
                                <rect width="22" height="32" rx="2" stroke="none"/>
                                <rect x="0.5" y="0.5" width="21" height="31" rx="1.5" fill="none"/>
                            </g>
                        </g>
                        <path id="Union_7" data-name="Union 7" d="M.116,17.813c-.527-2.7.79-4.178,2.987-4.089a2.841,2.841,0,0,1,2.55,2.436A2.837,2.837,0,0,1,8.2,13.724c2.2-.086,3.516,1.392,2.988,4.089s-5.536,7.133-5.536,7.133S.643,20.51.116,17.813ZM7.508,9.977l-.5-2H4.09l-.519,2H.628L3.8,0H7.53l3.147,9.977ZM4.595,5.935h1.92l-.873-3.5h-.15Z" transform="translate(-21003.68 -15843.648)" fill="#e6130a"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default BoardLeft;