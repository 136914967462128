import initialStore from "../initialStore";
import {
    SET_GAME_START,
    SET_SOCKET_CONNECTIONS,
    SET_LOGIN_DETECTED
} from "@actions/actionTypes";

export default function globalInfoReducer(state = initialStore.globalInfo, action) {
    switch (action.type) {
        case SET_SOCKET_CONNECTIONS:
            return {...state, isConnected: action.isConnected};
        case SET_GAME_START:
            return {...state, session: action.session, lobbyUrl: action.lobbyUrl};
        case SET_LOGIN_DETECTED:
            return {...state, detectNewLogin: action.detectNewLogin}
        default:
            return state;
    }
}
