import React from "react";
import BetArea from "@components/betBoard/betArea";
import UserActions from "@components/betBoard/UserActions";
import classNames from "classnames";
import DeleteBets from "@components/betBoard/deleteBets";

function BetBoard({threeD}) {
    return (
        <div className={classNames("bet-board", {'three-d-size':threeD})}>
            <BetArea threeD={threeD}/>
            <DeleteBets className={classNames("mobile", {threeD})}/>
            <UserActions threeD={threeD}/>
        </div>
    )
}

export default BetBoard;