import {
    SET_VIDEO_STREAM_CONFIG
} from "@actions/actionTypes";
import initialStore from "../initialStore";


export default function videoStreamConfigReducer(state = initialStore.videoStreamConfig, action) {
    switch (action.type) {
        case SET_VIDEO_STREAM_CONFIG:
            return action.config;
        default:
            return state;
    }
}