import React from "react";
import cardBg from "@assets/img/cardBg.svg";
import openedCardBg from "@assets/img/opendedCardBg.svg";
import classNames from "classnames";
import { CardTypeMap, CardsSuit} from "@constants/Enums";
import { useState } from "react";
import { useEffect } from "react";

function CardsListItem({card, ...restProps}) {
    const [cardItem, setCarditem] = useState({
        suit: 0,
        type: 0
    })

    useEffect(() => {
        let timer = setTimeout(() => {
            setCarditem(card);
        }, 500)
        return () => {
            clearTimeout(timer);
        }
    }, [card])
    
    return(
        <div className="card" {...restProps}>
            <div className={classNames("flip-card", {active: cardItem.suit || cardItem.Suit})}>
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                        <img src={cardBg} alt={cardBg} />
                    </div>
                    <div className="flip-card-back">
                        <img src={openedCardBg} alt={openedCardBg} />
                        <i className={`cardIcon icon-${cardItem ? CardTypeMap[cardItem.type || cardItem.Type] + CardsSuit[cardItem.suit || cardItem.Suit] : cardItem }`}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardsListItem;