import React from "react";
import classNames from "classnames";

function TwoHandButton({ onClick }) {
    
    return (
        <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48">
            <defs>
                <filter id="Path_3396TwoHandButton" x="17.006" y="6" width="26.992" height="35.435" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
                <filter id="Path_3397TwoHandButton" x="4" y="9.979" width="31.245" height="36.019" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur-2"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur-2"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="icon_outlined_editor_delete" transform="translate(14 14)">
                <circle id="Ellipse_349" cx="24" cy="24" r="24" transform="translate(-14 -14)" fill="none" opacity="0.7"/>
                <g id="Group_2969" >
                    <g transform="matrix(1, 0, 0, 1, -14, -14)" filter="url(#Path_3396TwoHandButton)">
                        <path id="Path_3396TwoHandButton-2" d="M251.041,1.895,240.483.033A2.183,2.183,0,0,0,237.954,1.8l-.1.567a3.827,3.827,0,0,1,3.223,2.8l.645,2.408A1.968,1.968,0,0,1,244.381,8.8a1.968,1.968,0,0,1,3.5,1.539c-.41,2.326-2.906,4.2-4.091,4.964l1.809,6.749a3.805,3.805,0,0,1,.128,1.082l1.522.268a2.183,2.183,0,0,0,2.529-1.771l3.034-17.206a2.183,2.183,0,0,0-1.771-2.529Z" transform="translate(-214.85 10)" fill="#fff"/>
                    </g>
                    <g transform="matrix(1, 0, 0, 1, -14, -14)" filter="url(#Path_3397TwoHandButton)">
                        <path id="Path_3397TwoHandButton-2" d="M14.648,74.4a2.181,2.181,0,0,0-2.674-1.544L1.619,75.63A2.183,2.183,0,0,0,.075,78.3L4.6,95.18a2.181,2.181,0,0,0,2.674,1.544l10.356-2.775a2.183,2.183,0,0,0,1.544-2.674ZM6.59,85.935a.58.58,0,0,1-.166-.621l1.583-4.689a.58.58,0,0,1,.932-.25l3.716,3.269a.58.58,0,0,1,.166.621l-1.583,4.689a.58.58,0,0,1-.932.25Z" transform="translate(10 -58.8)" fill="#fff"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default TwoHandButton