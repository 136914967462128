import {
    SET_TIME_TICKER
} from "@actions/actionTypes";
import initialStore from "../initialStore";

export default function timeTickerReducer(state = initialStore.timeTicker, action) {
    switch (action.type) {
        case SET_TIME_TICKER:
            return {
                diff: action.ticker.End - action.ticker.Start,
                start: action.ticker.Start,
                end: action.ticker.End
            };
        default:
            return state;
    }
}