import React from "react";
import Modal from "@components/modal";
import {FormattedMessage} from "react-intl";
import {HistoryMobileIcon, InfoMobileIcon, CloseMobileIcon} from "../resource";

function RightMenu({handleCancel, submitMenu, show}) {
    return(
        <Modal handleCancel={handleCancel}
               show={show}
               parentClass="right-menu"
        >
            <div className="title"><FormattedMessage id="menu" /></div>
            <ul className="menu-list">
                <li className="menu-list-item" onClick={() => submitMenu("history")}><HistoryMobileIcon /><FormattedMessage id="player_history"/></li>
                <li className="menu-list-item" onClick={() => submitMenu("rules")}><InfoMobileIcon /><FormattedMessage id="game_rules"/></li>
                <li className="menu-list-item" onClick={() => submitMenu("exit")}><CloseMobileIcon /><FormattedMessage id="close"/></li>
            </ul>

        </Modal>
    )
}

export default RightMenu