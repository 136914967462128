import {
    SET_CUSTOM_DISABLE_ACTION_PANEL,
    SET_ROUND_START
} from "@actions/actionTypes";
import {RoundActionType} from "@constants/Enums";

export default function isCustomDisableActionPanelReducer(state = true, action) {
    switch (action.type) {
        case SET_CUSTOM_DISABLE_ACTION_PANEL:
            return action.isEnable;
        case SET_ROUND_START:
            if (action.round?.actionType == RoundActionType.PlayerAction) {
                return false;
            }
            return state;
        default:
            return state;
    }
}
