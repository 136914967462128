import React from "react";

function CalendarIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24.5 24.5">
            <defs>
                <filter id="VectorCalendarIcon" x="0" y="0"  filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="icon_outlined_application_calendar_clip_group_" data-name="icon/outlined/application/calendar (clip group)" transform="translate(4.25 2.25)" opacity="0.6">
                <rect id="icon_outlined_application_calendar_style_" data-name="icon/outlined/application/calendar (style)" width="16" height="16" fill="none"/>
                <g transform="matrix(1, 0, 0, 1, -4.25, -2.25)" filter="url(#VectorCalendarIcon)">
                    <path id="Vector-2" data-name="Vector" d="M12,12.5H.5A.5.5,0,0,1,0,12V1.625a.5.5,0,0,1,.5-.5H3.125v-1A.125.125,0,0,1,3.25,0h.875A.125.125,0,0,1,4.25.125v1h4v-1A.125.125,0,0,1,8.375,0H9.25a.125.125,0,0,1,.125.125v1H12a.5.5,0,0,1,.5.5V12A.5.5,0,0,1,12,12.5ZM1.125,5.437v5.938h10.25V5.437Zm0-3.187V4.375h10.25V2.25h-2V3a.125.125,0,0,1-.125.125H8.375A.125.125,0,0,1,8.25,3V2.25h-4V3a.125.125,0,0,1-.125.125H3.25A.125.125,0,0,1,3.125,3V2.25Z" transform="translate(6 4)" fill="#fff"/>
                </g>
            </g>
        </svg>
    )
}

export default CalendarIcon