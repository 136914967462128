import React from "react";

function TotalBetsIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 30 30">
            <defs>
                <filter id="path4744TotalBetsIcon" x="0" y="0" width="30" height="30" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="layer1" transform="translate(7.471 -285.062)">
                <rect id="Rectangle_1094" data-name="Rectangle 1094" width="22" height="18" transform="translate(-3.471 289.062)" fill="none"/>
                <g transform="matrix(1, 0, 0, 1, -7.47, 285.06)" filter="url(#path4744TotalBetsIcon)">
                    <path id="path4744-2" data-name="path4744" d="M1.171,289.062a.643.643,0,0,0-.456,1.1l7.905,7.906-7.905,7.9a.643.643,0,0,0,.456,1.1H17.883a.643.643,0,0,0,.645-.641v-2.51a.643.643,0,1,0-1.286,0v1.865H2.728l7.255-7.261a.643.643,0,0,0,0-.906l-7.26-7.261h14.52v1.865a.643.643,0,1,0,1.286,0v-2.506a.643.643,0,0,0-.645-.646Z" transform="translate(5.47 -285.06)" fill="#fc0"/>
                </g>
            </g>
        </svg>
    )
}

export default TotalBetsIcon