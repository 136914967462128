import {
    SET_SELECTED_CHIP,
    SET_BETS,
    SET_ADD_BET,
    SUB_ACTIVE_BETS,
    RESET_ACTIVE_BET,
    SET_ALERT_MSG,
    SET_CUSTOM_DISABLED_PAIR,
    SET_USER_BALANCE,
    SUB_BALANCE_UPDATE,
    SEND_BET,
    SET_DELETE_BETS_CONDITION,
    CHECKED_IS_ACTIVE_CHIP,
    SET_TOTAL_ALL_BETS
} from "@actions/actionTypes";
import { Prediction, RoundActionType} from "@constants/Enums";
import {getLocalStorage, removeLocalStorage} from "@services/localStorageService";

export function selectChip(chip) {
    return dispatch => {
        dispatch({
            type: SET_SELECTED_CHIP,
            chip
        })
    }
}

export function checkedIsActiveChip(sum) {
    return dispatch => {
        dispatch ({
            type: CHECKED_IS_ACTIVE_CHIP,
            sum
        })
    }
}

export function getLocalBets(roundId) {
    return (dispatch, getStore) => {
        const { userInfo, totalBets, round } = getStore();
        const bets = getLocalStorage(userInfo.Id + "_bets_" + roundId);
        if (bets && !totalBets && round.actionType === RoundActionType.Bet) {
            dispatch({
                type: SET_BETS,
                bets,
                roundId,
                userId: userInfo.Id
            })
        }
    }
}

export function addBet(chip, type) {
    return (dispatch, getStore) => {
        const { round, userInfo } = getStore();
        if (round?.roundId) {
            dispatch({
                type: SET_ADD_BET,
                bets: [{Type: type, Stake: +chip}],
                roundId: round.roundId,
                userId: userInfo.Id
            })
        }
    }
}

export function sendBets(roundId) {
    return (dispatch, getStore) => {
        const { userInfo } = getStore();
        const key = userInfo.Id + "_bets_" + roundId;
        let bets = getLocalStorage(key);
        if (bets) {
            const mainBet =  bets?.find(bet => bet.Type == Prediction.Main) || null;
            bets = bets.filter(bet => bet.Type != Prediction.Main);
            if (mainBet) {
                dispatch({
                    eventEmit: SEND_BET,
                    emitData: {
                        Stake: mainBet.Stake,
                        SideBets: bets
                    },
                    type: SEND_BET
                })
            }
        }
    }
}

export function subscribeActiveBets() {
    return (dispatch, getState) => {
        
        dispatch({
            eventOn: SUB_ACTIVE_BETS,
            handle: (data) => {
                if (!data.ErrorCode) {
                    const { round, userInfo} = getState();
                    dispatch({
                        type: SET_BETS,
                        bets: [
                            {
                                Stake: data.Stake,
                                Type: data.BetType,
                            }
                        ],
                        roundId: round.roundId,
                        userId: userInfo.Id
                    });
                    dispatch({type: SET_USER_BALANCE, balance: data.Balance});
                }
            }
        })
    }
}

export function subscribeBalanceUpdate() {
    return dispatch => {
        dispatch({
            eventOn: SUB_BALANCE_UPDATE,
            handle: (data) => {
                dispatch({type: SET_USER_BALANCE, balance: data.Balance});
            }
        })
    }
}
export function setPair(name) {
    return dispatch => {
        dispatch({type: SET_CUSTOM_DISABLED_PAIR, name});
    }
}

export function resetActiveBets(roundId) {
    return (dispatch, getStore) => {
        const { userInfo } = getStore();
        if (roundId) {
            removeLocalStorage( userInfo.Id + "_bets_" + roundId)
        }
        dispatch({
            type: RESET_ACTIVE_BET,
        })
    }
}

export function clearActiveBetsInStorage(roundId) {
    return (_, getStore) => {
        const { userInfo } = getStore();
        if (roundId) {
            removeLocalStorage( userInfo.Id + "_bets_" + roundId)
        }
    }
}

export function resetActiveBetsStore(roundId) {
    return dispatch => {
        dispatch({
            type: RESET_ACTIVE_BET,
        })
    }
}

export function setDeleteBetCondition(condition) {
    return dispatch => {
        dispatch({
            type: SET_DELETE_BETS_CONDITION,
            condition
        })
    }
}

export function setTotalBets(bets) {
    return dispatch => {
        dispatch({type: SET_TOTAL_ALL_BETS, bets});
    }
}