import React, { useEffect, useState } from "react";
import {connect} from "react-redux";
import {abbrNum} from "@services/betService";

function Single3DChip({chipName, chipTitle, attributeClassName, disabledFilter, nominal}) {

    const [name, setName] = useState("")
    const [chip, setChip] = useState(chipName && chipName.toString())

    useEffect(() => {
        setName(`${attributeClassName}${chipName}`)
        if (nominal && !nominal.includes(chipName.toString())) {
            const closest = nominal.reduce((a, b) => {
                return Math.abs(b - chipName) < Math.abs(a - chipName) ? b : a;
            });
            setName(`${attributeClassName}${closest}`)
        }
    }, [chipName, nominal])

    useEffect(() => {
        let title = Number(chipTitle.toFixed(2)).toString()
        if (nominal && !nominal.includes(title)) {
            setChip(abbrNum(title).toString())
        } else {
            setChip(title)
        }
    }, [chipTitle, nominal])

    return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 84 84">
        <defs>
            <linearGradient id={`${attributeClassName}${chipName}-linear-gradient`} x1="1.341" y1="0.751" x2="-0.096" y2="0.314" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#ccd5d6"/>
                <stop offset="0.5" stopColor="#bacdcf"/>
                <stop offset="1" stopColor="#e8f0ef"/>
            </linearGradient>
            {
                disabledFilter ?  <filter id={attributeClassName} x="2.936" y="25.021" width="78.127" height="47.068" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter> : ""
            }

            <linearGradient id={`${attributeClassName}${chipName}-linear-gradient-3`} x1="1.341" y1="1.341" x2="0.016" y2="0.015" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#ccd6d5"/>
                <stop offset="1" stopColor="#e8eff0"/>
            </linearGradient>
            <linearGradient id={`${attributeClassName}{chipName}-linear-gradient-4`} x1="1.345" y1="1.345" x2="0.019" y2="0.019" xlinkHref={`#${attributeClassName}${chipName}-linear-gradient-3`}/>
            <linearGradient id={`${attributeClassName}${nominal[0] || 0}-linear-gradient-5`} x1="1.444" y1="1.444" x2="0.118" y2="0.118" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#1f1f1f"/>
                <stop offset="1" stopColor="#9d9d9d"/>
            </linearGradient>
            <linearGradient id={`${attributeClassName}${nominal[1] || 1}-linear-gradient-5`} x1="1.444" y1="1.444" x2="0.118" y2="0.118" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#634500"/>
                <stop offset="1" stopColor="#c29642"/>
            </linearGradient>
            <linearGradient id={`${attributeClassName}${nominal[2] || 2}-linear-gradient-5`} x1="1.444" y1="1.444" x2="0.118" y2="0.118" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#630000"/>
                <stop offset="1" stopColor="#c24242"/>
            </linearGradient>
            <linearGradient id={`${attributeClassName}${nominal[3] || 3}-linear-gradient-5`} x1="1.444" y1="1.444" x2="0.118" y2="0.118" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#040063"/>
                <stop offset="1" stopColor="#4264c2"/>
            </linearGradient>
            <linearGradient id={`${attributeClassName}${nominal[4] || 4}-linear-gradient-5`} x1="1.444" y1="1.444" x2="0.118" y2="0.118" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#5f6300"/>
                <stop offset="1" stopColor="#bdc242"/>
            </linearGradient>
            <linearGradient id={`${attributeClassName}${nominal[5] || 5}-linear-gradient-5`} x1="1.444" y1="1.444" x2="0.118" y2="0.118" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#306300"/>
                <stop offset="1" stopColor="#78c242"/>
            </linearGradient>
            <linearGradient id={`${attributeClassName}${nominal[6] || 6}-linear-gradient-5`} x1="1.444" y1="1.444" x2="0.118" y2="0.118" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#630054"/>
                <stop offset="1" stopColor="#c2428c"/>
            </linearGradient>
            <linearGradient id={`${attributeClassName}${nominal[7] || 7}-linear-gradient-5`} x1="1.444" y1="1.444" x2="0.118" y2="0.118" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#350063"/>
                <stop offset="1" stopColor="#6e42c2"/>
            </linearGradient>
            <linearGradient id={`${attributeClassName}${nominal[8] || 8}-linear-gradient-5`} x1="1.444" y1="1.444" x2="0.118" y2="0.118" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#A0522D"/>
                <stop offset="1" stopColor="#A0522D"/>
            </linearGradient>
            <linearGradient id={`${attributeClassName}${nominal[9] || 9}-linear-gradient-5`} x1="1.444" y1="1.444" x2="0.118" y2="0.118" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#FF4500"/>
                <stop offset="1" stopColor="#FF4500"/>
            </linearGradient>
            <linearGradient id={`${attributeClassName}${chipName}-linear-gradient-6`} x1="0.919" y1="1.089" x2="0.326" y2="0.256" xlinkHref={`#${attributeClassName}${chipName}-linear-gradient`}/>
            <linearGradient id={`${attributeClassName}${chipName}-linear-gradient-7`} x1="0.081" y1="1.089" x2="0.674" y2="0.255" xlinkHref={`#${attributeClassName}${chipName}-linear-gradient-3`}/>
            <linearGradient id={`${attributeClassName}${chipName}-linear-gradient-8`} x1="2.353" y1="3.003" x2="-0.945" y2="-1.475" xlinkHref={`#${attributeClassName}${chipName}-linear-gradient-5`}/>
            <linearGradient id={`${attributeClassName}${chipName}-linear-gradient-9`} x1="1.587" y1="1.594" x2="0.263" y2="0.265" xlinkHref={`#${attributeClassName}${chipName}-linear-gradient-5`}/>
            <linearGradient id={`${attributeClassName}${chipName}-linear-gradient-10`} x1="1.353" y1="1.352" x2="0.027" y2="0.027" xlinkHref={`#${attributeClassName}${chipName}-linear-gradient-5`}/>
            <linearGradient id={`${attributeClassName}${chipName}-linear-gradient-11`} x1="1.59" y1="1.6" x2="0.265" y2="0.273" xlinkHref={`#${attributeClassName}${chipName}-linear-gradient-5`}/>
        </defs>
        <g id="Group_155" data-name="Group 155" transform="translate(-391.5 -926)">
            {disabledFilter ?
                <g id="Group_128" data-name="Group 128" transform="translate(391.5 926)">
                    <rect id="Rectangle_360" data-name="Rectangle 360"  fill="none"/>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter={`url(#${attributeClassName})`}>
                        <path id="Path_366-2" data-name="Path 366" d="M323,269.935l.092-7.3c3.159-7.932,17.069-10.225,32.967-10.225,14.267,0,28.473,3.61,33.069,10.324v7.219c0,9.674-14.807,17.525-33.048,17.525S323,279.609,323,269.935Z" transform="translate(-314.06 -223.39)" fill={`url(#${attributeClassName}${chipName}-linear-gradient)`}/>
                    </g>
                </g>
                : ""
            }

            <g id="_1" data-name="1" transform="translate(391.5 926)">
                <rect id="Rectangle_351" data-name="Rectangle 351"  fill="none"/>
                <g id="_1-2" data-name="1" transform="translate(8.936 20.476)">
                    <path id="Path_351" data-name="Path 351" d="M323,269.935l.092-7.3c3.159-7.932,17.069-10.225,32.967-10.225,14.267,0,28.473,3.61,33.069,10.324v7.219c0,9.674-14.807,17.525-33.048,17.525S323,279.609,323,269.935Z" transform="translate(-323 -243.865)" fill={`url(#${attributeClassName}${chipName}-linear-gradient)`}/>
                    <path id="Path_352" data-name="Path 352" d="M323,265.107c0-10.84,14.812-19.637,33.069-19.637s33.059,8.8,33.059,19.637-14.812,19.637-33.059,19.637S323,275.948,323,265.107Z" transform="translate(-323 -245.409)" fill={`url(#${attributeClassName}${chipName}-linear-gradient-3)`}/>
                    <path id="Path_353" data-name="Path 353" d="M330.9,277.808l-.326-.212c-4.72-3.4-7.554-7.746-7.554-12.488,0-10.84,14.812-19.637,33.069-19.637a51.481,51.481,0,0,1,15.75,2.376l1.3.465Z" transform="translate(-323 -245.41)" fill="#fff" opacity="0.18"/>
                    <path id="Path_354" data-name="Path 354" d="M355.872,277.381c-11.968,0-21.672-5.885-21.672-13.146s9.7-13.146,21.672-13.146,21.662,5.9,21.662,13.146S367.83,277.381,355.872,277.381Zm0-1.709c10.439,0,18.91-5.056,18.91-11.437s-8.471-11.477-18.91-11.477-18.92,5.147-18.92,11.477S345.423,275.672,355.872,275.672Z" transform="translate(-322.783 -245.346)" fill={`url(#${attributeClassName}${chipName}-linear-gradient-4)`}/>
                    <path id="Path_355" data-name="Path 355" d="M355.872,277.381c-.754,0-1.488,0-2.222-.061h-.163v-1.689h.449c.632,0,1.274.061,1.937.061h1.4v1.668Zm5.678-.465-1.019-1.567.958-.152c.591-.111,1.172-.243,1.743-.384l.856-.253,1.019,1.557-.805.233c-.652.172-1.315.313-2.039.445Zm-12.905-.293-1.213-.273c-.642-.162-1.274-.354-1.886-.556l-.347-.121,1.1-1.547.551.2q.805.273,1.651.485l1.244.273Zm20.8-2.154-1.957-1.224.418-.182c.459-.233.907-.485,1.335-.738l1.019-.657,1.947,1.173-1.019.657a17.161,17.161,0,0,1-1.539.849Zm-27.687-.273-1.2-.667c-.489-.293-.958-.607-1.407-.93h0L341.1,271.4l.214.172q.581.425,1.223.819l1.2.667Zm33.008-3.539-2.548-.667.285-.283a9.041,9.041,0,0,0,.8-1.011l.489-.809,2.528.667-.479.789a11.971,11.971,0,0,1-.917,1.153Zm-38.278-.576-.581-.728a11.506,11.506,0,0,1-.734-1.2l-.082-.212,2.579-.617.133.334a8.817,8.817,0,0,0,.642,1.011l.6.758Zm-2.181-4.621-.1-1.224.092-1.072h2.763l-.092,1.072a7.475,7.475,0,0,0,.092,1.173v.051Zm40.368,0v-.051a7.478,7.478,0,0,0,.092-1.173l-.092-1.072h2.752l.092,1.072-.1,1.224Zm-.632-4.389-.1-.243a8.093,8.093,0,0,0-.642-1.011l-.663-.839,2.569-.617.632.8a9.3,9.3,0,0,1,.724,1.213l.061.121Zm-36.28-.142-2.548-.667.693-1.143.775-1.011,2.528.657-.765,1.011a8.077,8.077,0,0,0-.642,1.011Zm3.2-3.741L339,256.055l.143-.131c.449-.324.917-.637,1.407-.93l1.081-.6,1.947,1.183-1.081.6c-.428.253-.836.526-1.223.809Zm29.379-.334-1.1-.728c-.428-.263-.877-.506-1.335-.738l-.285-.131,1.967-1.193h.061c.54.273,1.019.556,1.539.849l1.1.738Zm-23.6-2.669-1.019-1.557,1.7-.506,1.784-.394,1.019,1.557-1.723.384c-.561.142-1.111.3-1.651.475Zm17.992-.081-1.5-.435q-.856-.222-1.743-.394h-.265l1.1-1.547,1.978.435,1.529.455Zm-11.213-1.244v-1.689h.163c.734,0,1.468-.071,2.222-.071h1.4v1.679h-1.4c-.663,0-1.3,0-1.937.061Z" transform="translate(-322.783 -245.347)" fill={`url(#${attributeClassName}${chipName}-linear-gradient-5)`}/>
                    <path id="Path_356" data-name="Path 356" d="M356.79,276.36c-5.872,0-11.132-1.628-14.6-4.176l-.234-.182.54.354a26.191,26.191,0,0,0,13.374,3.357c10.439,0,18.91-5.137,18.91-11.477a8.693,8.693,0,0,0-3.232-6.411l-.856-.7.693.455c2.691,2.022,4.312,4.53,4.312,7.3C375.7,271.223,367.228,276.36,356.79,276.36Zm-16.952-3.307-.693-.455c-3.058-2.275-4.944-5.187-4.944-8.363,0-7.25,9.7-13.146,21.672-13.146,6.718,0,12.742,1.871,16.718,4.783l.224.192-.7-.475a30.069,30.069,0,0,0-15.291-3.853c-11.968,0-21.672,5.9-21.672,13.146a9.983,9.983,0,0,0,3.7,7.351Z" transform="translate(-322.783 -245.346)" opacity="0.4"/>
                    <path id="Path_357" data-name="Path 357" d="M355.877,277.535c-12.11,0-21.927-5.956-21.927-13.3s9.817-13.3,21.927-13.3,21.917,5.956,21.917,13.3S367.977,277.535,355.877,277.535Zm0-.3c11.815,0,21.407-5.824,21.407-12.994s-9.592-12.994-21.407-12.994-21.407,5.825-21.407,12.994S344.052,277.231,355.877,277.231Z" transform="translate(-322.788 -245.347)" fill={`url(#${attributeClassName}${chipName}-linear-gradient-6)`}/>
                    <path id="Path_358" data-name="Path 358" d="M355.812,275.707c10.5,0,19.022-5.2,19.022-11.6s-8.522-11.6-19.022-11.6-19.012,5.2-19.012,11.619S345.322,275.707,355.812,275.707Zm0-.273c-10.245,0-18.573-5.056-18.573-11.326s8.328-11.325,18.573-11.325,18.583,5.056,18.583,11.325-8.328,11.325-18.583,11.325Z" transform="translate(-322.732 -245.331)" fill={`url(#${attributeClassName}${chipName}-linear-gradient-7)`}/>
                    <path id="Path_359" data-name="Path 359" d="M355.613,288.609c-1.142,0-2.273,0-3.384-.069l-1.529-.09v-6.02h10.245v5.992h-.3C359.008,288.554,357.326,288.609,355.613,288.609Z" transform="translate(-322.464 -244.995)" fill={`url(#${attributeClassName}${chipName}-linear-gradient-8)`}/>
                    <path id="Path_360" data-name="Path 360" d="M380.19,281.936v.581a19.61,19.61,0,0,0,5.1-4.366l.285-.326-.051-5.565-5.331,3.975Z" transform="translate(-321.891 -245.11)" fill={`url(#${attributeClassName}${chipName}-linear-gradient-9)`}/>
                    <path id="Path_361" data-name="Path 361" d="M356.02,284.685c-1.142,0-2.263,0-3.374-.1l-1.448-.152v-4.439c0-.384.53-.7,1.172-.7a38.209,38.209,0,0,0,7.9,0c.652,0,1.172.313,1.172.7v4.439l-2.039.182C358.293,284.655,357.161,284.685,356.02,284.685Zm25.22-6.957-6.116-2.245c-.55-.2-.724-.627-.387-.961a15.043,15.043,0,0,0,4.159-3.984,1.683,1.683,0,0,1,1.611-.233l6.116,2.225-.092.162a21.609,21.609,0,0,1-4.954,4.844Zm-51.214-.587-1.417-1.112a20.212,20.212,0,0,1-3.058-3.337l-.53-.86,6.7-2.2a1.652,1.652,0,0,1,1.59.263A13.947,13.947,0,0,0,337.141,274c.316.344.112.758-.449.951Zm50.215-16.685a1.382,1.382,0,0,1-.917-.364,14.051,14.051,0,0,0-3.833-4.1c-.316-.344-.112-.768.449-.951l6.116-2.022,1.325,1.011a19.508,19.508,0,0,1,3.058,3.337l.571.93-6.116,2.022A1.9,1.9,0,0,1,380.24,260.457Zm-47.687-.678a1.613,1.613,0,0,1-.438-.091l-6.473-2.376,1.315-1.618a23.392,23.392,0,0,1,3.558-3.135l.6-.394,6.392,2.346c.55.2.724.627.377,1.011a15.022,15.022,0,0,0-4.149,3.994A1.6,1.6,0,0,1,332.553,259.779Zm19.817-9.01c-.642,0-1.172-.313-1.172-.7v-4.429l1.448-.131c1.111-.061,2.232-.1,3.374-.1s2.273,0,3.384.1l2.039.192v4.348c0,.384-.52.7-1.172.7a38.209,38.209,0,0,0-7.9.02Z" transform="translate(-322.961 -245.41)" fill={`url(#${attributeClassName}${chipName}-linear-gradient-10)`}/>
                    <path id="Path_362" data-name="Path 362" d="M356.128,245.43c13.69,0,25.485,4.945,30.459,11.993l.418.667-1.356-1.658c-5.6-6.118-16.514-10.284-29.063-10.284-18.257,0-33.069,8.8-33.069,19.637a12.569,12.569,0,0,0,1.488,5.835l.7,1.133V272.7a13.077,13.077,0,0,1-2.61-7.645C323.059,254.227,337.871,245.43,356.128,245.43Z" transform="translate(-322.998 -245.409)" fill="#fff" opacity="0.3"/>
                    <path id="Path_363" data-name="Path 363" d="M355.988,284.593c-13.7,0-25.485-4.955-30.48-12l-.418-.667,1.346,1.658c5.607,6.118,16.534,10.284,29.093,10.284,18.257,0,33.079-8.808,33.079-19.648a12.509,12.509,0,0,0-1.488-5.845l-.7-1.133.051.061a13.076,13.076,0,0,1,2.6,7.645C389.067,275.785,374.245,284.593,355.988,284.593Z" transform="translate(-322.96 -245.278)" fill="#fff" opacity="0.3"/>
                    <path id="Path_364" data-name="Path 364" d="M330.1,282.079h0a18.878,18.878,0,0,1-4.669-4.222l-.311-.423-.046-5.883,5.046,4.438Z" transform="translate(-322.96 -245.118)" fill={`url(#${name}-linear-gradient-11)`}/>
                    <text x="40%" y="25%" style={{transform: "rotate3d(-1, 0, 0, 20deg)", fontFamily: "SF Pro Text Bold"}} dominantBaseline="middle" fontWeight="900" fontSize={chip.length > 4 ? "8px" : chip.length > 3 ? "10px" : "14px"} letterSpacing="0.56px"  textAnchor="middle">{chip}</text>
                </g>
            </g>
        </g>
    </svg>)
}
const mapStateToProps = (state) => {
    return {
        nominal: state.limit.Nominal
    }
}
export default connect(mapStateToProps, null)(Single3DChip)