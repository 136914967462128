import {SET_PLAYER_HAND} from "@actions/actionTypes";
import initialStore from "../../initialStore";

function playerHandsReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_PLAYER_HAND:
            return  action.playerHands;
        default:
            return state;
    }
}

export default playerHandsReducer