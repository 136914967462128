import {
    SET_RULES
} from "@actions/actionTypes";

export default function rulesReducer(state = "", action) {
    switch (action.type) {
        case SET_RULES:
            return action.rules
        default:
            return state;
    }
}