import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {abbrNum} from "@services/betService";

function SingleChip({chipName, chipTitle, attributeClassName, nominal}) {
    const [name, setName] = useState("")
    const [chip, setChip] = useState(chipName && chipName.toString())

    useEffect(() => {
        if (chipName) {
            setName(`${attributeClassName}${chipName}`)
            if (nominal && !nominal.includes(chipName)) {
                const closest = nominal.reduce((a, b) => {
                    return Math.abs(b - chipName) < Math.abs(a - chipName) ? b : a;
                });
                setName(`${attributeClassName}${nominal[closest]}`)
            }
        }
    }, [chipName, nominal])

    useEffect(() => {
        let title = Number(chipTitle.toFixed(2)).toString()
        if (nominal && !nominal.includes(title)) {
            setChip(abbrNum(title).toString())
        } else {
            setChip(title)
        }
    }, [chipTitle, nominal])

    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 71.851 71.88">
            <defs>
                <linearGradient id={`${name}-linear-gradient`} x1="1.443" y1="1.443" x2="0.118" y2="0.118" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#ccd6d5"/>
                    <stop offset="1" stopColor="#e8eff0"/>
                </linearGradient>
                <filter id={`${name}filter`} x="0" y="0" width="71.851" height="71.88" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
                <linearGradient id={`${name}-linear-gradient-3`} x1="1.443" y1="1.443" x2="0.118" y2="0.118" xlinkHref={`#${name}-linear-gradient`}/>
                <linearGradient id={`${attributeClassName}${nominal[0] || 0}-linear-gradient-4`} x1="1.444" y1="1.444" x2="0.118" y2="0.118" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#1f1f1f"/>
                    <stop offset="1" stopColor="#9d9d9d"/>
                </linearGradient>
                <linearGradient id={`${attributeClassName}${nominal[1] || 1}-linear-gradient-4`} x1="1.444" y1="1.444" x2="0.118" y2="0.118" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#634500"/>
                    <stop offset="1" stopColor="#c29642"/>
                </linearGradient>
                <linearGradient id={`${attributeClassName}${nominal[2] || 2}-linear-gradient-4`} x1="1.444" y1="1.444" x2="0.118" y2="0.118" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#630000"/>
                    <stop offset="1" stopColor="#c24242"/>
                </linearGradient>
                <linearGradient id={`${attributeClassName}${nominal[3] || 3}-linear-gradient-4`} x1="1.444" y1="1.444" x2="0.118" y2="0.118" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#040063"/>
                    <stop offset="1" stopColor="#4264c2"/>
                </linearGradient>
                <linearGradient id={`${attributeClassName}${nominal[4] || 4}-linear-gradient-4`} x1="1.444" y1="1.444" x2="0.118" y2="0.118" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#5f6300"/>
                    <stop offset="1" stopColor="#bdc242"/>
                </linearGradient>
                <linearGradient id={`${attributeClassName}${nominal[5] || 5}-linear-gradient-4`} x1="1.444" y1="1.444" x2="0.118" y2="0.118" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#306300"/>
                    <stop offset="1" stopColor="#78c242"/>
                </linearGradient>
                <linearGradient id={`${attributeClassName}${nominal[6] || 6}-linear-gradient-4`} x1="1.444" y1="1.444" x2="0.118" y2="0.118" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#630054"/>
                    <stop offset="1" stopColor="#c2428c"/>
                </linearGradient>
                <linearGradient id={`${attributeClassName}${nominal[7] || 7}-linear-gradient-4`} x1="1.444" y1="1.444" x2="0.118" y2="0.118" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#350063"/>
                    <stop offset="1" stopColor="#6e42c2"/>
                </linearGradient>
                <linearGradient id={`${attributeClassName}${nominal[8] || 8}-linear-gradient-4`} x1="1.444" y1="1.444" x2="0.118" y2="0.118" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#A0522D"/>
                    <stop offset="1" stopColor="#A0522D"/>
                </linearGradient>
                <linearGradient id={`${attributeClassName}${nominal[9] || 9}-linear-gradient-4`} x1="1.444" y1="1.444" x2="0.118" y2="0.118" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#FF4500"/>
                    <stop offset="1" stopColor="#FF4500"/>
                </linearGradient>
                <linearGradient id={`${name}-linear-gradient-5`} x1="0.915" y1="1.083" x2="0.322" y2="0.25" xlinkHref={`#${name}-linear-gradient`}/>
                <linearGradient id={`${name}-linear-gradient-6`} x1="0.085" y1="1.084" x2="0.678" y2="0.251" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#ccd5d6"/>
                    <stop offset="0.5" stopColor="#bacdcf"/>
                    <stop offset="1" stopColor="#e8f0ef"/>
                </linearGradient>
                <linearGradient id={`${name}-linear-gradient-7`} x1="1.458" y1="1.457" x2="0.132" y2="0.132" xlinkHref={`#${name}-linear-gradient-4`}/>
                <linearGradient id={`${name}-linear-gradient-8`} x1="0.5" y1="0" x2="0.5" y2="1" xlinkHref={`#${name}-linear-gradient-6`}/>

            </defs>
            <g id="Group_457" data-name="Group 457" transform="translate(-612 -986.12)">
                <g id="Group_450" data-name="Group 450" transform="translate(618 990)">
                    <g id="poker_chips_set2_Converted_" data-name="poker_chips_set2 [Converted]" transform="translate(0)">
                        <g transform="matrix(1, 0, 0, 1, -6, -3.88)" filter={`url(#${name}filter)`}>
                            <path id="Path_458-2" data-name="Path 458" d="M323,182.879a29.926,29.926,0,1,1,29.923,29.931A29.933,29.933,0,0,1,323,182.879Z" transform="translate(-317 -148.93)" fill={`url(#${name}-linear-gradient)`}/>
                        </g>
                        <path id="Path_495" data-name="Path 495" d="M323,182.879a29.926,29.926,0,1,1,29.923,29.931A29.933,29.933,0,0,1,323,182.879Z" transform="translate(-323 -152.81)" fill={`url(#${name}-linear-gradient)`}/>
                        <path id="Path_459" data-name="Path 459" d="M330.151,202.243l-.3-.332A29.926,29.926,0,0,1,367.2,156.558l1.181.72Z" transform="translate(-323.002 -152.81)" fill="#fff" opacity="0.18"/>
                        <path id="Path_460" data-name="Path 460" d="M353.811,203.353a19.622,19.622,0,1,1,19.6-19.615A19.617,19.617,0,0,1,353.811,203.353Zm0-2.492a17.13,17.13,0,1,0-17.121-17.12,17.125,17.125,0,0,0,17.121,17.12Z" transform="translate(-323.867 -153.672)" fill={`url(#${name}-linear-gradient-3)`}/>
                        <path id="Path_461" data-name="Path 461" d="M353.813,203.355a18.1,18.1,0,0,1-2.011-.1h-.148v-2.52l.406.065c.572.055,1.153.083,1.753.083l1.264-.065v2.492Zm5.138-.7-.922-2.335.867-.221c.535-.166,1.061-.36,1.577-.572l.775-.378.923,2.317-.729.351a17.338,17.338,0,0,1-1.845.665Zm-11.679-.434-1.1-.406c-.581-.249-1.153-.526-1.707-.821l-.314-.194,1-2.307.5.3c.489.268.987.508,1.494.729l1.125.406Zm18.819-3.2-1.771-1.781.378-.286c.415-.341.821-.711,1.208-1.1l.923-.988,1.762,1.763-.922.988c-.443.443-.923.858-1.393,1.264Zm-25.055-.415-1.089-.988c-.443-.452-.867-.923-1.273-1.394v-.065l1.771-1.772.194.249q.526.637,1.107,1.218l1.089,1Zm29.88-5.279-2.306-1,.258-.425a15.863,15.863,0,0,0,.72-1.5l.443-1.2,2.288.988-.434,1.181q-.378.868-.83,1.717Zm-34.64-.868-.526-1.08q-.369-.923-.664-1.846l-.074-.3,2.334-.923.12.48a16.161,16.161,0,0,0,.581,1.578l.544,1.135ZM334.3,185.57l-.092-1.846.083-1.606h2.491l-.083,1.606a17.37,17.37,0,0,0,.083,1.744v.083Zm36.531,0v-.083q.083-.858.083-1.744l-.083-1.606h2.491l.083,1.606-.092,1.846Zm-.572-6.553-.092-.369a15.173,15.173,0,0,0-.581-1.569l-.6-1.246,2.325-.923.572,1.2a16.694,16.694,0,0,1,.655,1.8l.055.185Zm-32.841-.24-2.306-1,.627-1.7.7-1.467,2.288,1-.692,1.44a15.168,15.168,0,0,0-.581,1.569Zm2.9-5.584-1.771-1.772.129-.185c.406-.489.83-.923,1.273-1.394l.978-.923,1.762,1.763-.978.923q-.581.581-1.107,1.218Zm26.586-.5-1-1.089c-.387-.388-.793-.757-1.208-1.108l-.258-.185,1.78-1.781h.055a18.11,18.11,0,0,1,1.393,1.264l1,1.1Zm-21.356-3.978-.922-2.335,1.541-.748,1.614-.591.922,2.317-1.559.572c-.507.212-1.005.461-1.494.72Zm16.282-.129-1.356-.655c-.517-.212-1.042-.406-1.577-.572l-.24-.065,1-2.317,1.79.655,1.384.665Zm-10.147-1.846v-2.51h.148c.664-.065,1.328-.1,2.011-.1l1.264.065v2.492l-1.264-.065a15.572,15.572,0,0,0-1.753.092Z" transform="translate(-323.869 -153.673)" fill={`url(#${name}-linear-gradient-4)`}/>
                        <path id="Path_462" data-name="Path 462" d="M354.6,201.806a17.091,17.091,0,0,1-13.247-6.248l-.212-.286.489.535a17.125,17.125,0,0,0,26.291-21.689l-.775-1.034.627.683A17.13,17.13,0,0,1,354.6,201.806Zm-15.341-4.928-.627-.683A19.616,19.616,0,1,1,368.9,171.275l.2.277-.637-.7a19.616,19.616,0,0,0-30.129,24.836Z" transform="translate(-323.829 -153.629)" opacity="0.4"/>
                        <path id="Path_463" data-name="Path 463" d="M353.792,203.565a19.852,19.852,0,1,1,19.834-19.846,19.848,19.848,0,0,1-19.834,19.846Zm0-.461a19.382,19.382,0,1,0-19.372-19.382A19.377,19.377,0,0,0,353.792,203.1Z" transform="translate(-323.848 -153.652)" fill={`url(#${name}-linear-gradient-5)`}/>
                        <path id="Path_464" data-name="Path 464" d="M354,201.124a17.222,17.222,0,1,0-17.2-17.216A17.218,17.218,0,0,0,354,201.124Zm0-.4a6.967,6.967,0,1,1,0,.028Z" transform="translate(-324.07 -153.87)" fill={`url(#${name}-linear-gradient-6)`}/>
                        <path id="Path_465" data-name="Path 465" d="M353.073,212.708a30.322,30.322,0,0,1-3.053-.157l-1.31-.194v-6.765a1.061,1.061,0,0,1,1.061-1.052,21.133,21.133,0,0,0,7.149,0,1.052,1.052,0,0,1,1.061,1.052v6.691l-1.845.277a30.515,30.515,0,0,1-3.063.148ZM375.9,202.1l-5.535-3.424a1.062,1.062,0,0,1-.351-1.458,20.841,20.841,0,0,0,3.764-6.082,1.061,1.061,0,0,1,1.458-.351l5.535,3.4-.083.24a30.2,30.2,0,0,1-4.483,7.384Zm-46.346-.923-1.282-1.707a30.384,30.384,0,0,1-2.767-5.085l-.48-1.311,6.061-3.359a1.061,1.061,0,0,1,1.439.406,21.01,21.01,0,0,0,3.469,6.258,1.052,1.052,0,0,1-.406,1.44Zm45.442-25.445a1.07,1.07,0,0,1-.83-.545,21.01,21.01,0,0,0-3.469-6.258,1.062,1.062,0,0,1,.406-1.44l5.535-3.1,1.2,1.615a30.066,30.066,0,0,1,2.767,5.085l.517,1.421-5.535,3.092a1.19,1.19,0,0,1-.59.166Zm-43.154-1.024a1.1,1.1,0,0,1-.4-.148l-5.858-3.627,1.19-2.464a30.033,30.033,0,0,1,3.219-4.772l.544-.563,5.784,3.572a1.052,1.052,0,0,1,.341,1.458,21.176,21.176,0,0,0-3.754,6.091,1.079,1.079,0,0,1-1.07.489Zm17.933-13.752a1.061,1.061,0,0,1-1.061-1.052V153.16l1.31-.2a29.882,29.882,0,0,1,6.116,0l1.845.286v6.664a1.052,1.052,0,0,1-1.061,1.052,21.133,21.133,0,0,0-7.149.037Z" transform="translate(-323.157 -152.8)" fill={`url(#${name}-linear-gradient-7)`}/>
                        <path id="Path_466" data-name="Path 466" d="M352.993,152.87a29.925,29.925,0,0,1,27.564,18.283l.378,1.025-1.227-2.575a29.927,29.927,0,0,0-54.851,23.2l.637,1.726v-.083a29.92,29.92,0,0,1,27.564-41.532Z" transform="translate(-323.012 -152.805)" fill="#fff" opacity="0.18"/>
                        <path id="Path_494" data-name="Path 494" d="M353.262,214.331a29.943,29.943,0,0,1-27.572-18.28l-.37-1.028,1.218,2.529A29.927,29.927,0,0,0,381.417,174.4l-.637-1.726.046.083a29.939,29.939,0,0,1-27.564,41.578Z" transform="translate(-323.18 -154.331)" opacity="0.4" fill={`url(#${name}-linear-gradient-8)`}/>
                        <text x="42%" y="45%" dominantBaseline="middle" style={{fontFamily: "SF Pro Text Bold"}}  fontWeight="900" fontSize={chip.toString().length > 4 ? "8px" : chip.toString().length > 3 ? "10px" : "14px"} letterSpacing="0.56px"  textAnchor="middle">{abbrNum(chip)}</text>
                    </g>
                </g>
            </g>
        </svg>
    )
}

const mapStateToProps = (state) => {
    return {
        nominal: state.limit.Nominal
    }
}
export default connect(mapStateToProps, null)(SingleChip)