import React, { useEffect, useState } from "react";
import classNames from "classnames";
import {addBet, subscribeActiveBets, setTotalBets} from "@actions/betsAction";
import {connect} from "react-redux";
import Board from "./BoardElements/Board";
import { Prediction, RoundActionType } from "@constants/Enums";

function BetArea({ threeD, addBet, selectedChip, subscribeActiveBets, isEnabledMainBetArea, activeBets, setTotalBets }) {

    const [isEnabledSideBetAreas, setIsEnabledSideBetAreas] = useState(false);

    const setBet = (board) => {
        if (selectedChip) {
            addBet(selectedChip, board)
        }
    }

    useEffect(() => {
        subscribeActiveBets();
    }, [])

    useEffect(() => {
        let value = !!activeBets.find(item => item.Type === Prediction.Main && item.Stake > 0);
        setIsEnabledSideBetAreas(value);
        setTotalBets(activeBets);
    }, [activeBets])

    return (
        <div className={classNames("bet-area", {
                                            threeD, "enabled-main-bet-area": isEnabledMainBetArea,
                                            "enabled-side-bet-area": isEnabledSideBetAreas && isEnabledMainBetArea
                                        }
                                    )}>
            <div className="board-body">
                <Board setBet={setBet} />
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        selectedChip: state.selectedChip,
        isEnabledMainBetArea: state.isEnabledMainBetArea,
        activeBets: state.activeBets
    }
}

const mapDispatchToProps = {
    addBet,
    subscribeActiveBets,
    setTotalBets
}

export default connect(mapStateToProps,mapDispatchToProps)(BetArea)

