import React from "react";

function CalendarDividerIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 23.5 18.125">
            <defs>
                <filter id="VectorCalendarDividerIcon" x="0" y="1.875"  filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="icon_outlined_directional_swap-right_clip_group_" data-name="icon/outlined/directional/swap-right (clip group)" transform="translate(3.75)" opacity="0.6">
                <rect id="icon_outlined_directional_swap-right_style_" data-name="icon/outlined/directional/swap-right (style)" width="16" height="16" fill="none"/>
                <g transform="matrix(1, 0, 0, 1, -3.75, 0)" filter="url(#VectorCalendarDividerIcon)">
                    <path id="Vector-2" data-name="Vector" d="M11.392,3.441,8.83.191A.5.5,0,0,0,8.437,0H7.425a.126.126,0,0,0-.1.2L9.581,3.063H.125A.125.125,0,0,0,0,3.188v.938a.125.125,0,0,0,.125.125H11A.5.5,0,0,0,11.392,3.441Z" transform="translate(6 5.88)" fill="#fff"/>
                </g>
            </g>
        </svg>
    )
}

export default CalendarDividerIcon