import React, {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';

function ToolTipContent({title, toolTipParams, position})  {
    const [template, setTemplate] = useState(document.createElement('div'));

    useEffect(() => {
        const arrowSizes = 10;
        var p = template;
        const arrow = document.createElement("div")
        document.body.appendChild(p);
        p.className="tooltip-content";
        const contentParams = p.getBoundingClientRect()
        if (position) {
            p.className= `tooltip-content ${position}`
        }
        if (position === "left") {
            p.style.top = toolTipParams.bottom - toolTipParams.height   + 'px'
            p.style.bottom = "unset"
            p.style.right = 'unset';
            p.style.left = toolTipParams.left - (p.clientWidth / 2)  - arrowSizes + 'px'
        } else if (position === "top") {
            p.style.top = toolTipParams.top - p.clientHeight - arrowSizes  + 'px'
            p.style.right = 'auto';
            p.style.left = Math.max(toolTipParams.right - (toolTipParams.width / 2) - (p.clientWidth / 2), 4) + 'px';
            // if (toolTipParams.right - (toolTipParams.width / 2) + (p.clientWidth / 2) > window.innerWidth) {
            //     p.style.left = 'unset';
            //     p.style.right = `${window.innerWidth - toolTipParams.right}px`
            // }
        } else if (position === "right") {
            p.style.top = toolTipParams.bottom - toolTipParams.height  + 'px'
            p.style.left = Math.max(toolTipParams.right + (p.clientWidth / 2) + arrowSizes, 4) + 'px';
            p.style.right = 'unset';
        } else {
            p.style.left = Math.max((toolTipParams.right - (toolTipParams.width / 2)) - (p.clientWidth / 2), 4) + 'px'
            p.style.top = toolTipParams.bottom + arrowSizes + 'px'
        }

        if (toolTipParams.right - (toolTipParams.width / 2) + (p.clientWidth / 2) > window.innerWidth) {
            p.style.left = 'unset';
            p.style.right = `${window.innerWidth - toolTipParams.right}px`
            arrow.style.right =  `${(toolTipParams.width / 2) - (arrowSizes / 2)}px`
        } else if(toolTipParams.left - (toolTipParams.width / 2) - (p.clientWidth / 2) < 0) {
            p.style.left = `${toolTipParams.left}px`;
            p.style.right = "unset"
            arrow.style.left =  `${(toolTipParams.width / 2) - (arrowSizes / 2)}px`
        }

        arrow.className = arrow.className + "arrow"
        p.appendChild(arrow)
        setTemplate(p);
        return () => {
            document.body.removeChild(p);
        }
    }, [])

    return ReactDOM.createPortal(
        title,
        template,
    );
}

export default ToolTipContent