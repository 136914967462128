import React, {useState, useEffect, useRef, useContext} from "react";
import Header from "./header";
import Stream from "@components/videoStream";
import {connect} from "react-redux";
import ConnectMessage from "@components/connectMessage";
import {subscribeServerTime} from "@actions/workerActions";
import {resetPlayingCards} from "@actions/cardsAction";
import AlertMessages from "@components/alertMessage";
import BetBoard from "@components/betBoard/betBoard";
import WinnerList from "@components/roundInfo/winnerList";
import ChipsList from "@components/betBoard/ChipsList";
import RoundInfo from "@components/roundInfo/roundInfo";
import ActionTypeInfo from "@components/ActionTypeInfo";
import ActionsPanel from "@components/ActionsPanel";
import InsurancePanel from "@components/InsurancePanel";
import SelectHandPanel from "@components/SelectHandPanel";
import UserWon from "@components/UserWon";
import {refreshSession, gameStart, connectToSocket, subGameInitData, subscribeGameFinish, subscribeCanceledGame} from "@actions/gameAction";
import classNames from "classnames";
import Cards from "../components/cards/Cards";
import {FormattedMessage} from "react-intl";
import {ResizeContext} from "../contexts/resizeContext";

function MainLayout({
                        subscribeServerTime, gameCondition, resetPlayingCards, isConnected, refreshSession, gameStart,
                        session, connectToSocket, subGameInitData, viewSetting,subscribeGameFinish, subscribeCanceledGame,
                        detectNewLogin}) {
    const [online, setOnline] = useState(navigator.onLine);
    const [isShowOflineMessage, setIsShowOflineMessage] = useState(false);
    const prevOnlineValue = useRef(true);
    const refreshTimeout = useRef(0)
    const {mobile} = useContext(ResizeContext)

    useEffect(() => {
        function enable(e) { setOnline(true) }
        function disable(e) { setOnline(false) }
        window.addEventListener('online', enable);
        window.addEventListener('offline', disable);

        return () => {
            window.removeEventListener('online', enable);
            window.removeEventListener('offline', disable);
        }
    },[])

    useEffect(() => {
        subscribeServerTime();
        gameStart();
        subscribeGameFinish();
        subscribeCanceledGame()
    }, [])

    useEffect(() => {
        if (!gameCondition) {
            resetPlayingCards()
        }
    }, [gameCondition])

    useEffect(() => {
        if (!online) {
            setIsShowOflineMessage(true);
        }
        if (online && !isConnected && !prevOnlineValue.current && session) {
            refreshTimeout.current = setTimeout(() => refreshSession(session), 2000)
        }
        return () => {
            prevOnlineValue.current = online;
            if (!online && prevOnlineValue.current) {
                clearTimeout(refreshTimeout.current)
            }
        }
    }, [online])

    useEffect(() => {
        if (online && isConnected) {
            setIsShowOflineMessage(false);
        }
    }, [isConnected])

    useEffect(() => {
        if (session) {
            connectToSocket(session)
            subGameInitData();
        }

    }, [session])

    const refreshPage = () => {
        location.reload();
    }

    return (
        <div  className="mainContent white">
            <AlertMessages />
            <UserWon />
            <Header />
            <Stream />
            <WinnerList isMobile={mobile}/>
            <div className="fon"/>
            <div className="playerArena">
                <div className={classNames("game-arena", {'d3': viewSetting})}>
                    <RoundInfo />
                    <BetBoard threeD={viewSetting} />
                    <Cards threeD={viewSetting}/>
                    <ActionTypeInfo />
                    <ActionsPanel />
                    <InsurancePanel />
                    <SelectHandPanel />
                    <ChipsList windowMode="desktop" />
                </div>
            </div>
            { isShowOflineMessage ? 
                <ConnectMessage title={<FormattedMessage id="no_internet_connection"/>}
                                description={<FormattedMessage id="please_check_internet_connection_and_try_again" values={{br: <br/>}}/>}
                                button={{
                                    active: false
                                }}
                /> : ""
            }
            {
                detectNewLogin ?
                    <ConnectMessage title={<FormattedMessage id="new_login_detected"/>}
                                    description={<FormattedMessage id="new_login_detected_msg" values={{br: <br/>}}/>}
                                    button={{
                                        active: true,
                                        text: <FormattedMessage id="refresh"/>,
                                        callBack: refreshPage
                                    }}
                    />:""
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        gameCondition: state.gameCondition,
        isConnected: state.globalInfo.isConnected,
        session: state.globalInfo.session,
        gameHistory: state.gameHistory,
        viewSetting: state.viewSetting,
        detectNewLogin: state.globalInfo.detectNewLogin
    }
}

const mapDispatchToProps = {
    subscribeServerTime,
    resetPlayingCards,
    refreshSession,
    gameStart,
    connectToSocket,
    subGameInitData,
    subscribeGameFinish,
    subscribeCanceledGame
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);