import initialStore from "../initialStore";
import {SET_WINNER_LIST} from "@actions/actionTypes";

export default function winnerListReducer(state = initialStore.winnerList, action) {
    switch (action.type) {
        case SET_WINNER_LIST:
            return action.winners.map(item => ({
                ...item,
                Id: item.PlayerName + new Date().getTime()
            }))
        default:
            return state;
    }
}
