import {
    SET_ENABLE_MAIN_BETS_AREA
} from "@actions/actionTypes";
import initialStore from "../initialStore";

export default function isEnabledMainBetAreaReducer(state = initialStore.isEnabledMainBetArea, action) {
    switch (action.type) {
        case SET_ENABLE_MAIN_BETS_AREA:
            return action.isEnable;
        default:
            return state;
    }
}
