import {SET_GAME_HISTORY} from "@actions/actionTypes";
import initialStore from "../initialStore";

export default function gameHistoryReducer(state = initialStore.gameHistory, action) {
    switch (action.type) {
        case SET_GAME_HISTORY:
            return action.gameHistory
        default:
            return state;
    }
}