import initialStore from "../initialStore";
import { SET_ALERT_MSG } from "@actions/actionTypes";

export default function errorReducer(state = initialStore.emptyObj, action) {
    switch (action.type) {
        case SET_ALERT_MSG:
            return  action.data;
        default:
            return state;
    }
}
