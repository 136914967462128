import React, {useRef} from "react";
import {connect} from "react-redux";
import HistoryItem from "@components/history/historyItem";
import {FormattedMessage} from "react-intl";
import ElementLoading from "@components/elemetLoading";
import {NoDataIcon} from "@resource";

export function HistoryList({history, setPage, loading, setSelectedRound}) {
    const bodyRef = useRef();

    const fetchMore = () => {
        let scrollArea = bodyRef.current.scrollTop + bodyRef.current.clientHeight;
        if (((bodyRef.current.scrollHeight > scrollArea - 2) && (bodyRef.current.scrollHeight < scrollArea + 2)) && history.hasNext && !loading) {
            setPage((prev) => prev + 1);
        }
    }

    return (
        <div className="history-table">
            {
                history && history.data && history.data.length ? <>
                    <table cellSpacing="0" cellPadding="0">
                        <thead>
                        <tr>
                            <th><FormattedMessage id="round_id" /></th>
                            <th><FormattedMessage id="date" /></th>
                            <th><FormattedMessage id="bet" /></th>
                            <th><FormattedMessage id="win-lose" /></th>
                        </tr>
                        </thead>
                    </table>
                    <div className="history-table-body scrollable" ref={bodyRef} onScroll={fetchMore}>

                        <table cellSpacing="0" cellPadding="0">
                            <tbody>
                            {
                                history && history.data && history.data.map((item, index) => {
                                    return <HistoryItem item={item} index={index} key={item.roundId} onClick={() => setSelectedRound(item)}/>
                                })
                            }
                            </tbody>

                        </table>
                        {loading ? <ElementLoading /> : ""}
                    </div>
                </> : loading ? <ElementLoading /> : <div className="history-table-no-data">
                    <div className="icon">
                        <NoDataIcon />
                    </div>
                    <div className="title">
                        <FormattedMessage id="no-data" />
                    </div>

                </div>
            }
        </div>
    )
}
function mapStateToProps(state) {
    return {
        history: state.history,
        loading: state.historyLoading
    }
}

export default connect(mapStateToProps, null)(HistoryList);


