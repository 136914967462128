import React from "react";
import rotateDeviceIcon from "@assets/img/rotateDevice.svg";
import { FormattedMessage } from "react-intl";
import UserCenterInfo from "../UserCenterInfo";

export default function RotateDevice() {

    return  (
        <UserCenterInfo description={<FormattedMessage id="please_rotate_to_continue" />}
                        icon={rotateDeviceIcon}
                        className="rotate-device" />
    )
}