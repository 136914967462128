export function  getDateDDMMYYUtc(timeStamp, intl, returnType = "onlyNumber") {

    const date = new Date(timeStamp)
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month < 10 ? '0'+month : month;
    let day = date.getDate();
    day = day < 10 ? '0'+day : day;
    let hours = date.getHours();
    let minutes = date.getMinutes();
    minutes = minutes < 10 ? '0'+minutes : minutes;
    let seconds =  date.getSeconds()
    seconds = seconds < 10 ? '0'+seconds : seconds;


    if (returnType === "onlyNumber") {
        return day + month + year
    }
    if (returnType === "dd-mm-yy") {
        return `${day}-${month}-${year}`
    }

    if (returnType === "time") {
        return  `${hours}:${minutes}:${seconds} `
    }
    if (returnType === "dd-mm-yy-time") {
        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} `
    }
    if ( returnType === "dd-mm-time") {
        return `${day}-${month} ${hours}:${minutes}:${seconds}`
    }
    return day + month + year
}

export function removeDay(date, day) {
    date.setDate(date.getDate() - day)
    return date
}