// export const DefaultLanguage = 'en-US';
export const DefaultLanguage = 'en';
export const DefaultLanguageCode = 42;

export const Language = {
    ENGLISH: 1,
    RUSSIAN: 2
};

export const LanguageName = {
    1: "English",
    2: "Russian"
};

export const LanguageCode = {
    1: "EN",
    2: "RU"
};

export const LanguageForApi = {
    "EN": 1,
    "RU": 2,
    "AM": 3
};

export const LanguageCodesArr = [
    "EN",
    "RU"
];


export const BetsType = {
    1: "win",
    2: "lose",
    3: "refund"
}

export const TimeTicker = {
    6: "yellow",
    3: "red",
    0: "white"
}

export const SocketUpdateType = {
    0: "Ping",
    1: "Error",
    2: "RoundStart",
    3: "Card",
    4: "Odd",
    5: "Timer",
    6: "Result",
    7: "Bet",
    8: "Pair"
}
export const CardsSuit = {
    0: "None", // for joker
    1: "clubs",
    2: "diamonds",
    3: "hearts",
    4: "spades"
}

export const Prediction = {
    Main: 0,
    Split: 1,
    Insurance: 2,
    Double: 3,
    PerfectPairs: 4,
    _21plus3: 5,
    0: "Main",
    1: "Split",
    2: "Insurance",
    3: "Double",
    4: "PerfectPairs",
    5: "_21plus3"
}

export const PlayerActionType = {
    Hit: 0,
    Stand: 1,
    Double: 2,
    0: "Hit",
    1: "Stand",
    2: "Double"
}

export const InsuranceType = {
    Yes: 0,
    No: 1,
    0: "Yes",
    1: "No",
}

export const PlayerHandType = {
    OneHand: 0,
    TwoHands: 1,
    0: "OneHand",
    1: "TwoHands"
}

export const RoundActionType = {
    Bet: 0,
    Split: 1,
    Insurance: 2,
    DealerAction: 3,
    PlayerAction: 4,
    0: "Bet",
    1: "Split",
    2: "Insurance",
    3: "DealerAction",
    4: "PlayerAction"
}

export const OwnerTypes = {
    dealer: 0,
    player: 1,
    "0": "dealer",
    "1": "player"
}

export const gameHistory = {
    0: "A", //UP
    1: "B", //DOWN
    2: "T", // 7
    "A": 0,
    "B": 1,
    "T": 2
}

export const CardName = [
    "2", "3", "4", "5", "6", "7", "8", "9", "10", "J", "Q", "K", "A"
]

export const CardTypeMap = {
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10",
    11: "J",
    12: "Q",
    13: "K",
    14: "A",
    15: "Joker1",
    16: "Joker2"
}

export const CardType = {
    "2": 2,
    "3": 3,
    "4": 4,
    "5": 5,
    "6": 6,
    "7": 7,
    "8": 8,
    "9": 9,
    "10": 10,
    "J": 11,
    "Q": 12,
    "K": 13,
    "A": 14,
    "Joker1": 15,
    "Joker2": 16
}

export const SocketReadyState = {
    "CONNECTING": 0,
    "OPEN": 1,
    "CLOSING": 2,
    "CLOSED": 3
}