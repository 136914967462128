import React from "react";
import {connect} from "react-redux";
import classNames from "classnames";
import {FormattedMessage} from "react-intl";
import { RoundActionType, PlayerHandType } from "@constants/Enums";
import { emitSplitAction, disableSelectHandPanel } from "@actions/userActions";
import OneHandButton from "@components/ActionsButtons/OneHandButton";
import TwoHandButton from "@components/ActionsButtons/TwoHandButton";
import TemplateButton from "@components/templateButton"

function SelectHandPanel({ round, isCustomDisableSelectHandPanel, emitSplitAction, disableSelectHandPanel }) {

    const emitAction = (action) => {
        disableSelectHandPanel();
        emitSplitAction(action);
    }

    return (
        <div className={classNames("action-panel", {show: round?.actionType == RoundActionType.Split && !isCustomDisableSelectHandPanel})}>
           <ul className="action-panel-body template-button-list">
                <TemplateButton title={<FormattedMessage id={"one_hand"}/>}
                                icon={<OneHandButton/>}
                                active={false}
                                position="top"
                                changeState={() => emitAction(PlayerHandType.OneHand)} />
                <TemplateButton title={<FormattedMessage id={"two_hands"}/>}
                                icon={<TwoHandButton/>}
                                active={false}
                                position="top"
                                changeState={() => emitAction(PlayerHandType.TwoHands)} />
           </ul>
        </div>
    )
}

export const mapStateToProps = (state) => {
    return {
        round: state.round,
        isCustomDisableSelectHandPanel: state.isCustomDisableSelectHandPanel
    }
}

const mapDispatchToProps = {
    emitSplitAction,
    disableSelectHandPanel
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectHandPanel)