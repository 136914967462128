import {useEffect, useState} from "react";

export default function useFetch(urls) {
    const [process, setProcess] = useState({});
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(true);

    function addFont() {
        const fonts = document.createElement("link")
        fonts.rel = "stylesheet";
        fonts.type = "text/css"
        fonts.href = "/font.css"
        document.head.appendChild(fonts);
    }

    function fetch(item) {
        const xhr = new XMLHttpRequest();

        xhr.onerror = () => {
            console.error('Download failed.');
            addFont()
        }

        xhr.onabort = () => {
            console.error('Download cancelled.');
        }

        xhr.addEventListener('readystatechange', function(e) {
            if(xhr.readyState == 2 && xhr.status == 200) {
                // Download is being started
            }
            else if(xhr.readyState == 3) {
                // Download is under progress
            }
            else if(xhr.readyState == 4) {
                // Downloading has finished
        
                // request.response holds the binary data of the font
                if (item.fontFamily !== undefined) {
                    try {
                        var junction_font = new FontFace(item.fontFamily, xhr.response);
                        junction_font.load().then(function(loaded_face) {
                            console.log("onload font")
                            document.fonts.add(loaded_face);
                        }).catch(function(error) {
                            // error occurred
                            addFont()
                            console.error(error)
                        });
                    } catch (e) {
                        console.error(e)
                        addFont();
                    }
                }
            }
        });

        xhr.onprogress =  (event) => {
            // event.loaded returns how many bytes are downloaded

            var contentLength;
            if (event.lengthComputable) {
                contentLength = event.total;
            } else {
                contentLength = parseInt(event.target.getResponseHeader('content-length'), 10);
            }
            setProcess((prev) => {
                return {...prev, [item.url]: event.loaded / contentLength *  100}
            })

        }
        xhr.responseType = 'arraybuffer';
        xhr.open('GET', item.url);
        xhr.send();

    }
    function getJson() {
       urls.forEach(item => {
        fetch(item)

       })
    }

    useEffect(() => {
        let percent = 0
        let actualPersent =  Object.keys(process).length / urls.length * 100;
        urls.forEach(item => {
            percent += process[item.url] || 0
        })

        let loadingPersent = Math.min(actualPersent, percent / urls.length);

        if (loadingPersent >= 100) {
            setTimeout(() => setLoading(false), 1000);
        }
        setProgress(loadingPersent)
    }, [process])

    return { loading, progress, getJson };
}