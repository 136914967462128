import React, { useState, useEffect } from "react";
import {connect} from "react-redux";
import SingleChip from "./singleChip";
import Single3DChip from "./single3DChip";
import { getChipsByStack } from "@services/betService";

function Chips({ stack, isThreeD, nominal}) {

    const [bets, setBets] = useState([]);

    useEffect(() => {
        setBets(getChipsByStack(stack, nominal));
    }, [stack, nominal])

    return isThreeD ? bets.map((bet, index) => <li className="bet-value-item threeD"
                                        key={`${index} ${bet}`}
                                        style={{transform: `translate(${index * 0}%, ${index * -6}%)`}}>
                                        <Single3DChip chipName={bet.toString()}
                                                        chipTitle={index === bets.length - 1? bets.reduce((a, b) => a + b) : bet}
                                                        attributeClassName="userBet"
                                                        disabledFilter={index === 0}/>
                                    </li>
                                )
                
             :  bets.length? <li className="bet-value-item">
                                <SingleChip
                                    chipName={bets[bets.length - 1] || ""}
                                    chipTitle={bets.reduce((a, b) => a + b) || ""}
                                    attributeClassName="userBet"/>
                            </li>
                        : ""

}

const mapStateToProps = (state) => {
    return {
        nominal: state.limit.Nominal
    }
}
export default connect(mapStateToProps, null)(Chips)