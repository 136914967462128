import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import CardList from "./CardsList";
import { getPointsView } from "@utils/CommonUtil";

function DealerHand({ dealerHand, gameCondition }) {

    return (
        <div className="dealer-hand">
            {
                gameCondition ?
                    <span className="hands-title">
                        <FormattedMessage id="dealer" /> - {dealerHand?.points?.length && getPointsView(dealerHand.points, dealerHand.isBlackJack) || 0}
                    </span>: ""
            }

            <div className="hands-body">
                <CardList cards={dealerHand?.cards || []} />
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        dealerHand: state.dealerHand,
        gameCondition: state.gameCondition
    }
}

const mapDispatchToProps = {
}


export default connect(mapStateToProps, mapDispatchToProps)(DealerHand);