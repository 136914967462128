import React from "react";
import CardListView from "@components/cards/CardsList";
import classNames from "classnames";
import { getPointsView } from "@utils/CommonUtil";

function CardList({points, player, cards, winner = false, isBlackJack}) {
    return (
        <div className={classNames("history-card-list", { active: winner})}>
            <div>
                <div className="point">{ getPointsView(points, isBlackJack) }</div>
                <div className="player">{ player }</div>
            </div>
            <div>
                <CardListView cards={cards} className="history-card-list-view"/>
            </div>
        </div>
    )
}

export default CardList