import React from "react";

function ViewSettingsMobileIcon() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 30 31">
            <defs>
                <filter id="VectorViewSettingsMobileIcon" x="0" y="0" width="30" height="31" filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Group_1514" data-name="Group 1514" transform="translate(-49.002 -182)">
                <rect id="Rectangle_706" data-name="Rectangle 706" width="20" height="20" transform="translate(54.002 187)" fill="none"/>
                <g transform="matrix(1, 0, 0, 1, 49, 184)" filter="url(#VectorViewSettingsMobileIcon)">
                    <path id="Vector-2" data-name="Vector" d="M3.55,19a.187.187,0,0,1-.121-.045L.07,16.175A.2.2,0,0,1,0,16.043a.272.272,0,0,1,.069-.195l3.358-2.783a.191.191,0,0,1,.067-.037.188.188,0,0,1,.054-.008h.022a.193.193,0,0,1,.072.026A.208.208,0,0,1,3.7,13.1a.211.211,0,0,1,.041.128v1.8H16.13V8.709a.193.193,0,0,1,.187-.2h1.491a.2.2,0,0,1,.192.2V15.22A1.732,1.732,0,0,1,16.32,17H3.747v1.8A.2.2,0,0,1,3.55,19ZM1.678,10.49H.187a.193.193,0,0,1-.187-.2V3.78A1.732,1.732,0,0,1,1.68,2H14.249V.208a.2.2,0,0,1,.2-.208.186.186,0,0,1,.121.045l3.358,2.781a.2.2,0,0,1,.069.133.271.271,0,0,1-.069.194L14.567,5.935a.191.191,0,0,1-.067.037.188.188,0,0,1-.054.008h-.022a.193.193,0,0,1-.072-.026.208.208,0,0,1-.058-.052.211.211,0,0,1-.041-.128v-1.8H1.865v6.316A.193.193,0,0,1,1.678,10.49Z" transform="translate(6 4)" fill="#fff"/>
                </g>
            </g>
        </svg>
    )
}

export default ViewSettingsMobileIcon