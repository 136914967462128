import {
    SUB_SERVER_TIME,
    SET_SERVER_TIME,
    SET_SUB_TICKER_WORKER,
    SET_UN_SUB_TICKER_WORKER,
} from "@actions/actionTypes";

export function subscribeServerTime() {
    return dispatch => {
        dispatch({
            eventOn: SUB_SERVER_TIME,
            handle: (data) => {
                dispatch({
                    type: SET_SERVER_TIME,
                    time: data.Time
                })
            }
        })
    }
}

export function subscribeTickerWorker(setServerTime) {
    return dispatch => {
        dispatch({
            type: SET_SUB_TICKER_WORKER,
            data: setServerTime
        })
    }
}

export function unSubscribeTickerWorker(setServerTime) {
    return dispatch => {
        dispatch({
            type: SET_UN_SUB_TICKER_WORKER,
            data: setServerTime
        })
    }
}