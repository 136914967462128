import React from "react";

function SwitchIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 48 48">
            <defs>
                <filter id="VectorSwitchIcon" x="8" y="9.002"  filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Group_1482" data-name="Group 1482" transform="translate(-30 -26)">
                <circle id="Ellipse_352" data-name="Ellipse 352" cx="24" cy="24" r="24" transform="translate(30 26)" fill="none"/>
                <g transform="matrix(1, 0, 0, 1, 30, 26)" filter="url(#VectorSwitchIcon)">
                    <path id="Vector-2" data-name="Vector" d="M3.944,21a.208.208,0,0,1-.135-.049L.078,17.877A.215.215,0,0,1,0,17.731a.3.3,0,0,1,.076-.215l3.731-3.075a.213.213,0,0,1,.075-.041.21.21,0,0,1,.06-.009h.025a.215.215,0,0,1,.08.028.226.226,0,0,1,.111.2v1.986H17.923V9.625a.214.214,0,0,1,.208-.219h1.657A.223.223,0,0,1,20,9.625v7.2a1.919,1.919,0,0,1-1.867,1.963H4.163V20.77A.225.225,0,0,1,3.944,21ZM1.865,11.594H.208A.214.214,0,0,1,0,11.375v-7.2A1.919,1.919,0,0,1,1.867,2.214H15.832V.23A.225.225,0,0,1,16.051,0a.207.207,0,0,1,.135.049l3.731,3.073a.215.215,0,0,1,.076.147.3.3,0,0,1-.076.214L16.186,6.559a.213.213,0,0,1-.075.041.21.21,0,0,1-.06.009h-.025a.215.215,0,0,1-.08-.028.226.226,0,0,1-.111-.2V4.394H2.072v6.98A.214.214,0,0,1,1.865,11.594Z" transform="translate(14 13)" fill="#fff"/>
                </g>
            </g>
        </svg>
    )
}

export default SwitchIcon