import React from "react";

function DropDownArrowIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 17.333 20.889">
            <defs>
                <filter id="VectorDropDownArrowIcon" x="0" y="0"  filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="icon_outlined_directional_play-circle" data-name="icon/outlined/directional/play-circle" transform="translate(0.667 0.445)">
                <rect id="icon_outlined_directional_play-circle_clip_mask_" data-name="icon/outlined/directional/play-circle (clip mask)" width="16" height="16" fill="none"/>
                <g transform="matrix(1, 0, 0, 1, -0.67, -0.44)" filter="url(#VectorDropDownArrowIcon)">
                    <path id="Vector-2" data-name="Vector" d="M8.824,4.893,4.678.1a.317.317,0,0,0-.467,0L.065,4.893a.271.271,0,0,0,.234.44H8.591A.271.271,0,0,0,8.824,4.893Z" transform="translate(11.33 4) rotate(90)" fill="#fff"/>
                </g>
            </g>
        </svg>
    )
}

export default DropDownArrowIcon