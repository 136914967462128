import {
    SET_ADD_BET,
    RESET_ACTIVE_BET,
    SET_BETS
} from "@actions/actionTypes";
import initialStore from "../initialStore";
import {setLocalStorage} from "@services/localStorageService";

export default function activeBetsReducer(state = initialStore.userBetList, action) {
    switch (action.type) {
        case SET_ADD_BET: {
            let newState  = JSON.parse(JSON.stringify(state));
            action.bets.forEach(bet => {
                let index = newState.findIndex(el => el.Type === bet.Type)
                if (index > -1 ) {
                    newState[index].Stake += bet.Stake
                }
            })
            setLocalStorage(action.userId + "_bets_" + action.roundId, JSON.stringify(newState.filter(bet => bet.Stake)))
            return newState
        }
        case SET_BETS: {
            let newState  = JSON.parse(JSON.stringify(state));
            action.bets.forEach(bet => {
                let index = newState.findIndex(el => el.Type === bet.Type)
                if (index > -1 ) {
                    newState[index].Stake = bet.Stake
                }
            })
            return newState
        }
        case RESET_ACTIVE_BET:
            return initialStore.userBetList;
        default:
            return state;
    }
}
