import React from "react";

function DoneIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 25 22">
            <defs>
                <filter id="doneIcon" x="0" y="0"  filterUnits="userSpaceOnUse">
                    <feOffset dy="2" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="2" result="blur"/>
                    <feFlood/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Group_1422" data-name="Group 1422" transform="translate(-451 -483)">
                <rect id="Rectangle_529" data-name="Rectangle 529" width="13" height="16" transform="translate(457 484)" fill="none"/>
                <g transform="matrix(1, 0, 0, 1, 451, 483)" filter="url(#doneIcon)">
                    <path id="Vector-2" data-name="Vector" d="M12.872,0H11.759a.512.512,0,0,0-.4.189L4.791,8.3,1.641,4.411a.508.508,0,0,0-.178-.139.521.521,0,0,0-.222-.05H.128a.124.124,0,0,0-.1.2L4.391,9.811a.52.52,0,0,0,.8,0L12.973.2A.123.123,0,0,0,12.872,0Z" transform="translate(6 4)" fill="#fff"/>
                </g>
            </g>
        </svg>
    )
}

export default DoneIcon